@charset "UTF-8";

/*!
  Impulse, by Archetype Themes
  http://archetypethemes.co
*/:root
{
  --colorBtnPrimary: #111111
  ;
  --colorBtnPrimaryLight: #2b2b2b
  ;
  --colorBtnPrimaryDim: #040404
  ;
  --colorBtnPrimaryText: #ffffff
  ;
  --colorCartDot: #e4320c
  ;

  --colorLink: #000000
  ;

  --colorTextBody: #000000
  ;
  --colorPrice: #1c1d1d
  ;
  --colorTextSavings: #e4320c
  ;
  --colorSaleTag: #1c1d1d
  ;
  --colorSaleTagText: #ffffff
  ;

  --colorBody: #ffffff
  ;
  --colorBodyDim: #f2f2f2
  ;

  --colorFooter: #ffffff
  ;
  --colorFooterText: #000000
  ;

  --colorBorder: #e8e8e1
  ;

  --colorNav: #ffffff
  ;
  --colorNavText: #000000
  ;
  --colorAnnouncement: #3d474f
  ;
  --colorAnnouncementText: #ffffff
  ;

  --colorHeroText: #ffffff
  ;

  --colorModalBg: #e6e6e6
  ;

  --colorImageOverlay: #000000
  ;
  --colorImageOverlayOpacity: 0.18
  ;
  --colorImageOverlayTextShadow: 0.26
  ;

  --colorSmallImageBg: #ffffff
  ;
  --colorLargeImageBg: #0f0f0f
  ;
  --colorGridOverlay: #000000
  ;
  --colorGridOverlayOpacity: 0.1;

  --colorDrawers: #ffffff
  ;
  --colorDrawersDim: #f2f2f2
  ;
  --colorDrawerBorder: #e8e8e1
  ;
  --colorDrawerText: #000000
  ;
  --colorDrawerTextDark: #000000
  ;
  --colorDrawerButton: #111111
  ;
  --colorDrawerButtonText: #ffffff
  ;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  60% {
    width: 100px;
    margin-left: -50px;
  }
  to {
    margin-left: 50px;
    width: 0;
  }
}

@keyframes preloading {
  0% {
    width: 0;
    opacity: 0;
  }
  60% {
    width: 100px;
    margin-left: -50px;
    opacity: 1;
  }
  to {
    margin-left: 50px;
    width: 0;
    opacity: 1;
  }
}

@keyframes slideshowBars {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes grid-product__loading {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0.2;
  }
  to {
    opacity: 0;
  }
}

@keyframes shine {
  to {
    left: -200%;
  }
}

@keyframes overlay-on {
  0% {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}

@keyframes overlay-off {
  0% {
    opacity: 0.6;
  }
  to {
    opacity: 0;
  }
}

@keyframes full-overlay-on {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes full-overlay-off {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes modal-open {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal-closing {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

@keyframes rise-up {
  0% {
    opacity: 1;
    transform: translateY(120%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes rise-up-out {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 1;
    transform: translateY(-120%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes zoom-fade {
  0% {
    opacity: 0;
    transform: scale(1.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoom-fade-password {
  0% {
    opacity: 0;
    transform: scale(1.4);
  }
  5% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 1;
    transform: scale(1.2);
  }
}

*,:after ,:before ,
input {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.grid:after {
  content: "";
  display: table;
  clear: both;
}

.grid {
  list-style: none;
  margin: 0 0 0 -22px;
  padding: 0;
}

@media only screen and (max-width:768px) {

  .grid {
    margin-left: -17px
  }

  html[dir=rtl] .grid {
    margin-left: 0;
    margin-right: -17px
  }
}

html[dir=rtl] .grid {
  margin-left: 0;
  margin-right: -22px
}

.grid--small {
  margin-left: -10px
}

.grid--small .grid__item {
  padding-left: 10px;
}

.grid__item {
  float: left;
  padding-left: 22px;
  width: 100%;
  min-height: 1px
}

@media only screen and (max-width:768px) {

  .grid__item {
    padding-left: 17px
  }

  html[dir=rtl] .grid__item {
    padding-left: 0;
    padding-right: 17px
  }
}

.grid__item[class*="--push"] {
  position: relative;
}

html[dir=rtl] .grid__item {
  float: right;
  padding-left: 0;
  padding-right: 22px
}

html[dir=rtl] .grid__item[class*="--push"] {
  position: static;
}

.grid--no-gutters {
  margin-left: 0
}

.grid--no-gutters .grid__item {
  padding-left: 0;
}

.grid--small-gutters {
  margin-left: -10px;
  margin-bottom: -10px
}

.grid--small-gutters .grid__item {
  padding-left: 10px;
  padding-bottom: 10px;
}

html[dir=rtl] .grid--small-gutters {
  margin-left: 0;
  margin-right: -10px
}

html[dir=rtl] .grid--small-gutters .grid__item {
  padding-left: 0;
  padding-right: 10px;
}

.grid--flush-bottom {
  margin-bottom: -22px;
  overflow: auto
}

.grid--flush-bottom > .grid__item {
  margin-bottom: 22px;
}

.grid--center {
  text-align: center
}

.grid--center .grid__item {
  float: none;
  display: inline-block;
  vertical-align: top;
  text-align: left
}

html[dir=rtl] .grid--center .grid__item {
  text-align: right
}

.grid--full {
  margin-left: 0
}

.grid--full > .grid__item {
  padding-left: 0;
}

@media only screen and (min-width:769px) {
  .grid--table-large {
    display: table;
    width: 100%;
    table-layout: fixed
  }

  .grid--table-large > .grid__item {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

@media only screen and (max-width:768px) {
  .small--grid--flush {
    margin-left: -2px
  }
  .page-width .small--grid--flush {
    margin-left: -15px;
    margin-right: -17px
  }

  .small--grid--flush > .grid__item {
    padding-left: 2px;
  }
}

.one-whole {
  width: 100%;
}

.one-half {
  width: 50%;
}

.one-third {
  width: 33.33333%;
}

.two-thirds {
  width: 66.66667%;
}

.one-quarter {
  width: 25%;
}

.two-quarters {
  width: 50%;
}

.three-quarters {
  width: 75%;
}

.one-fifth {
  width: 20%;
}

.two-fifths {
  width: 40%;
}

.three-fifths {
  width: 60%;
}

.four-fifths {
  width: 80%;
}

.one-sixth {
  width: 16.66667%;
}

.two-sixths {
  width: 33.33333%;
}

.three-sixths {
  width: 50%;
}

.four-sixths {
  width: 66.66667%;
}

.five-sixths {
  width: 83.33333%;
}

.one-eighth {
  width: 12.5%;
}

.two-eighths {
  width: 25%;
}

.three-eighths {
  width: 37.5%;
}

.four-eighths {
  width: 50%;
}

.five-eighths {
  width: 62.5%;
}

.six-eighths {
  width: 75%;
}

.seven-eighths {
  width: 87.5%;
}

.one-tenth {
  width: 10%;
}

.two-tenths {
  width: 20%;
}

.three-tenths {
  width: 30%;
}

.four-tenths {
  width: 40%;
}

.five-tenths {
  width: 50%;
}

.six-tenths {
  width: 60%;
}

.seven-tenths {
  width: 70%;
}

.eight-tenths {
  width: 80%;
}

.nine-tenths {
  width: 90%;
}

.one-twelfth {
  width: 8.33333%;
}

.two-twelfths {
  width: 16.66667%;
}

.three-twelfths {
  width: 25%;
}

.four-twelfths {
  width: 33.33333%;
}

.five-twelfths {
  width: 41.66667%;
}

.six-twelfths {
  width: 50%;
}

.seven-twelfths {
  width: 58.33333%;
}

.eight-twelfths {
  width: 66.66667%;
}

.nine-twelfths {
  width: 75%;
}

.ten-twelfths {
  width: 83.33333%;
}

.eleven-twelfths {
  width: 91.66667%;
}

@media only screen and (max-width:768px) {
  .small--one-whole {
    width: 100%;
  }
  .small--one-half {
    width: 50%;
  }
  .small--one-third {
    width: 33.33333%;
  }
  .small--two-thirds {
    width: 66.66667%;
  }
  .small--one-quarter {
    width: 25%;
  }
  .small--two-quarters {
    width: 50%;
  }
  .small--three-quarters {
    width: 75%;
  }
  .small--one-fifth {
    width: 20%;
  }
  .small--two-fifths {
    width: 40%;
  }
  .small--three-fifths {
    width: 60%;
  }
  .small--four-fifths {
    width: 80%;
  }
  .small--one-sixth {
    width: 16.66667%;
  }
  .small--two-sixths {
    width: 33.33333%;
  }
  .small--three-sixths {
    width: 50%;
  }
  .small--four-sixths {
    width: 66.66667%;
  }
  .small--five-sixths {
    width: 83.33333%;
  }
  .small--one-eighth {
    width: 12.5%;
  }
  .small--two-eighths {
    width: 25%;
  }
  .small--three-eighths {
    width: 37.5%;
  }
  .small--four-eighths {
    width: 50%;
  }
  .small--five-eighths {
    width: 62.5%;
  }
  .small--six-eighths {
    width: 75%;
  }
  .small--seven-eighths {
    width: 87.5%;
  }
  .small--one-tenth {
    width: 10%;
  }
  .small--two-tenths {
    width: 20%;
  }
  .small--three-tenths {
    width: 30%;
  }
  .small--four-tenths {
    width: 40%;
  }
  .small--five-tenths {
    width: 50%;
  }
  .small--six-tenths {
    width: 60%;
  }
  .small--seven-tenths {
    width: 70%;
  }
  .small--eight-tenths {
    width: 80%;
  }
  .small--nine-tenths {
    width: 90%;
  }
  .small--one-twelfth {
    width: 8.33333%;
  }
  .small--two-twelfths {
    width: 16.66667%;
  }
  .small--three-twelfths {
    width: 25%;
  }
  .small--four-twelfths {
    width: 33.33333%;
  }
  .small--five-twelfths {
    width: 41.66667%;
  }
  .small--six-twelfths {
    width: 50%;
  }
  .small--seven-twelfths {
    width: 58.33333%;
  }
  .small--eight-twelfths {
    width: 66.66667%;
  }
  .small--nine-twelfths {
    width: 75%;
  }
  .small--ten-twelfths {
    width: 83.33333%;
  }
  .small--eleven-twelfths {
    width: 91.66667%;
  }
  .grid--uniform .small--five-tenths:nth-of-type(odd),
  .grid--uniform .small--four-eighths:nth-of-type(odd),
  .grid--uniform .small--four-twelfths:nth-of-type(3n + 1),
  .grid--uniform .small--one-eighth:nth-of-type(8n + 1),
  .grid--uniform .small--one-fifth:nth-of-type(5n + 1),
  .grid--uniform .small--one-half:nth-of-type(odd),
  .grid--uniform .small--one-quarter:nth-of-type(4n + 1),
  .grid--uniform .small--one-sixth:nth-of-type(6n + 1),
  .grid--uniform .small--one-third:nth-of-type(3n + 1),
  .grid--uniform .small--one-twelfth:nth-of-type(12n + 1),
  .grid--uniform .small--six-twelfths:nth-of-type(odd),
  .grid--uniform .small--three-sixths:nth-of-type(odd),
  .grid--uniform .small--three-twelfths:nth-of-type(4n + 1),
  .grid--uniform .small--two-eighths:nth-of-type(4n + 1),
  .grid--uniform .small--two-sixths:nth-of-type(3n + 1),
  .grid--uniform .small--two-twelfths:nth-of-type(6n + 1) {
    clear: both;
  }
}

@media only screen and (min-width:769px) {
  .medium-up--one-whole {
    width: 100%;
  }
  .medium-up--one-half {
    width: 50%;
  }
  .medium-up--one-third {
    width: 33.33333%;
  }
  .medium-up--two-thirds {
    width: 66.66667%;
  }
  .medium-up--one-quarter {
    width: 25%;
  }
  .medium-up--two-quarters {
    width: 50%;
  }
  .medium-up--three-quarters {
    width: 75%;
  }
  .medium-up--one-fifth {
    width: 20%;
  }
  .medium-up--two-fifths {
    width: 40%;
  }
  .medium-up--three-fifths {
    width: 60%;
  }
  .medium-up--four-fifths {
    width: 80%;
  }
  .medium-up--one-sixth {
    width: 16.66667%;
  }
  .medium-up--two-sixths {
    width: 33.33333%;
  }
  .medium-up--three-sixths {
    width: 50%;
  }
  .medium-up--four-sixths {
    width: 66.66667%;
  }
  .medium-up--five-sixths {
    width: 83.33333%;
  }
  .medium-up--one-eighth {
    width: 12.5%;
  }
  .medium-up--two-eighths {
    width: 25%;
  }
  .medium-up--three-eighths {
    width: 37.5%;
  }
  .medium-up--four-eighths {
    width: 50%;
  }
  .medium-up--five-eighths {
    width: 62.5%;
  }
  .medium-up--six-eighths {
    width: 75%;
  }
  .medium-up--seven-eighths {
    width: 87.5%;
  }
  .medium-up--one-tenth {
    width: 10%;
  }
  .medium-up--two-tenths {
    width: 20%;
  }
  .medium-up--three-tenths {
    width: 30%;
  }
  .medium-up--four-tenths {
    width: 40%;
  }
  .medium-up--five-tenths {
    width: 50%;
  }
  .medium-up--six-tenths {
    width: 60%;
  }
  .medium-up--seven-tenths {
    width: 70%;
  }
  .medium-up--eight-tenths {
    width: 80%;
  }
  .medium-up--nine-tenths {
    width: 90%;
  }
  .medium-up--one-twelfth {
    width: 8.33333%;
  }
  .medium-up--two-twelfths {
    width: 16.66667%;
  }
  .medium-up--three-twelfths {
    width: 25%;
  }
  .medium-up--four-twelfths {
    width: 33.33333%;
  }
  .medium-up--five-twelfths {
    width: 41.66667%;
  }
  .medium-up--six-twelfths {
    width: 50%;
  }
  .medium-up--seven-twelfths {
    width: 58.33333%;
  }
  .medium-up--eight-twelfths {
    width: 66.66667%;
  }
  .medium-up--nine-twelfths {
    width: 75%;
  }
  .medium-up--ten-twelfths {
    width: 83.33333%;
  }
  .medium-up--eleven-twelfths {
    width: 91.66667%;
  }
  .grid--uniform .medium-up--five-tenths:nth-of-type(odd),
  .grid--uniform .medium-up--four-eighths:nth-of-type(odd),
  .grid--uniform .medium-up--four-twelfths:nth-of-type(3n + 1),
  .grid--uniform .medium-up--one-eighth:nth-of-type(8n + 1),
  .grid--uniform .medium-up--one-fifth:nth-of-type(5n + 1),
  .grid--uniform .medium-up--one-half:nth-of-type(odd),
  .grid--uniform .medium-up--one-quarter:nth-of-type(4n + 1),
  .grid--uniform .medium-up--one-sixth:nth-of-type(6n + 1),
  .grid--uniform .medium-up--one-third:nth-of-type(3n + 1),
  .grid--uniform .medium-up--one-twelfth:nth-of-type(12n + 1),
  .grid--uniform .medium-up--six-twelfths:nth-of-type(odd),
  .grid--uniform .medium-up--three-sixths:nth-of-type(odd),
  .grid--uniform .medium-up--three-twelfths:nth-of-type(4n + 1),
  .grid--uniform .medium-up--two-eighths:nth-of-type(4n + 1),
  .grid--uniform .medium-up--two-sixths:nth-of-type(3n + 1),
  .grid--uniform .medium-up--two-twelfths:nth-of-type(6n + 1) {
    clear: both;
  }
}

@media only screen and (min-width:1050px) {
  .widescreen--one-whole {
    width: 100%;
  }
  .widescreen--one-half {
    width: 50%;
  }
  .widescreen--one-third {
    width: 33.33333%;
  }
  .widescreen--two-thirds {
    width: 66.66667%;
  }
  .widescreen--one-quarter {
    width: 25%;
  }
  .widescreen--two-quarters {
    width: 50%;
  }
  .widescreen--three-quarters {
    width: 75%;
  }
  .widescreen--one-fifth {
    width: 20%;
  }
  .widescreen--two-fifths {
    width: 40%;
  }
  .widescreen--three-fifths {
    width: 60%;
  }
  .widescreen--four-fifths {
    width: 80%;
  }
  .widescreen--one-sixth {
    width: 16.66667%;
  }
  .widescreen--two-sixths {
    width: 33.33333%;
  }
  .widescreen--three-sixths {
    width: 50%;
  }
  .widescreen--four-sixths {
    width: 66.66667%;
  }
  .widescreen--five-sixths {
    width: 83.33333%;
  }
  .widescreen--one-eighth {
    width: 12.5%;
  }
  .widescreen--two-eighths {
    width: 25%;
  }
  .widescreen--three-eighths {
    width: 37.5%;
  }
  .widescreen--four-eighths {
    width: 50%;
  }
  .widescreen--five-eighths {
    width: 62.5%;
  }
  .widescreen--six-eighths {
    width: 75%;
  }
  .widescreen--seven-eighths {
    width: 87.5%;
  }
  .widescreen--one-tenth {
    width: 10%;
  }
  .widescreen--two-tenths {
    width: 20%;
  }
  .widescreen--three-tenths {
    width: 30%;
  }
  .widescreen--four-tenths {
    width: 40%;
  }
  .widescreen--five-tenths {
    width: 50%;
  }
  .widescreen--six-tenths {
    width: 60%;
  }
  .widescreen--seven-tenths {
    width: 70%;
  }
  .widescreen--eight-tenths {
    width: 80%;
  }
  .widescreen--nine-tenths {
    width: 90%;
  }
  .widescreen--one-twelfth {
    width: 8.33333%;
  }
  .widescreen--two-twelfths {
    width: 16.66667%;
  }
  .widescreen--three-twelfths {
    width: 25%;
  }
  .widescreen--four-twelfths {
    width: 33.33333%;
  }
  .widescreen--five-twelfths {
    width: 41.66667%;
  }
  .widescreen--six-twelfths {
    width: 50%;
  }
  .widescreen--seven-twelfths {
    width: 58.33333%;
  }
  .widescreen--eight-twelfths {
    width: 66.66667%;
  }
  .widescreen--nine-twelfths {
    width: 75%;
  }
  .widescreen--ten-twelfths {
    width: 83.33333%;
  }
  .widescreen--eleven-twelfths {
    width: 91.66667%;
  }
  .grid--uniform .widescreen--five-tenths:nth-of-type(odd),
  .grid--uniform .widescreen--four-eighths:nth-of-type(odd),
  .grid--uniform .widescreen--four-twelfths:nth-of-type(3n + 1),
  .grid--uniform .widescreen--one-eighth:nth-of-type(8n + 1),
  .grid--uniform .widescreen--one-fifth:nth-of-type(5n + 1),
  .grid--uniform .widescreen--one-half:nth-of-type(odd),
  .grid--uniform .widescreen--one-quarter:nth-of-type(4n + 1),
  .grid--uniform .widescreen--one-sixth:nth-of-type(6n + 1),
  .grid--uniform .widescreen--one-third:nth-of-type(3n + 1),
  .grid--uniform .widescreen--one-twelfth:nth-of-type(12n + 1),
  .grid--uniform .widescreen--six-twelfths:nth-of-type(odd),
  .grid--uniform .widescreen--three-sixths:nth-of-type(odd),
  .grid--uniform .widescreen--three-twelfths:nth-of-type(4n + 1),
  .grid--uniform .widescreen--two-eighths:nth-of-type(4n + 1),
  .grid--uniform .widescreen--two-sixths:nth-of-type(3n + 1),
  .grid--uniform .widescreen--two-twelfths:nth-of-type(6n + 1) {
    clear: both;
  }
}

@media only screen and (min-width:769px) {
  .medium-up--push-one-half {
    left: 50%;
  }
  .medium-up--push-one-third {
    left: 33.33333%;
  }
  .medium-up--push-two-thirds {
    left: 66.66667%;
  }
  .medium-up--push-one-quarter {
    left: 25%;
  }
  .medium-up--push-two-quarters {
    left: 50%;
  }
  .medium-up--push-three-quarters {
    left: 75%;
  }
  .medium-up--push-one-fifth {
    left: 20%;
  }
  .medium-up--push-two-fifths {
    left: 40%;
  }
  .medium-up--push-three-fifths {
    left: 60%;
  }
  .medium-up--push-four-fifths {
    left: 80%;
  }
  .medium-up--push-one-sixth {
    left: 16.66667%;
  }
  .medium-up--push-two-sixths {
    left: 33.33333%;
  }
  .medium-up--push-three-sixths {
    left: 50%;
  }
  .medium-up--push-four-sixths {
    left: 66.66667%;
  }
  .medium-up--push-five-sixths {
    left: 83.33333%;
  }
  .medium-up--push-one-eighth {
    left: 12.5%;
  }
  .medium-up--push-two-eighths {
    left: 25%;
  }
  .medium-up--push-three-eighths {
    left: 37.5%;
  }
  .medium-up--push-four-eighths {
    left: 50%;
  }
  .medium-up--push-five-eighths {
    left: 62.5%;
  }
  .medium-up--push-six-eighths {
    left: 75%;
  }
  .medium-up--push-seven-eighths {
    left: 87.5%;
  }
  .medium-up--push-one-tenth {
    left: 10%;
  }
  .medium-up--push-two-tenths {
    left: 20%;
  }
  .medium-up--push-three-tenths {
    left: 30%;
  }
  .medium-up--push-four-tenths {
    left: 40%;
  }
  .medium-up--push-five-tenths {
    left: 50%;
  }
  .medium-up--push-six-tenths {
    left: 60%;
  }
  .medium-up--push-seven-tenths {
    left: 70%;
  }
  .medium-up--push-eight-tenths {
    left: 80%;
  }
  .medium-up--push-nine-tenths {
    left: 90%;
  }
  .medium-up--push-one-twelfth {
    left: 8.33333%;
  }
  .medium-up--push-two-twelfths {
    left: 16.66667%;
  }
  .medium-up--push-three-twelfths {
    left: 25%;
  }
  .medium-up--push-four-twelfths {
    left: 33.33333%;
  }
  .medium-up--push-five-twelfths {
    left: 41.66667%;
  }
  .medium-up--push-six-twelfths {
    left: 50%;
  }
  .medium-up--push-seven-twelfths {
    left: 58.33333%;
  }
  .medium-up--push-eight-twelfths {
    left: 66.66667%;
  }
  .medium-up--push-nine-twelfths {
    left: 75%;
  }
  .medium-up--push-ten-twelfths {
    left: 83.33333%;
  }
  .medium-up--push-eleven-twelfths {
    left: 91.66667%;
  }
}

@media only screen and (min-width:1050px) {
  .widescreen--push-one-half {
    left: 50%;
  }
  .widescreen--push-one-third {
    left: 33.33333%;
  }
  .widescreen--push-two-thirds {
    left: 66.66667%;
  }
  .widescreen--push-one-quarter {
    left: 25%;
  }
  .widescreen--push-two-quarters {
    left: 50%;
  }
  .widescreen--push-three-quarters {
    left: 75%;
  }
  .widescreen--push-one-fifth {
    left: 20%;
  }
  .widescreen--push-two-fifths {
    left: 40%;
  }
  .widescreen--push-three-fifths {
    left: 60%;
  }
  .widescreen--push-four-fifths {
    left: 80%;
  }
  .widescreen--push-one-sixth {
    left: 16.66667%;
  }
  .widescreen--push-two-sixths {
    left: 33.33333%;
  }
  .widescreen--push-three-sixths {
    left: 50%;
  }
  .widescreen--push-four-sixths {
    left: 66.66667%;
  }
  .widescreen--push-five-sixths {
    left: 83.33333%;
  }
  .widescreen--push-one-eighth {
    left: 12.5%;
  }
  .widescreen--push-two-eighths {
    left: 25%;
  }
  .widescreen--push-three-eighths {
    left: 37.5%;
  }
  .widescreen--push-four-eighths {
    left: 50%;
  }
  .widescreen--push-five-eighths {
    left: 62.5%;
  }
  .widescreen--push-six-eighths {
    left: 75%;
  }
  .widescreen--push-seven-eighths {
    left: 87.5%;
  }
  .widescreen--push-one-tenth {
    left: 10%;
  }
  .widescreen--push-two-tenths {
    left: 20%;
  }
  .widescreen--push-three-tenths {
    left: 30%;
  }
  .widescreen--push-four-tenths {
    left: 40%;
  }
  .widescreen--push-five-tenths {
    left: 50%;
  }
  .widescreen--push-six-tenths {
    left: 60%;
  }
  .widescreen--push-seven-tenths {
    left: 70%;
  }
  .widescreen--push-eight-tenths {
    left: 80%;
  }
  .widescreen--push-nine-tenths {
    left: 90%;
  }
  .widescreen--push-one-twelfth {
    left: 8.33333%;
  }
  .widescreen--push-two-twelfths {
    left: 16.66667%;
  }
  .widescreen--push-three-twelfths {
    left: 25%;
  }
  .widescreen--push-four-twelfths {
    left: 33.33333%;
  }
  .widescreen--push-five-twelfths {
    left: 41.66667%;
  }
  .widescreen--push-six-twelfths {
    left: 50%;
  }
  .widescreen--push-seven-twelfths {
    left: 58.33333%;
  }
  .widescreen--push-eight-twelfths {
    left: 66.66667%;
  }
  .widescreen--push-nine-twelfths {
    left: 75%;
  }
  .widescreen--push-ten-twelfths {
    left: 83.33333%;
  }
  .widescreen--push-eleven-twelfths {
    left: 91.66667%;
  }
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media only screen and (max-width:768px) {
  .small--show {
    display: block !important;
  }
  .small--hide {
    display: none !important;
  }
  .small--text-left {
    text-align: left !important;
  }
  .small--text-right {
    text-align: right !important;
  }
  .small--text-center {
    text-align: center !important;
  }
}

@media only screen and (max-width:959px) {
  .medium-down--show {
    display: block !important;
  }
  .medium-down--hide {
    display: none !important;
  }
  .medium-down--text-left {
    text-align: left !important;
  }
  .medium-down--text-right {
    text-align: right !important;
  }
  .medium-down--text-center {
    text-align: center !important;
  }
}

@media only screen and (min-width:769px) {
  .medium-up--show {
    display: block !important;
  }
  .medium-up--hide {
    display: none !important;
  }
  .medium-up--text-left {
    text-align: left !important;
  }
  .medium-up--text-right {
    text-align: right !important;
  }
  .medium-up--text-center {
    text-align: center !important;
  }
}

@media only screen and (min-width:1050px) {
  .widescreen--show {
    display: block !important;
  }
  .widescreen--hide {
    display: none !important;
  }
  .widescreen--text-left {
    text-align: left !important;
  }
  .widescreen--text-right {
    text-align: right !important;
  }
  .widescreen--text-center {
    text-align: center !important;
  }
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%
}

[data-center-text=true] .flex-grid {
  justify-content: center
}

.flex-grid--center {
  align-items: center;
}

.flex-grid--gutters {
  margin-top: -15px;
  margin-left: -15px;
}

.flex-grid__item {
  flex: 0 1 100%;
  display: flex;
  align-items: stretch
}

.flex-grid--gutters .flex-grid__item {
  padding-top: 15px;
  padding-left: 15px
}

.flex-grid__item > * {
  flex: 1 1 100%;
}

.flex-grid__item--stretch {
  flex: 1 1 100%
}

.flex-grid__item--stretch:first-child {
  min-width: 250px;
}

@media only screen and (min-width:769px) {
  .flex-grid__item--33 {
    flex-basis: 33.33%;
  }

  .flex-grid__item--50 {
    flex-basis: 50%;
  }
}

@media only screen and (max-width:768px) {
  .flex-grid__item--mobile-second {
    order: 2;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

html:not(.tab-outline):focus {
  outline: none;
}

.is-transitioning {
  display: block !important;
  visibility: visible !important;
}

.display-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
  float: none;
}

@media only screen and (min-width:769px) {
  .medium-up--display-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .medium-up--display-table-cell {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

.visually-hidden {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.visually-invisible {
  opacity: 0 !important;
}

.skip-link:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  color: #000000
  ;
  color: var(--colorTextBody);
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  padding: 10px;
  opacity: 1;
  z-index: 10000;
  transition: none;
}

html {
  touch-action: manipulation;
}

html[dir=rtl] {
  direction: rtl;
}

body,
html {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
}

.page-width {
  max-width: 1500px;
  margin: 0 auto;
}

.page-full,
.page-width {
  padding: 0 17px
}

@media only screen and (min-width:769px) {

  .page-full,
  .page-width {
    padding: 0 40px
  }
}

@media only screen and (max-width:768px) {
  .page-width--flush-small {
    padding: 0;
  }
}

.page-content,
.shopify-email-marketing-confirmation__container,
.shopify-policy__container {
  padding-top: 40px;
  padding-bottom: 40px
}

@media only screen and (min-width:769px) {

  .page-content,
  .shopify-email-marketing-confirmation__container,
  .shopify-policy__container {
    padding-top: 75px;
    padding-bottom: 75px
  }
}

.shopify-email-marketing-confirmation__container {
  text-align: center;
}

.page-content--top,
.page-content--with-blocks {
  padding-bottom: 0;
}

.page-content--bottom {
  padding-top: 0;
}

.main-content {
  display: block;
  min-height: 300px
}

@media only screen and (min-width:769px) {

  .main-content {
    min-height: 700px
  }
}

.template-challange .main-content {
  min-height: 0
}

.hr--large,
.hr--medium,
.hr--small,
hr {
  height: 1px;
  border: 0;
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorBorder);
}

.hr--small {
  margin: 15px auto;
}

.hr--medium {
  margin: 25px auto
}

@media only screen and (min-width:769px) {

  .hr--medium {
    margin: 35px auto
  }
}

.hr--large {
  margin: 30px auto
}

@media only screen and (min-width:769px) {

  .hr--large {
    margin: 45px auto
  }
}

.page-blocks + .hr--large,
.page-blocks + [data-section-type=recently-viewed] .hr--large {
  margin-top: 0
}

.hr--clear {
  border: 0;
}

@media only screen and (max-width:768px) {
  .table--responsive thead {
    display: none;
  }

  .table--responsive tr {
    display: block;
  }

  .table--responsive td,
  .table--responsive tr {
    float: left;
    clear: both;
    width: 100%;
  }

  .table--responsive td,
  .table--responsive th {
    display: block;
    text-align: right;
    padding: 15px;
  }

  .table--responsive td:before {
    content: attr(data-label);
    float: left;
    font-size: 12px;
    padding-right: 10px;
  }
}

@media only screen and (max-width:768px) {
  .table--small-hide {
    display: none !important;
  }

  .table__section + .table__section {
    position: relative;
    margin-top: 10px;
    padding-top: 15px
  }

  .table__section + .table__section:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    border-bottom: 1px solid;
    border-bottom-color: #e8e8e1
    ;
    border-bottom-color: var(--colorBorder);
  }
}

.faux-select,
body,
button,
input,
select,
textarea {
  font-family: var(--typeBasePrimary), var(--typeBaseFallback);
  font-size: calc(var(--typeBaseSize) * 0.85);
  letter-spacing: var(--typeBaseSpacing);
  line-height: var(--typeBaseLineHeight);
}

@media only screen and (min-width:769px) {

  .faux-select,
  body,
  button,
  input,
  select,
  textarea {
    font-size: var(--typeBaseSize);
  }
}

.faux-select,
body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeSpeed;
}

body {
  font-weight: var(--typeBaseWeight);
}

p {
  margin: 0 0 15px
}

p img {
  margin: 0;
}

em {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

p[data-spam-detection-disclaimer],
small {
  font-size: 0.85em;
}

sub,
sup {
  position: relative;
  font-size: 60%;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.5em;
}

.rte blockquote,
blockquote {
  margin: 0;
  padding: 15px 30px 40px
}

.rte blockquote p,
blockquote p {
  margin-bottom: 0
}

.rte blockquote p + cite,
blockquote p + cite {
  margin-top: 15px;
}

.rte blockquote cite,
blockquote cite {
  display: block
}

.rte blockquote cite:before,
blockquote cite:before {
  content: "\2014 \0020";
}

code,
pre {
  background-color: #faf7f5;
  font-family: Consolas, monospace;
  font-size: 1em;
  border: 0 none;
  padding: 0 2px;
  color: #51ab62;
}

pre {
  overflow: auto;
  padding: 15px;
  margin: 0 0 30px;
}

.label,
label:not(.variant__button-label) {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.75em;
}

label {
  display: block;
  margin-bottom: 10px;
}

.label-info {
  display: block;
  margin-bottom: 10px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 0 0 7.5px
}

@media only screen and (min-width:769px) {

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 15px
  }
}

.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
  font-weight: inherit;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight);
}

[data-type_header_capitalize=true] .h1,
[data-type_header_capitalize=true] .h2,
[data-type_header_capitalize=true] .h3,
[data-type_header_capitalize=true] h1,
[data-type_header_capitalize=true] h2,
[data-type_header_capitalize=true] h3 {
  text-transform: uppercase;
}

.h1,
h1 {
  font-size: calc(var(--typeHeaderSize) * 0.85);
}

.h2,
h2 {
  font-size: calc(var(--typeHeaderSize) * 0.73);
}

.h3,
h3 {
  font-size: calc(var(--typeHeaderSize) * 0.67);
}

@media only screen and (min-width:769px) {
  .h1,
  h1 {
    font-size: var(--typeHeaderSize);
  }

  .h2,
  h2 {
    font-size: calc(var(--typeHeaderSize) * 0.85);
  }

  .h3,
  h3 {
    font-size: calc(var(--typeHeaderSize) * 0.7);
  }
}

.h4,
h4 {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.75em;
}

.h5,
.h6,
h5,
h6 {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.75em;
  margin-bottom: 10px
}

@media only screen and (max-width:768px) {

  .h5,
  .h6,
  h5,
  h6 {
    margin-bottom: 5px
  }
}

.text-spacing,
.text-spacing.rte:last-child {
  margin-bottom: 15px;
}

@media only screen and (max-width:768px) {

  .rte table td,
  .rte table th {
    padding: 6px 8px;
  }
}

.collapsible-content .rte table td,
.collapsible-content .rte table th {
  padding: 6px 8px;
}

.comment-author {
  margin-bottom: 0;
}

.comment-date {
  font-size: calc(var(--typeBaseSize) * 0.85);
  display: block;
  margin-top: 3px
}

@media only screen and (max-width:768px) {

  .comment-date {
    margin-bottom: 15px
  }
}

.ajaxcart__product-meta {
  font-size: calc(var(--typeBaseSize) * 0.85);
}

.ajaxcart__subtotal {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.75em;
  margin-bottom: 10px;
}

.ajaxcart__price {
  font-size: calc(var(--typeBaseSize) * 0.85);
  margin-bottom: 10px;
}

.ajaxcart__note {
  font-size: calc(var(--typeBaseSize) * 0.85);
  opacity: 0.8;
  margin-bottom: 10px
}

@media only screen and (max-width:768px) {

  .ajaxcart__note {
    font-size: max(calc(var(--typeBaseSize) * 0.7), 12px)
  }
}

.ajaxcart__note--terms {
  margin-top: 10px
}

.ajaxcart__note--terms input {
  vertical-align: middle;
}

.ajaxcart__note--terms label {
  display: inline;
}

.ajaxcart__note--terms a {
  text-decoration: underline;
}

.rte .enlarge-text {
  margin: 0;
  font-size: 1.3em
}

.rte .enlarge-text p:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width:769px) {

  .rte .enlarge-text--offset p {
    padding-right: 15%
  }

  .text-center .rte .enlarge-text--offset p {
    padding: 0 5%
  }
}

@media only screen and (min-width:769px) {
  .table--small-text {
    font-size: calc(var(--typeBaseSize) * 0.85);
  }
}

.index-section--footer h3 {
  font-size: 1.5em;
}

html[dir=rtl] .text-left {
  text-align: right !important;
}

html[dir=rtl] .text-right {
  text-align: left !important;
}

ol,
ul {
  margin: 0 0 15px 30px;
  padding: 0;
  text-rendering: optimizeLegibility;
}

ol ol {
  list-style: lower-alpha;
}

ol {
  list-style: decimal;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 4px 0 5px 20px;
}

li {
  margin-bottom: 0.25em;
}

ul.square {
  list-style: square outside;
}

ul.disc {
  list-style: disc outside;
}

ol.alpha {
  list-style: lower-alpha outside;
}

.no-bullets {
  list-style: none outside;
  margin-left: 0;
}

.inline-list {
  padding: 0;
  margin: 0
}

.inline-list li {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

table {
  width: 100%;
  border-spacing: 1px;
  position: relative;
  border: 0 none;
  background: #e8e8e1
  ;
  background: var(--colorBorder);
}

.table-wrapper {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

td,
th {
  border: 0 none;
  text-align: left;
  padding: 10px 15px;
  background: #ffffff
  ;
  background: var(--colorBody)
}

html[dir=rtl] td,
html[dir=rtl] th {
  text-align: right
}

th {
  font-weight: 700;
}

.table__title,
th {
  font-weight: 700;
}

.text-link,
a {
  color: #000000
  ;
  color: var(--colorTextBody);
  text-decoration: none;
  background: transparent
}

.text-link:hover,
a:hover {
  color: #000000
  ;
  color: var(--colorTextBody);
}

.text-link {
  display: inline;
  border: 0 none;
  background: none;
  padding: 0;
  margin: 0;
}

.rte a:not(.stamped-rewards-widget-btn),
.shopify-email-marketing-confirmation__container a,
.shopify-policy__container a {
  color: #000000
  ;
  color: var(--colorLink);
}

button {
  overflow: visible;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

.btn,
.product-reviews .spr-button,
.product-reviews .spr-summary-actions a,
.rte .btn,
.shopify-payment-button .shopify-payment-button__button--unbranded {
  line-height: 1.42;
  text-decoration: none;
  text-align: center;
  white-space: normal;

  font-size: calc(var(--typeBaseSize) - 4px);
  font-size: max(calc(var(--typeBaseSize) - 4px), 13px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.3em;

  display: inline-block;
  padding: 11px 20px;
  margin: 0;
  width: auto;
  min-width: 90px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: var(--buttonRadius);
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  background: #111111
  ;
  background: var(--colorBtnPrimary)
}

button.add-to-cart,
button.add-to-cart:hover {
  background-color: #0483c0;
}


@media only screen and (max-width:768px) {

  .btn,
  .product-reviews .spr-button,
  .product-reviews .spr-summary-actions a,
  .rte .btn,
  .shopify-payment-button .shopify-payment-button__button--unbranded {
    padding: 9px 17px;
    font-size: calc(var(--typeBaseSize) - 6px);
    font-size: max(calc(var(--typeBaseSize) - 6px), 11px)
  }
}

.btn:hover,
.product-reviews .spr-button:hover,
.product-reviews .spr-summary-actions a:hover,
.rte .btn:hover,
.shopify-payment-button .shopify-payment-button__button--unbranded:hover {
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  background-color: #111111
  ;
  background-color: var(--colorBtnPrimary);
}

.btn.disabled,
.btn[disabled],
.product-reviews .spr-button.disabled,
.product-reviews .spr-button[disabled],
.product-reviews .spr-summary-actions a.disabled,
.product-reviews .spr-summary-actions a[disabled],
.rte .btn.disabled,
.rte .btn[disabled],
.shopify-payment-button .shopify-payment-button__button--unbranded.disabled,
.shopify-payment-button .shopify-payment-button__button--unbranded[disabled] {
  cursor: default;
  color: #b6b6b6;
  background-color: #f6f6f6
}

.btn.disabled:hover,
.btn[disabled]:hover,
.product-reviews .spr-button.disabled:hover,
.product-reviews .spr-button[disabled]:hover,
.product-reviews .spr-summary-actions a.disabled:hover,
.product-reviews .spr-summary-actions a[disabled]:hover,
.rte .btn.disabled:hover,
.rte .btn[disabled]:hover,
.shopify-payment-button .shopify-payment-button__button--unbranded.disabled:hover,
.shopify-payment-button .shopify-payment-button__button--unbranded[disabled]:hover {
  color: #b6b6b6;
  background-color: #f6f6f6;
}

[data-button_style=angled] .btn.disabled:after,
[data-button_style=angled] .btn.disabled:before,
[data-button_style=angled] .btn[disabled]:after,
[data-button_style=angled] .btn[disabled]:before,
[data-button_style=angled] .product-reviews .spr-button.disabled:after,
[data-button_style=angled] .product-reviews .spr-button.disabled:before,
[data-button_style=angled] .product-reviews .spr-button[disabled]:after,
[data-button_style=angled] .product-reviews .spr-button[disabled]:before,
[data-button_style=angled] .product-reviews .spr-summary-actions a.disabled:after,
[data-button_style=angled] .product-reviews .spr-summary-actions a.disabled:before,
[data-button_style=angled] .product-reviews .spr-summary-actions a[disabled]:after,
[data-button_style=angled] .product-reviews .spr-summary-actions a[disabled]:before,
[data-button_style=angled] .rte .btn.disabled:after,
[data-button_style=angled] .rte .btn.disabled:before,
[data-button_style=angled] .rte .btn[disabled]:after,
[data-button_style=angled] .rte .btn[disabled]:before,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded.disabled:after,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded.disabled:before,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded[disabled]:after,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded[disabled]:before {
  background-color: #f6f6f6;
  border-top: 1px solid;
  border-top-color: #b6b6b6;
  border-bottom: 1px solid;
  border-bottom-color: #b6b6b6;
}

[data-button_style=angled] .btn,
[data-button_style=angled] .product-reviews .spr-button,
[data-button_style=angled] .product-reviews .spr-summary-actions a,
[data-button_style=angled] .rte .btn,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded {
  position: relative;
  border: 0;
  margin-left: 10px;
  margin-right: 10px
}

[data-button_style=angled] .btn:after,
[data-button_style=angled] .btn:before,
[data-button_style=angled] .product-reviews .spr-button:after,
[data-button_style=angled] .product-reviews .spr-button:before,
[data-button_style=angled] .product-reviews .spr-summary-actions a:after,
[data-button_style=angled] .product-reviews .spr-summary-actions a:before,
[data-button_style=angled] .rte .btn:after,
[data-button_style=angled] .rte .btn:before,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded:after,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 20px;
  transform: skewX(-12deg);
  background-color: inherit;
}

[data-button_style=angled] .btn:before,
[data-button_style=angled] .product-reviews .spr-button:before,
[data-button_style=angled] .product-reviews .spr-summary-actions a:before,
[data-button_style=angled] .rte .btn:before,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded:before {
  left: -6px;
}

[data-button_style=angled] .btn:after,
[data-button_style=angled] .product-reviews .spr-button:after,
[data-button_style=angled] .product-reviews .spr-summary-actions a:after,
[data-button_style=angled] .rte .btn:after,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded:after {
  right: -6px;
}

[data-button_style=angled] .btn.btn--small:before,
[data-button_style=angled] .product-reviews .spr-button.btn--small:before,
[data-button_style=angled] .product-reviews .spr-summary-actions a.btn--small:before,
[data-button_style=angled] .rte .btn.btn--small:before,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded.btn--small:before {
  left: -5px;
}

[data-button_style=angled] .btn.btn--small:after,
[data-button_style=angled] .product-reviews .spr-button.btn--small:after,
[data-button_style=angled] .product-reviews .spr-summary-actions a.btn--small:after,
[data-button_style=angled] .rte .btn.btn--small:after,
[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded.btn--small:after {
  right: -5px;
}

[data-button_style=square] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style=square] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style=square] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style=square] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style=square] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style^=round] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style^=round] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style^=round] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style^=round] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static),
[data-button_style^=round] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static) {
  position: relative;
  overflow: hidden;
  transition: background 0.2s ease 0s
}

[data-button_style=square] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style=square] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style=square] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style=square] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style=square] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style^=round] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style^=round] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style^=round] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style^=round] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after,
[data-button_style^=round] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):after {
  content: "";
  position: absolute;
  top: 0;
  left: 150%;
  width: 200%;
  height: 100%;
  transform: skewX(-20deg);
  background-image: linear-gradient(90deg,transparent, hsla(0, 0%, 100%, 0.25),transparent);
}

[data-button_style=square] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style=square] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style=square] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style=square] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style=square] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style^=round] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style^=round] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style^=round] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style^=round] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after,
[data-button_style^=round] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover:after {
  animation: shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);
}

[data-button_style=square] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style=square] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style=square] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style=square] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style=square] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style^=round] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style^=round] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style^=round] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style^=round] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover,
[data-button_style^=round] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):hover {
  background: #2b2b2b
  ;
  background: var(--colorBtnPrimaryLight);
  transition-delay: 0.25s;
}

[data-button_style=square] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style=square] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style=square] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style=square] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style=square] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style^=round] .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style^=round] .product-reviews .spr-button:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style^=round] .product-reviews .spr-summary-actions a:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style^=round] .rte .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active,
[data-button_style^=round] .shopify-payment-button .shopify-payment-button__button--unbranded:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static):active {
  background: #111111
  ;
  background: var(--colorBtnPrimary);
  transition-delay: 0s;
}

.shopify-payment-button .shopify-payment-button__button--unbranded:hover:not([disabled]) {
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  background-color: #111111
  ;
  background-color: var(--colorBtnPrimary);
}

.shopify-payment-button__more-options {
  color: inherit;
}

.btn--secondary,
.rte .btn--secondary {
  color: #000000
  ;
  color: var(--colorTextBody);
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  background-color: transparent
}

[data-button_style=angled] .btn--secondary,
[data-button_style=angled] .rte .btn--secondary {
  border-left: 0;
  border-right: 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorBorder);
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorBorder)
}

[data-button_style=angled] .btn--secondary:after,
[data-button_style=angled] .btn--secondary:before,
[data-button_style=angled] .rte .btn--secondary:after,
[data-button_style=angled] .rte .btn--secondary:before {
  background-color: transparent;
  top: -1px;
  bottom: -1px;
}

[data-button_style=angled] .btn--secondary:before,
[data-button_style=angled] .rte .btn--secondary:before {
  border-left: 1px solid;
  border-left-color: #e8e8e1
  ;
  border-left-color: var(--colorBorder);
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorBorder);
}

[data-button_style=angled] .btn--secondary:after,
[data-button_style=angled] .rte .btn--secondary:after {
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorBorder);
  border-right: 1px solid;
  border-right-color: #e8e8e1
  ;
  border-right-color: var(--colorBorder);
}

.btn--secondary:hover,
.rte .btn--secondary:hover {
  color: #000000
  ;
  color: var(--colorTextBody);
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  background-color: transparent
}

.btn--secondary:hover:before,
.rte .btn--secondary:hover:before {
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
}

.btn--secondary:hover:after,
.rte .btn--secondary:hover:after {
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
}

.btn--tertiary,
.rte .btn--tertiary {
  font-weight: 400;
  text-transform: none;
  letter-spacing: normal;
  background-color: transparent;
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  color: #000000
  ;
  color: var(--colorTextBody);
  padding: 8px 10px;
  white-space: nowrap
}

.btn--tertiary:hover,
.rte .btn--tertiary:hover {
  background-color: transparent;
  color: #000000
  ;
  color: var(--colorTextBody);
}

.btn--tertiary.disabled,
.btn--tertiary[disabled],
.rte .btn--tertiary.disabled,
.rte .btn--tertiary[disabled] {
  cursor: default;
  color: #b6b6b6;
  background-color: #f6f6f6;
  border-color: #b6b6b6;
}

[data-button_style=angled] .btn--tertiary,
[data-button_style=angled] .rte .btn--tertiary {
  margin-left: 0;
  margin-right: 0
}

[data-button_style=angled] .btn--tertiary:after,
[data-button_style=angled] .btn--tertiary:before,
[data-button_style=angled] .rte .btn--tertiary:after,
[data-button_style=angled] .rte .btn--tertiary:before {
  content: none;
}

.btn--tertiary-active {
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  background: #111111
  ;
  background: var(--colorBtnPrimary);
  border-color: #111111
  ;
  border-color: var(--colorBtnPrimary)
}

.btn--tertiary-active:hover {
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  background: #111111
  ;
  background: var(--colorBtnPrimary);
}

.btn--body {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody)
}

.btn--body:active,
.btn--body:hover {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
}

.btn--circle {
  padding: 10px;
  border-radius: 50%;
  min-width: 0;
  line-height: 1
}

.btn--circle .icon {
  width: 20px;
  height: 20px;
}

.btn--circle:after,
.btn--circle:before {
  content: none;
  background: none;
  width: auto;
}

.btn--circle.btn--large .icon {
  width: 30px;
  height: 30px;
}

.btn--circle.btn--large {
  padding: 15px;
}

[data-button_style=angled] .btn--circle {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder)
}

[data-button_style=angled] .btn--circle:after,
[data-button_style=angled] .btn--circle:before {
  display: none;
}

.btn--small,
.collapsibles-wrapper .spr-button,
.collapsibles-wrapper .spr-summary-actions a {
  padding: 8px 14px;
  background-position: 150% 45%;
  min-width: 90px;
  font-size: calc(var(--typeBaseSize) - 6px);
  font-size: max(calc(var(--typeBaseSize) - 6px), 12px)
}

[data-button_style=angled] .btn--small,
[data-button_style=angled] .collapsibles-wrapper .spr-button,
[data-button_style=angled] .collapsibles-wrapper .spr-summary-actions a {
  margin-left: 10px;
  padding-left: 16px;
  padding-right: 16px
}

@media only screen and (max-width:768px) {

  .btn--small,
  .collapsibles-wrapper .spr-button,
  .collapsibles-wrapper .spr-summary-actions a {
    font-size: calc(var(--typeBaseSize) - 8px);
    font-size: max(calc(var(--typeBaseSize) - 8px), 10px)
  }
}

.btn--secondary.btn--small {
  font-weight: 400;
}

.btn--large {
  padding: 15px 20px;
}

.btn--full {
  width: 100%;
  padding: 13px 20px;
  transition: none;
}

[data-button_style=angled] .btn--full {
  max-width: 94%
}

[data-button_style=angled] .shopify-payment-button .shopify-payment-button__button--unbranded {
  max-width: 94%;
}

.btn--inverse {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff
}

.btn--inverse:focus,
.btn--inverse:hover {
  background-color: transparent;
}

[data-button_style=angled] .btn--inverse {
  border-left: 0;
  border-right: 0;
  border-top: 2px solid;
  border-bottom: 2px solid
}

[data-button_style=angled] .btn--inverse:after,
[data-button_style=angled] .btn--inverse:before {
  background-color: transparent;
  border-color: #fff;
  top: -2px;
  bottom: -2px;
}

[data-button_style=angled] .btn--inverse:before {
  border-left: 2px solid;
  border-bottom: 2px solid;
}

[data-button_style=angled] .btn--inverse:after {
  border-top: 2px solid;
  border-right: 2px solid;
}

.hero__link .btn--inverse {
  color: #ffffff
  ;
  color: var(--colorHeroText);
  border-color: #ffffff
  ;
  border-color: var(--colorHeroText)
}

[data-button_style=angled] .hero__link .btn--inverse:before {
  border-color: #ffffff
  ;
  border-color: var(--colorHeroText);
}

[data-button_style=angled] .hero__link .btn--inverse:after {
  border-color: #ffffff
  ;
  border-color: var(--colorHeroText);
}

.btn--loading {
  position: relative;
  text-indent: -9999px;
  background-color: #040404
  ;
  background-color: var(--colorBtnPrimaryDim);
  color: #040404
  ;
  color: var(--colorBtnPrimaryDim)
}

.btn--loading:active,
.btn--loading:hover {
  background-color: #040404
  ;
  background-color: var(--colorBtnPrimaryDim);
  color: #040404
  ;
  color: var(--colorBtnPrimaryDim);
}

.btn--loading:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #ffffff
  ;
  border-color: var(--colorBtnPrimaryText);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

[data-button_style=angled] .btn--loading:before {
  left: 50%;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: none;
  border: 3px solid;
  border-color: #ffffff
   !important;
  border-color: var(--colorBtnPrimaryText) !important;
  border-top-color: transparent !important;
}

[data-button_style=angled] .btn--loading:after {
  background-color: #040404
  ;
  background-color: var(--colorBtnPrimaryDim);
}

[data-button_style=angled] .btn--loading.btn--secondary:after {
  bottom: 1px;
}

.return-link {
  text-align: center;
  padding: 15px 25px;
  margin-top: 50px
}

@media only screen and (max-width:768px) {

  .return-link {
    padding: 22px 17px;
    width: 100%
  }

  [data-button_style=angled] .return-link {
    width: 90%
  }
}

.return-link .icon {
  width: 20px;
  margin-right: 8px;
}

.collapsible-trigger-btn {
  text-align: left
}

[data-center-text=true] .collapsible-trigger-btn {
  text-align: center
}

.collapsible-trigger-btn {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.75em;
  display: block;
  width: 100%;
  padding: 17.14286px 0
}

@media only screen and (max-width:768px) {

  .collapsible-trigger-btn {
    padding: 15px 0
  }
}

.collection-sidebar__group .collapsible-trigger-btn {
  text-align: left
}

.collapsible-trigger-btn--borders {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  border-bottom: 0;
  padding: 12px
}

.collapsible-trigger-btn--borders .collapsible-trigger__icon {
  right: 12px;
}

@media only screen and (min-width:769px) {

  .collapsible-trigger-btn--borders {
    padding: 15px
  }

  .collapsible-trigger-btn--borders .collapsible-trigger__icon {
    right: 15px;
  }
}

.collapsible-content + .collapsible-trigger-btn--borders {
  margin-top: -1px
}

.collapsible-trigger-btn--borders + .collapsible-content .collapsible-content__inner {
  font-size: max(calc(var(--typeBaseSize) * 0.7), 12px);
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  border-top: 0;
  padding: 0 20px 20px
}

@media only screen and (min-width:769px) {

  .collapsible-trigger-btn--borders + .collapsible-content .collapsible-content__inner {
    font-size: calc(var(--typeBaseSize) * 0.85)
  }
}

.collapsible-trigger-btn--borders + .collapsible-content--expanded {
  margin-bottom: 30px
}

.collapsible-trigger-btn--borders + .collapsible-content--expanded:last-child {
  margin-bottom: -1px;
}

.collapsible-trigger-btn--borders-top {
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorBorder);
}

.shopify-payment-button {
  margin-top: 10px;
}

.shopify-payment-button .shopify-payment-button__button--unbranded {
  display: block;
  width: 100%;
  transition: none;
}

.payment-buttons .add-to-cart,
.payment-buttons .shopify-payment-button,
.payment-buttons .shopify-payment-button__button--unbranded {
  min-height: 50px;
}

.add-to-cart.btn--secondary {
  border: 1px solid;
  border-color: #000000
  ;
  border-color: var(--colorTextBody)
}

[data-button_style=angled] .add-to-cart.btn--secondary {
  border-left: 0;
  border-right: 0
}

[data-button_style=angled] .add-to-cart.btn--secondary:after,
[data-button_style=angled] .add-to-cart.btn--secondary:before {
  border-color: #000000
  ;
  border-color: var(--colorTextBody);
}

.add-to-cart.btn--secondary.disabled,
.add-to-cart.btn--secondary[disabled] {
  border-color: #b6b6b6
}

[data-button_style=angled] .add-to-cart.btn--secondary.disabled:after,
[data-button_style=angled] .add-to-cart.btn--secondary.disabled:before,
[data-button_style=angled] .add-to-cart.btn--secondary[disabled]:after,
[data-button_style=angled] .add-to-cart.btn--secondary[disabled]:before {
  border-color: #b6b6b6;
}

.shopify-payment-button__button--hidden {
  display: none !important;
}

img {
  border: 0 none;
}

svg:not(:root) {
  overflow: hidden;
}

iframe,
img {
  max-width: 100%;
}

img[data-sizes=auto] {
  display: block;
  width: 100%;
}

.lazyload {
  opacity: 0
}

.no-js .lazyload {
  display: none
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto
}

.video-wrapper iframe,
.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrapper--modal {
  width: 1000px;
}

.grid__image-ratio {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  background-color: #ffffff
  ;
  background-color: var(--colorSmallImageBg);
  opacity: 0
}

.grid__image-ratio.lazyloaded {
  opacity: 1;
  animation: fade-in 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none;
}

.grid__image-ratio:before {
  content: "";
  display: block;
  height: 0;
  width: 100%;
}

.grid__image-ratio .placeholder-svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.grid__image-ratio--object {
  opacity: 1;
}

.grid__image-ratio--cover {
  background-size: cover;
}

.grid__image-ratio--wide:before {
  padding-bottom: 56.25%;
}

.grid__image-ratio--landscape:before {
  padding-bottom: 75%;
}

.grid__image-ratio--square:before {
  padding-bottom: 100%;
}

.grid__image-ratio--portrait:before {
  padding-bottom: 150%;
}

.image-fit {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
  z-index: 1;
}

.parallax-container {
  position: absolute;
  top: -30%;
  left: 0;
  height: 160%;
  width: 100%;
}

.parallax-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media only screen and (min-width:769px) {
  .parallax-image {
    background-attachment: fixed
  }
  .js-ipad .parallax-image {
    background-attachment: scroll
  }
}

form {
  margin: 0;
}

[data-center-text=true] .form-vertical {
  text-align: center
}

.form-vertical {
  margin-bottom: 15px
}

.form-vertical label {
  text-align: left;
}

.inline {
  display: inline;
}

@media only screen and (max-width:959px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

button,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button {
  background: none;
  border: none;
  display: inline-block;
  cursor: pointer;
}

fieldset {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  padding: 15px;
}

legend {
  border: 0;
  padding: 0;
}

button,
input[type=submit] {
  cursor: pointer;
}

input,
select,
textarea {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  max-width: 100%;
  padding: 8px 10px;
  border-radius: 0
}

input.disabled,
input[disabled],
select.disabled,
select[disabled],
textarea.disabled,
textarea[disabled] {
  cursor: default;
  border-color: #b6b6b6;
}

input.input-full,
select.input-full,
textarea.input-full {
  width: 100%;
}

textarea {
  min-height: 100px;
}

input[type=checkbox],
input[type=radio] {
  margin: 0 10px 0 0;
  padding: 0;
  width: auto;
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}

input[type=radio] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
}

input[type=image] {
  padding-left: 0;
  padding-right: 0;
}

.faux-select,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: 100%;
  background-image: url('//paracable.com/cdn/shop/t/11/assets/ico-select.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: transparent;
  background-size: 11px;
  padding-right: 28px;
  text-indent: 0.01px;
  text-overflow: "";
  cursor: pointer;
  color: inherit
}

.is-light .faux-select,
.is-light select {
  background-image: url('//paracable.com/cdn/shop/t/11/assets/ico-select-white.svg')
}

optgroup {
  font-weight: 700;
}

option {
  color: #000;
  background-color: #fff
}

option[disabled] {
  color: #ccc;
}

select::-ms-expand {
  display: none;
}

.hidden-label {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

label[for] {
  cursor: pointer;
}

.form-vertical input,
.form-vertical select,
.form-vertical textarea {
  display: block;
  margin-bottom: 30px;
}

.form-vertical .btn,
.form-vertical input[type=checkbox],
.form-vertical input[type=radio] {
  display: inline-block;
}

.form-vertical .btn:not(:last-child) {
  margin-bottom: 30px;
}

small {
  display: block;
}

input.error,
textarea.error {
  border-color: #d02e2e;
  background-color: #fff6f6;
  color: #d02e2e;
}

label.error {
  color: #d02e2e;
}

.selector-wrapper label {
  margin-right: 10px;
}

.selector-wrapper + .selector-wrapper {
  margin-top: 15px;
}

.input-group {
  display: flex
}

.input-group .input-group-btn:first-child .btn,
.input-group .input-group-field:first-child,
.input-group input[type=hidden]:first-child + .input-group-field {
  border-radius: 0 0 0 0;
}

.input-group .input-group-field:last-child {
  border-radius: 0 0 0 0;
}

.input-group .input-group-btn:first-child .btn,
.input-group input[type=hidden]:first-child + .input-group-btn .btn {
  border-radius: var(--buttonRadius) 0 0 var(--buttonRadius);
}

.input-group .input-group-btn:last-child .btn {
  border-radius: 0 var(--buttonRadius) var(--buttonRadius) 0;
}

.input-group input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin-top: -1px;
  margin-bottom: -1px;
}

.input-group-field {
  flex: 1 1 auto;
  margin: 0;
  min-width: 0;
}

.input-group-btn {
  flex: 0 1 auto;
  margin: 0;
  display: flex
}

.input-group-btn .icon {
  vertical-align: baseline;
  vertical-align: initial;
}

[data-button_style=angled] .input-group-btn {
  position: relative;
  left: -8px
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  fill: currentColor
}

.no-svg .icon {
  display: none
}

.icon--full-color {
  fill: initial;
}

svg.icon:not(.icon--full-color) circle,
svg.icon:not(.icon--full-color) ellipse,
svg.icon:not(.icon--full-color) g,
svg.icon:not(.icon--full-color) line,
svg.icon:not(.icon--full-color) path,
svg.icon:not(.icon--full-color) polygon,
svg.icon:not(.icon--full-color) polyline,
svg.icon:not(.icon--full-color) rect,
symbol.icon:not(.icon--full-color) circle,
symbol.icon:not(.icon--full-color) ellipse,
symbol.icon:not(.icon--full-color) g,
symbol.icon:not(.icon--full-color) line,
symbol.icon:not(.icon--full-color) path,
symbol.icon:not(.icon--full-color) polygon,
symbol.icon:not(.icon--full-color) polyline,
symbol.icon:not(.icon--full-color) rect {
  fill: inherit;
  stroke: inherit;
}

.icon-bag circle,
.icon-bag ellipse,
.icon-bag g,
.icon-bag line,
.icon-bag path,
.icon-bag polygon,
.icon-bag polyline,
.icon-bag rect,
.icon-cart circle,
.icon-cart ellipse,
.icon-cart g,
.icon-cart line,
.icon-cart path,
.icon-cart polygon,
.icon-cart polyline,
.icon-cart rect,
.icon-chevron-down circle,
.icon-chevron-down ellipse,
.icon-chevron-down g,
.icon-chevron-down line,
.icon-chevron-down path,
.icon-chevron-down polygon,
.icon-chevron-down polyline,
.icon-chevron-down rect,
.icon-close circle,
.icon-close ellipse,
.icon-close g,
.icon-close line,
.icon-close path,
.icon-close polygon,
.icon-close polyline,
.icon-close rect,
.icon-email circle,
.icon-email ellipse,
.icon-email g,
.icon-email line,
.icon-email path,
.icon-email polygon,
.icon-email polyline,
.icon-email rect,
.icon-filter circle,
.icon-filter ellipse,
.icon-filter g,
.icon-filter line,
.icon-filter path,
.icon-filter polygon,
.icon-filter polyline,
.icon-filter rect,
.icon-hamburger circle,
.icon-hamburger ellipse,
.icon-hamburger g,
.icon-hamburger line,
.icon-hamburger path,
.icon-hamburger polygon,
.icon-hamburger polyline,
.icon-hamburger rect,
.icon-search circle,
.icon-search ellipse,
.icon-search g,
.icon-search line,
.icon-search path,
.icon-search polygon,
.icon-search polyline,
.icon-search rect,
.icon-user circle,
.icon-user ellipse,
.icon-user g,
.icon-user line,
.icon-user path,
.icon-user polygon,
.icon-user polyline,
.icon-user rect {
  fill: none !important;
  stroke-width: var(--iconWeight);
  stroke: currentColor !important;
  stroke-linecap: var(--iconLinecaps);
  stroke-linejoin: var(--iconLinecaps);
}

.icon-cart circle {
  fill: currentColor !important;
}

.icon__fallback-text {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.js-drawer-open {
  overflow: hidden;
}

.drawer {
  display: none;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  top: 0;
  bottom: 0;
  max-width: 95%;
  z-index: 30;
  color: #000000
  ;
  color: var(--colorDrawerText);
  background-color: #ffffff
  ;
  background-color: var(--colorDrawers);
  box-shadow: 0 0 150px rgba(0, 0, 0, 0.1);
  transition: transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1)
}

@media screen and (max-height:400px) {

  .drawer {
    overflow: scroll
  }

  .drawer .drawer__contents {
    height: auto;
  }
}

.drawer a:not(.btn) {
  color: #000000
  ;
  color: var(--colorDrawerText)
}

.drawer a:not(.btn):hover {
  color: #000000
  ;
  color: var(--colorDrawerText);
}

.drawer input,
.drawer textarea {
  border-color: #e8e8e1
  ;
  border-color: var(--colorDrawerBorder);
}

.drawer .btn {
  background-color: #111111
  ;
  background-color: var(--colorDrawerButton);
  color: #ffffff
  ;
  color: var(--colorDrawerButtonText);
}

.drawer--left {
  width: 300px;
  left: -300px
}

.drawer--left.drawer--is-open {
  display: block;
  transform: translateX(300px);
  transition-duration: 0.45s;
}

.drawer--right {
  width: 300px;
  right: -300px
}

@media only screen and (min-width:769px) {

  .drawer--right {
    width: 400px;
    right: -400px
  }
}

.drawer--right.drawer--is-open {
  display: block;
  transform: translateX(-300px);
  transition-duration: 0.45s
}

@media only screen and (min-width:769px) {

  .drawer--right.drawer--is-open {
    transform: translateX(-400px)
  }
}

.js-drawer-closing .main-content:after,
.js-drawer-open .main-content:after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e6e6e6
  ;
  background-color: var(--colorModalBg);
  opacity: 0;
  z-index: 26;
}

.js-drawer-open .main-content:after {
  animation: overlay-on 0.35s forwards;
}

.js-drawer-closing .main-content:after {
  animation: overlay-off 0.25s forwards;
}

.drawer__fixed-header,
.drawer__footer,
.drawer__header,
.drawer__scrollable {
  padding-left: 15px;
  padding-right: 15px
}

@media only screen and (min-width:769px) {

  .drawer__fixed-header,
  .drawer__footer,
  .drawer__header,
  .drawer__scrollable {
    padding-left: 30px;
    padding-right: 30px
  }
}

.drawer__header {
  display: table;
  height: 70px;
  width: 100%;
  padding: 11.53846px 0;
  margin-bottom: 0;
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorDrawerBorder);
}

.drawer__fixed-header {
  height: 70px;
  overflow: visible;
}

@media only screen and (min-width:769px) {
  .drawer__fixed-header,
  .drawer__header {
    height: 119px;
  }

  .drawer__header--full {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.drawer__close,
.drawer__title {
  display: table-cell;
  vertical-align: middle;
}

.drawer__title {
  width: 100%
}

@media only screen and (max-width:768px) {

  .drawer__title {
    padding-left: 15px
  }
}

.drawer__close {
  width: 1%;
  text-align: center;
}

.drawer__close-button {
  position: relative;
  height: 100%;
  padding: 0 15px;
  color: inherit
}

.drawer__close-button:active {
  background-color: #f2f2f2
  ;
  background-color: var(--colorDrawersDim);
}

.drawer__close-button .icon {
  height: 28px;
  width: 28px;
}

@media only screen and (min-width:769px) {

  .drawer__close-button {
    right: -30px
  }
}

.drawer__close--left {
  text-align: left
}

.drawer__close--left .drawer__close-button {
  right: auto;
  left: -30px;
}

.drawer__contents {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drawer__inner,
.drawer__scrollable {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.drawer__scrollable {
  padding-top: 15px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer__footer {
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorDrawerBorder);
  padding-top: 15px;
  padding-bottom: 15px
}

@media only screen and (min-width:769px) {

  .drawer__footer {
    padding-top: 22.22222px;
    padding-bottom: 30px
  }
}

.drawer__inner.is-loading .drawer__scrollable {
  transition: opacity 0.3s ease 0.7s;
  opacity: 0.4;
}

.cart-notes {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 60px;
  height: 60px
}

@media only screen and (min-width:769px) {

  .cart-notes {
    min-height: 80px;
    height: 80px
  }
}

.ajaxcart__row > .grid {
  margin-left: -15px
}

.ajaxcart__row > .grid > .grid__item {
  padding-left: 15px;
}

.ajaxcart__product {
  position: relative;
  max-height: 500px
}

.ajaxcart__product.is-removed {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 450ms cubic-bezier(0.57,0.06,0.05,0.95);
}

.ajaxcart__row {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorDrawerBorder)
}

@media only screen and (min-width:769px) {

  .ajaxcart__row {
    padding-bottom: 22.22222px;
    margin-bottom: 22.22222px
  }
}

.ajaxcart__product:last-child .ajaxcart__row {
  border-bottom: 0 none;
  padding-bottom: 0;
}

.ajaxcart__product-image {
  display: block;
  overflow: hidden;
  padding-top: 3px
}

.ajaxcart__product-image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.ajaxcart__product-meta,
.ajaxcart__product-name {
  display: block;
}

.ajaxcart__product-name--wrapper {
  margin-bottom: 11px;
}

.ajaxcart__product-name + .ajaxcart__product-meta {
  padding-top: 8px;
}

.ajaxcart__discount {
  padding-top: 3px;
}

.placeholder-svg {
  fill: #999;
  background-color: #e1e1e1;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: block;
  padding: 30px 0;
}

.placeholder-noblocks {
  padding: 40px;
  text-align: center;
}

.animation-delay-20 {
  animation-delay: 1.2s;
}

.animation-delay-19 {
  animation-delay: 1.14s;
}

.animation-delay-18 {
  animation-delay: 1.08s;
}

.animation-delay-17 {
  animation-delay: 1.02s;
}

.animation-delay-16 {
  animation-delay: 0.96s;
}

.animation-delay-15 {
  animation-delay: 0.9s;
}

.animation-delay-14 {
  animation-delay: 0.84s;
}

.animation-delay-13 {
  animation-delay: 0.78s;
}

.animation-delay-12 {
  animation-delay: 0.72s;
}

.animation-delay-11 {
  animation-delay: 0.66s;
}

.animation-delay-10 {
  animation-delay: 0.6s;
}

.animation-delay-9 {
  animation-delay: 0.54s;
}

.animation-delay-8 {
  animation-delay: 0.48s;
}

.animation-delay-7 {
  animation-delay: 0.42s;
}

.animation-delay-6 {
  animation-delay: 0.36s;
}

.animation-delay-5 {
  animation-delay: 0.3s;
}

.animation-delay-4 {
  animation-delay: 0.24s;
}

.animation-delay-3 {
  animation-delay: 0.18s;
}

.animation-delay-2 {
  animation-delay: 0.12s;
}

.appear-delay-20 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.4s;
}

.appear-delay-19 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.24s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.34s;
}

.appear-delay-18 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.18s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.28s;
}

.appear-delay-17 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.12s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.22s;
}

.appear-delay-16 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.06s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.16s;
}

.appear-delay-15 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.1s;
}

.appear-delay-14 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.94s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.04s;
}

.appear-delay-13 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.88s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.98s;
}

.appear-delay-12 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.82s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.92s;
}

.appear-delay-11 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.76s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.86s;
}

.appear-delay-10 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
}

.appear-delay-9 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.64s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.74s;
}

.appear-delay-8 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.58s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.68s;
}

.appear-delay-7 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.52s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.62s;
}

.appear-delay-6 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.46s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.56s;
}

.appear-delay-5 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
}

.appear-delay-4 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.34s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.44s;
}

.appear-delay-3 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.28s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.38s;
}

.appear-delay-2 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.22s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.32s;
}

.appear-delay-1 {
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
}

.animation-cropper {
  overflow: hidden;
  display: inline-flex;
}

.image-wrap {
  background: #ffffff
  ;
  background: var(--colorSmallImageBg);
  overflow: hidden;
}

.image-wrap img:not([role=presentation]) {
  display: block
}

.no-js .image-wrap img:not([role=presentation]).lazyload {
  display: none
}

.image-wrap .animate-me,
.image-wrap img:not([role=presentation]),
.image-wrap svg {
  opacity: 0
}

.no-js .image-wrap .animate-me,
.no-js .image-wrap img:not([role=presentation]),
.no-js .image-wrap svg {
  opacity: 1
}

.aos-animate .image-wrap .animate-me,
.aos-animate .image-wrap .lazyloaded:not([role=presentation]),
.aos-animate .image-wrap svg {
  animation: fade-in 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
}

[data-aos=row-of-3].aos-animate:nth-child(3n + 2) .image-wrap img {
  animation-delay: 150ms
}

[data-aos=row-of-3].aos-animate:nth-child(3n + 3) .image-wrap img {
  animation-delay: 300ms
}

[data-aos=row-of-4].aos-animate:nth-child(4n + 2) .image-wrap img {
  animation-delay: 120ms
}

[data-aos=row-of-4].aos-animate:nth-child(4n + 3) .image-wrap img {
  animation-delay: 240ms
}

[data-aos=row-of-4].aos-animate:nth-child(4n + 4) .image-wrap img {
  animation-delay: 360ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 2) .image-wrap img {
  animation-delay: 75ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 3) .image-wrap img {
  animation-delay: 150ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 4) .image-wrap img {
  animation-delay: 225ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 5) .image-wrap img {
  animation-delay: 300ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 2) .image-wrap img {
  animation-delay: 50ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 3) .image-wrap img {
  animation-delay: 100ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 4) .image-wrap img {
  animation-delay: 150ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 5) .image-wrap img {
  animation-delay: 200ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 6) .image-wrap img {
  animation-delay: 250ms
}

[data-aos=row-of-3].aos-animate:nth-child(3n + 2) .collection-image {
  animation-delay: 150ms
}

[data-aos=row-of-3].aos-animate:nth-child(3n + 3) .collection-image {
  animation-delay: 300ms
}

[data-aos=row-of-4].aos-animate:nth-child(4n + 2) .collection-image {
  animation-delay: 120ms
}

[data-aos=row-of-4].aos-animate:nth-child(4n + 3) .collection-image {
  animation-delay: 240ms
}

[data-aos=row-of-4].aos-animate:nth-child(4n + 4) .collection-image {
  animation-delay: 360ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 2) .collection-image {
  animation-delay: 75ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 3) .collection-image {
  animation-delay: 150ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 4) .collection-image {
  animation-delay: 225ms
}

[data-aos=row-of-5].aos-animate:nth-child(5n + 5) .collection-image {
  animation-delay: 300ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 2) .collection-image {
  animation-delay: 50ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 3) .collection-image {
  animation-delay: 100ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 4) .collection-image {
  animation-delay: 150ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 5) .collection-image {
  animation-delay: 200ms
}

[data-aos=row-of-6].aos-animate:nth-child(6n + 6) .collection-image {
  animation-delay: 250ms
}

.loading:after,
.loading:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 5px;
  background: #ffffff
  ;
  background: var(--colorBody);
  left: 50%;
  top: 50%;
  margin: -3px 0 0 -50px;
  z-index: 4;
  opacity: 0
}

.no-js .loading:after,
.no-js .loading:before {
  display: none
}

.loading:before {
  background: #000000
  ;
  background: var(--colorTextBody);
  animation: fade-in 0.5s 0s forwards;
}

.loading:after {
  animation: preloading 0.5s ease 0.3s infinite;
}

.loading--delayed:before {
  animation-delay: 0.8s !important;
  animation-duration: 1s !important;
}

.loading--delayed:after {
  animation-delay: 1.3s !important;
}

.appear-animation {
  opacity: 0;
  transform: translateY(60px)
}

[data-disable-animations=true] .appear-animation {
  opacity: 1;
  transform: none
}

.js-drawer-open .appear-animation {
  opacity: 1;
  transform: translateY(0px);
}

.js-drawer-closing .appear-animation {
  transition-duration: 0s;
  transition-delay: 0.5s;
}

.spr-badge-starrating,
.spr-icon-star-empty,
.spr-icon-star-hover,
.spr-icon-star-hover:hover,
.spr-starrating,
.spr-starratings {
  color: #f3c200;
}

.product-single__meta .spr-icon {
  font-size: 14px !important;
  vertical-align: text-bottom;
}

.spr-header-title {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight);
}

[data-type_header_capitalize=true] .spr-header-title {
  text-transform: uppercase;
}

.spr-header-title {
  font-size: calc(var(--typeHeaderSize) * 0.7) !important;
  margin-bottom: 20px !important;
}

.spr-container.spr-container {
  padding: 0;
  border: 0;
  text-align: center;
}

.product-reviews #shopify-product-reviews {
  margin: 0;
}

.product-reviews .spr-summary-actions-newreview {
  float: none;
}

.product-reviews .spr-form-label,
.product-reviews .spr-review-content-body {
  font-size: calc(var(--typeBaseSize) - 2px);
  line-height: 1.563;
}

.product-reviews .spr-review-header-byline {
  font-size: 11px;
  opacity: 1
}

.product-reviews .spr-review-header-byline strong {
  font-weight: 400;
}

.product-reviews .spr-review {
  border: none !important;
}

.product-reviews .spr-form-label {
  display: block;
  text-align: left;
  margin-top: 20px;
}

.product-reviews .spr-summary-actions,
.product-reviews .spr-summary-caption {
  display: block;
}

.product-reviews .spr-summary-actions {
  margin-top: 20px;
}

@media only screen and (min-width:769px) {
  .product-reviews--full.index-section {
    margin-top: 0;
  }

  .product-reviews--full .spr-form-title {
    display: none;
  }

  .product-reviews--full .spr-form {
    max-width: 650px;
    margin: 0 auto;
    border-top: none;
  }

  .product-reviews--full .spr-reviews {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap
  }

  [data-type_headers_align_text=true] .product-reviews--full .spr-reviews {
    justify-content: center
  }

  .product-reviews--full .spr-review:first-child {
    margin-top: 0;
  }

  .product-reviews--full .spr-review {
    flex: 1 1 40%;
    padding: 20px;
    margin-left: 22px;
    margin-bottom: 22px
  }

  [data-type_headers_align_text=true] .product-reviews--full .spr-review {
    max-width: 30%
  }

  .product-reviews--full .spr-review:nth-child(3n + 1) {
    margin-left: 0;
  }

  .product-reviews--full .spr-review:last-child {
    padding-bottom: 20px;
  }
}

.grid-product .spr-badge[data-rating="0.0"] {
  display: none;
}

.grid-product .spr-badge-starrating {
  font-size: 0.65em;
}

.grid-product .spr-icon {
  margin-right: 1px;
}

.grid-product .spr-badge-caption {
  font-size: 11px;
  vertical-align: bottom;
  margin-left: 4px
}

@media only screen and (min-width:769px) {

  .grid-product .spr-badge-caption {
    font-size: 12px
  }
}

.product-reviews--tab .collapsible-trigger .spr-badge-caption {
  margin-left: 0;
}

.product-reviews--tab .collapsible-trigger .spr-badge-starrating {
  font-size: 13px;
  margin-right: 10px
}

@media only screen and (min-width:769px) {

  .product-reviews--tab .collapsible-trigger .spr-badge-starrating {
    font-size: 14px
  }
}

.product-reviews--tab .collapsible-trigger .spr-badge[data-rating="0.0"] .spr-starrating {
  display: none;
}

.product-reviews--tab .spr-icon {
  margin-right: 1px;
}

.product-reviews--tab .spr-badge-caption {
  margin-left: 4px;
}

.product-reviews--tab .spr-header-title,
.product-reviews--tab .spr-summary-caption,
.product-reviews--tab .spr-summary-starrating {
  display: none !important;
}

.product-reviews--tab .spr-button,
.product-reviews--tab .spr-summary-actions a {
  margin-top: 0 !important;
}

.product-reviews--tab .spr-button-primary {
  float: none;
}

@media only screen and (max-width:480px) {
  .product-reviews--tab .spr-summary {
    text-align: left;
  }
}

.product-reviews--tab .spr-form-title {
  display: none;
}

.product-reviews--tab .spr-form-label {
  font-size: 13px !important;
}

.product-reviews--tab .spr-review-header .spr-starratings {
  font-size: 14px;
}

.spr-content .spr-review-header-title {
  font-family: var(--typeBasePrimary), var(--typeBaseFallback);
  font-size: calc(var(--typeBaseSize) * 0.85);
  letter-spacing: var(--typeBaseSpacing);
  line-height: var(--typeBaseLineHeight);
}

@media only screen and (min-width:769px) {

  .spr-content .spr-review-header-title {
    font-size: var(--typeBaseSize);
  }
}

.spr-content .spr-review-header-title {
  font-weight: 700;
  text-transform: none;
}

.spr-pagination {
  flex: 1 1 100%;
}

.spr-review-reportreview {
  opacity: 0.4;
  float: none !important;
  display: block;
}

.spr-summary-starrating {
  font-size: 0.8em;
}

[data-center-text=true] .footer-promotions {
  text-align: center
}

@media only screen and (max-width:768px) {
  .footer-promotions .grid__item {
    margin-bottom: 32px
  }

  .footer-promotions .grid__item:last-child {
    margin-bottom: 0;
  }
}

.site-footer {
  padding-bottom: 30px;
  background-color: #ffffff
  ;
  background-color: var(--colorFooter);
  color: #000000
  ;
  color: var(--colorFooterText)
}

@media only screen and (min-width:769px) {

  .site-footer {
    padding-top: 60px;
    padding-bottom: 60px
  }
}

.site-footer .footer__collapsible {
  font-size: calc(var(--typeBaseSize) * 0.85);
}

@media only screen and (min-width:769px) {
  .site-footer input,
  .site-footer select,
  .site-footer textarea {
    font-size: calc(var(--typeBaseSize) * 0.85);
  }
}

@media only screen and (max-width:768px) {

  [data-center-text=true] .site-footer {
    text-align: center
  }

  .site-footer {
    overflow: hidden;
    padding-bottom: 0
  }

  .site-footer .grid__item {
    padding-bottom: 5px
  }

  .site-footer .grid__item:after {
    content: "";
    border-bottom: 1px solid;
    border-bottom-color: #000000
    ;
    border-bottom-color: var(--colorFooterText);
    opacity: 0.12;
    display: block;
  }

  .site-footer .grid__item:first-child {
    padding-top: 7.5px;
  }

  .site-footer .grid__item:last-child:after {
    display: none;
  }
}

.site-footer a {
  color: #000000
  ;
  color: var(--colorFooterText);
}

.footer__small-text {
  font-size: max(calc(var(--typeBaseSize) * 0.7), 12px);
  padding: 7.5px 0;
  margin: 0;
  text-align: center
}

ul + .footer__small-text {
  padding-top: 15px
}

.footer__clear {
  clear: both;
  height: 30px;
}

.footer__section {
  margin-bottom: 15px
}

@media only screen and (min-width:769px) {

  .footer__section {
    margin-top: 30px;
    margin-bottom: 0
  }
}

@media only screen and (min-width:769px) {
  .footer__item-padding {
    padding-right: 60px;
  }
}

.footer__title {
  color: #000000
  ;
  color: var(--colorFooterText)
}

@media only screen and (min-width:769px) {

  .footer__title {
    margin-bottom: 20px
  }
}

@media only screen and (max-width:768px) {

  [data-center-text=true] .footer__title {
    text-align: center
  }
}

.site-footer__linklist {
  margin: 0
}

.site-footer__linklist a {
  display: inline-block;
  padding: 4px 0;
}

.footer__newsletter {
  position: relative;
  display: inline-block;
  max-width: 300px;
  width: 100%;
}

.footer__newsletter-input {
  padding: 10px 45px 10px 0;
  background-color: #ffffff
  ;
  background-color: var(--colorFooter);
  color: #000000
  ;
  color: var(--colorFooterText);
  max-width: 300px;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid;
  border-bottom-color: #000000
  ;
  border-bottom-color: var(--colorFooterText)
}

.footer__newsletter-input:focus {
  border: 0;
  border-bottom: 2px solid;
  border-bottom-color: #000000
  ;
  border-bottom-color: var(--colorFooterText);
}

.footer__newsletter-input::-webkit-input-placeholder {
  color: #000000
  ;
  color: var(--colorFooterText);
  opacity: 1;
}

.footer__newsletter-input:-moz-placeholder {
  color: #000000
  ;
  color: var(--colorFooterText);
  opacity: 1;
}

.footer__newsletter-input::-moz-placeholder {
  color: #000000
  ;
  color: var(--colorFooterText);
  opacity: 1;
}

.footer__newsletter-input:-ms-input-placeholder {
  color: #000000
  ;
  color: var(--colorFooterText);
  opacity: 1;
}

.footer__newsletter-input::-ms-input-placeholder {
  color: #000000
  ;
  color: var(--colorFooterText);
  opacity: 1;
}

.footer__newsletter-input--active {
  padding-right: 80px;
}

.footer__newsletter-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #000000
  ;
  color: var(--colorFooterText);
  padding: 0
}

.footer__newsletter-btn .icon {
  width: 26px;
  height: 24px;
}

.footer__newsletter-input--active + .footer__newsletter-btn .icon {
  display: none;
}

.footer__newsletter-input--active + .footer__newsletter-btn .footer__newsletter-btn-label {
  display: block;
}

.footer__newsletter-btn-label {
  display: none;
  font-size: calc(var(--typeBaseSize) * 0.85);
}

.footer__logo {
  margin: 15px 0
}

@media only screen and (min-width:769px) {

  .footer__logo {
    margin: 0 0 20px
  }
}

.footer__logo a {
  display: block;
}

.footer__logo img {
  display: inline-block;
  transform: translateZ(0);
  max-height: 100%;
}

.footer__social {
  margin: 0
}

form + .footer__social {
  margin-top: 30px
}

.footer__social li {
  display: inline-block;
  margin: 0 15px 15px 0;
}

.footer__social a {
  display: block;
}

.footer__social .icon {
  width: 22px;
  height: 22px
}

@media only screen and (min-width:769px) {

  .footer__social .icon {
    width: 24px;
    height: 24px
  }
}

.footer__social .icon.icon--wide {
  width: 40px;
}

@media only screen and (max-width:768px) {

  .footer__collapsible {
    padding: 0 0 15px
  }
}

@media only screen and (max-width:768px) {

  .footer_collapsible--disabled {
    padding-top: 15px
  }
}

.collapsible-content__inner p a:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  border-bottom: 2px solid;
  border-bottom-color: #000000
  ;
  border-bottom-color: var(--colorFooterText);
  transition: width 0.5s ease;
}

.collapsible-content__inner p a {
  position: relative;
  text-decoration: none;
  border-bottom: 2px solid;
  border-color: rgba(0, 0, 0, 0.1)
}

.collapsible-content__inner p a:focus:after,
.collapsible-content__inner p a:hover:after {
  width: 100%;
}

.site-footer .faux-select,
.site-footer select {
  background-image: url('//paracable.com/cdn/shop/t/11/assets/ico-select-footer.svg');
}

[data-center-text=true] .payment-icons {
  text-align: center
}

.payment-icons {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default
}

.payment-icons li {
  cursor: default;
  margin: 0 4px;
}

.errors,
.note {
  border-radius: 0;
  padding: 6px 12px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  text-align: left
}

.errors ol,
.errors ul,
.note ol,
.note ul {
  margin-top: 0;
  margin-bottom: 0;
}

.errors li:last-child,
.note li:last-child {
  margin-bottom: 0;
}

.errors p,
.note p {
  margin-bottom: 0;
}

.note {
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
}

.errors ul {
  list-style: disc outside;
  margin-left: 20px;
}

.note--success {
  color: #56ad6a;
  background-color: #ecfef0;
  border-color: #56ad6a
}

.note--success a {
  color: #56ad6a;
  text-decoration: underline
}

.note--success a:hover {
  text-decoration: none;
}

.errors,
.form-error {
  color: #d02e2e;
  background-color: #fff6f6;
  border-color: #d02e2e
}

.errors a,
.form-error a {
  color: #d02e2e;
  text-decoration: underline
}

.errors a:hover,
.form-error a:hover {
  text-decoration: none;
}

.pagination {
  margin: 0;
  padding: 60px 0;
  text-align: center
}

.pagination > span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.pagination a {
  display: inline-block;
}

.pagination .page.current,
.pagination a {
  padding: 8px 12px;
}

.pagination .page.current {
  opacity: 0.3;
}

.pagination .next,
.pagination .prev {
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  background: #111111
  ;
  background: var(--colorBtnPrimary);
  width: 43px;
  margin: 0 10px
}

@media only screen and (max-width:768px) {

  .pagination .next,
  .pagination .prev {
    width: 35px
  }
}

.pagination .next a,
.pagination .prev a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.pagination .next .icon,
.pagination .prev .icon {
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  width: 13px;
  height: 14px
}

@media only screen and (max-width:768px) {

  .pagination .next .icon,
  .pagination .prev .icon {
    width: 12px;
    height: 12px
  }
}

.rte:after {
  content: "";
  display: table;
  clear: both;
}

.rte {
  margin-bottom: 7.5px
}

@media only screen and (min-width:769px) {

  .rte {
    margin-bottom: 15px
  }
}

.rte:last-child {
  margin-bottom: 0;
}

.rte + .rte {
  margin-top: 30px;
}

.rte ol,
.rte p,
.rte table,
.rte ul {
  margin-bottom: 15px
}

@media only screen and (min-width:769px) {

  .rte ol,
  .rte p,
  .rte table,
  .rte ul {
    margin-bottom: 25px
  }
}

.rte ol:last-child,
.rte p:last-child,
.rte table:last-child,
.rte ul:last-child {
  margin-bottom: 0;
}

.rte ul ul {
  margin-bottom: 0;
}

.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5,
.rte h6 {
  margin-top: 60px;
  margin-bottom: 25px;
}

.rte h1:first-child,
.rte h2:first-child,
.rte h3:first-child,
.rte h4:first-child,
.rte h5:first-child,
.rte h6:first-child {
  margin-top: 0;
}

.rte h1 a,
.rte h2 a,
.rte h3 a,
.rte h4 a,
.rte h5 a,
.rte h6 a {
  text-decoration: none;
}

.rte meta:first-child + h1,
.rte meta:first-child + h2,
.rte meta:first-child + h3,
.rte meta:first-child + h4,
.rte meta:first-child + h5,
.rte meta:first-child + h6 {
  margin-top: 0;
}

.rte > div {
  margin-bottom: 15px;
}

.rte li {
  margin-bottom: 0;
}

.rte > p:last-child {
  margin-bottom: 0;
}

.rte table {
  table-layout: fixed;
}

.rte--block {
  margin-bottom: 8px
}

@media only screen and (min-width:769px) {

  .rte--block {
    margin-bottom: 12px
  }
}

.rte-setting > p:last-child {
  margin-bottom: 0;
}

.rte-setting a,
.rte a {
  text-decoration: none;
}

.rte-setting img,
.rte img {
  height: auto;
}

.rte-setting a:not(.rte__image),
.rte a:not(.rte__image):not(.stamped-rewards-widget-btn) {
  text-decoration: none;
  border-bottom: 1px solid;
  border-bottom-color: rgba(0, 0, 0, 0.15)
  ;
  position: relative;
  display: inline-block;
}

.rte-setting a:not(.btn):after,
.rte a:not(.btn):after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  border-bottom: 2px solid currentColor;
  transition: width 0.5s ease;
}

.rte-setting a:not(.btn):focus:after,
.rte-setting a:not(.btn):hover:after,
.rte a:not(.btn):focus:after,
.rte a:not(.btn):hover:after {
  width: 100%;
}

.rte-setting a.rte__image:after,
.rte a.rte__image:after {
  content: none;
}

.text-center.rte ol,
.text-center .rte ol,
.text-center.rte ul,
.text-center .rte ul {
  list-style-position: inside;
  margin-left: 0;
}

.rte--nomargin {
  margin-bottom: 0;
}

.header-layout {
  display: flex;
  justify-content: space-between;
}

.header-layout--center {
  align-items: center;
}

.header-item {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.header-item--logo {
  flex: 0 0 auto;
}

.header-item--icons {
  justify-content: flex-end;
  flex: 0 1 auto;
}

.header-layout--left-center .header-item--icons,
.header-layout--left-center .header-item--logo {
  flex: 0 0 200px;
  max-width: 50%
}

@media only screen and (min-width:769px) {

  .header-layout--left-center .header-item--icons,
  .header-layout--left-center .header-item--logo {
    min-width: 130px
  }
}

@media only screen and (min-width:769px) {

  .header-layout[data-logo-align=center] .header-item--logo {
    margin: 0 30px
  }
}

.header-layout[data-logo-align=center] .header-item--icons,
.header-layout[data-logo-align=center] .header-item--navigation {
  flex: 1 1 130px;
}

.header-layout[data-logo-align=left] .site-header__logo {
  margin-right: 10px;
}

.header-item--logo-split {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%
}

.header-item--logo-split .header-item:not(.header-item--logo) {
  text-align: center;
  flex: 1 1 20%;
}

.header-item--split-left {
  justify-content: flex-end;
}

.header-item--left .site-nav {
  margin-left: -12px
}

@media only screen and (max-width:768px) {

  .header-item--left .site-nav {
    margin-left: -7.5px
  }
}

.header-item--icons .site-nav {
  margin-right: -12px
}

@media only screen and (max-width:768px) {

  .header-item--icons .site-nav {
    margin-right: -7.5px
  }
}

.site-header {
  position: relative;
  padding: 7px 0;
  background: #ffffff
  ;
  background: var(--colorNav)
}

@media only screen and (min-width:769px) {

  .site-header {
    padding: 20px 0
  }

  .toolbar + .header-sticky-wrapper .site-header {
    border-top: 1px solid;
    border-top-color: rgba(0, 0, 0, 0.1)
  }
}

.site-header--stuck {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transform: translate3d(0, -100%, 0);
  transition: none;
  z-index: 20
}

.js-drawer-open--search .site-header--stuck {
  z-index: 28
}

@media only screen and (min-width:769px) {

  .site-header--stuck {
    padding: 10px 0
  }
}

@media screen and (min-width:700px) and(max-height:550px) {
  .site-header--stuck {
    position: static;
  }
}

.site-header--opening {
  transform: translateZ(0);
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.site-header__logo {
  position: relative;
  margin: 10px 0;
  display: block;
  font-size: 30px;
  z-index: 6
}

@media only screen and (min-width:769px) {

  .text-center .site-header__logo {
    padding-right: 0;
    margin: 10px auto
  }
}

.header-layout[data-logo-align=center] .site-header__logo {
  margin-left: auto;
  margin-right: auto;
  text-align: center
}

.site-header__logo a {
  max-width: 100%;
}

.site-header__logo a,
.site-header__logo a:hover {
  text-decoration: none;
}

.site-header__logo img {
  display: block
}

.header-layout[data-logo-align=center] .site-header__logo img {
  margin-left: auto;
  margin-right: auto
}

.site-header__logo-link {
  display: flex;
  align-items: center;
  color: #000000
  ;
  color: var(--colorNavText)
}

.site-header__logo-link:hover {
  color: #000000
  ;
  color: var(--colorNavText);
}

@media only screen and (max-width:768px) {

  .site-header__logo-link {
    margin: 0 auto
  }
}

.header-sticky-wrapper {
  position: relative;
}

.header-wrapper--sticky {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  background: none;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%,transparent)
}

.header-wrapper--sticky .site-header:not(.site-header--stuck) {
  background: none;
}

.js-drawer-open--search .header-wrapper--sticky {
  z-index: 28
}

.site-header__search-container {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 200%;
  height: 100%;
  z-index: 28;
  overflow: hidden
}

.site-header__search-container.is-active {
  display: block;
  overflow: visible;
  bottom: 0;
}

.site-header__search {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 28;
  display: flex;
  transform: translate3d(0, -110%, 0);
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody)
}

.site-header__search .page-width {
  flex: 1 1 100%;
  display: flex;
  align-items: stretch
}

@media only screen and (max-width:768px) {

  .site-header__search .page-width {
    padding: 0
  }
}

.is-active .site-header__search {
  transform: translateZ(0)
}

.site-header__search .icon {
  width: 30px;
  height: 30px;
}

.site-header__search-form {
  flex: 1 1 auto;
  display: flex
}

@media only screen and (min-width:769px) {

  .site-header__search-form {
    padding: 15px 0
  }
}

.site-header__search-input {
  border: 0;
  width: 100px;
  flex: 1 1 auto
}

.site-header__search-input:focus {
  border: 0;
  outline: 0;
}

.site-header__search-btn {
  padding: 0 15px;
}

@media only screen and (min-width:769px) {
  .site-header__search-btn--submit {
    padding: 0 15px 0 0
  }

  .site-header__search-btn--submit .icon {
    position: relative;
    top: -1px;
    width: 28px;
    height: 28px;
  }
}

.predictive-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
  max-height: 70vh;
  max-height: calc(90vh - 100%);
  overflow: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09)
}

@media only screen and (min-width:769px) {

  .predictive-results {
    max-height: calc(100vh - 100% - 33px)
  }
}

.predictive__label {
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorBorder);
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.predictive-result__layout {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px
}

.predictive-result__layout > div {
  margin: 0 10px 30px
}

.predictive-result__layout > div:last-child {
  margin-bottom: 0;
}

.predictive-result__layout [data-type-products] {
  flex: 1 1 60%;
  margin-bottom: 0;
}

.predictive-result__layout [data-type-collections],
.predictive-result__layout [data-type-pages] {
  flex: 1 1 200px;
}

.predictive-result__layout [data-type-articles] {
  flex: 1 1 60%;
}

.predictive__image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.predictive__image-wrap img {
  opacity: 0
}

.predictive__image-wrap img.lazyloaded {
  opacity: 1;
}

.predictive-results__footer {
  padding: 0 0 30px;
}

.search-bar {
  max-width: 100%;
}

.search-bar--page {
  max-width: 300px;
  margin-top: -15px
}

[data-type_headers_align_text=true] .search-bar--page {
  margin: -15px auto 0
}

.search-bar--drawer {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorDrawerBorder)
}

.search-bar--drawer input {
  border: 0;
}

.search-bar .icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.toolbar {
  background: #ffffff
  ;
  background: var(--colorNav);
  color: #000000
  ;
  color: var(--colorNavText);
  font-size: calc(var(--typeBaseSize) * 0.85)
}

.toolbar a {
  color: #000000
  ;
  color: var(--colorNavText);
}

.site-header--stuck .toolbar {
  display: none
}

.toolbar--transparent {
  background-color: transparent;
  color: #fff;
  border-bottom: none
}

.toolbar--transparent a {
  color: #fff;
}

.toolbar--transparent .toolbar__content {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}

.toolbar__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toolbar__item {
  flex: 0 1 auto;
  padding: 0 5px
}

.toolbar__item:first-child {
  padding-left: 0;
}

.toolbar__item:last-child {
  padding-right: 0;
}

.toolbar__item .faux-select,
.toolbar__item select {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.toolbar__item--menu {
  flex: 1 1 auto;
}

.toolbar__menu {
  margin-left: -10px
}

.toolbar__menu a {
  display: block;
  padding: 5px 10px;
}

.toolbar__social {
  text-align: right
}

.toolbar__social a {
  display: block;
  padding: 5px;
}

.toolbar__social .icon {
  position: relative;
  top: -2px;
  width: 16px;
  height: 16px;
}

.section-header {
  margin-bottom: 30px
}

@media only screen and (min-width:769px) {

  .section-header {
    margin-bottom: 50px
  }
}

[data-type_headers_align_text=true] .section-header {
  text-align: center
}

.section-header select {
  display: inline-block;
  vertical-align: middle;
}

.section-header--flush {
  margin-bottom: 0;
}

.section-header--with-link {
  display: flex;
  align-items: center
}

.section-header--with-link select {
  flex: 0 1 auto;
}

.section-header--hero {
  position: relative;
  flex: 1 1 100%;
  color: #ffffff
  ;
  color: var(--colorHeroText);
  margin-bottom: 0
}

.section-header--hero a {
  color: #ffffff
  ;
  color: var(--colorHeroText);
}

.section-header__shadow {
  position: relative;
  display: inline-block
}

.section-header__shadow:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;
  background: radial-gradient(rgba(0,0,0,0.26) 0%, transparent 60%);
  background: radial-gradient(rgba(0,0,0,var(--colorImageOverlayTextShadow)) 0%, transparent 60%);
  margin: -100px -200px;
  z-index: -1;
}

.section-header__shadow .breadcrumb,
.section-header__shadow .section-header__title {
  position: relative;
}

.section-header__title {
  margin-bottom: 0
}

.section-header--with-link .section-header__title {
  flex: 1 1 auto
}

@media only screen and (min-width:769px) {

  .section-header--hero .section-header__title {
    font-size: calc(var(--typeHeaderSize) * 1.45)
  }
}

.section-header__link {
  flex: 0 1 auto;
  margin-top: 15px
}

.section-header--with-link .section-header__link {
  margin-top: 0
}

.section-header--404 {
  margin-bottom: 0;
  padding: 80px 0;
}

.section-header select {
  margin: 10px 0;
}

.section-header p {
  margin: 10px 0;
}

.site-nav {
  margin: 0;
}

.text-center .site-navigation {
  margin: 0 auto
}

.header-layout--left .site-navigation {
  padding-left: 10px
}

.site-nav__icons {
  white-space: nowrap;
}

.site-nav__item {
  position: relative;
  display: inline-block;
  margin: 0
}

.site-nav__item li {
  display: block;
}

.site-nav__item .icon-chevron-down {
  width: 10px;
  height: 10px;
}

.site-nav__link {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 7.5px 15px;
  white-space: nowrap;
  color: #000000
  ;
  color: var(--colorNavText)
}

.site-header--heading-style .site-nav__link {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight)
}

[data-type_header_capitalize=true] .site-header--heading-style .site-nav__link {
  text-transform: uppercase
}

.site-nav__link:hover {
  color: #000000
  ;
  color: var(--colorNavText);
}

.is-light .site-nav__link {
  color: #fff
}

.is-light .site-nav__link:hover {
  color: #fff;
}

.site-nav--has-dropdown > .site-nav__link {
  position: relative;
  z-index: 6
}

.site-nav__link .icon-chevron-down {
  margin-left: 5px;
}

@media only screen and (max-width:959px) {

  .site-nav__link {
    padding: 7.5px
  }

  .header-layout--center .site-nav__link {
    padding-left: 2px;
    padding-right: 2px
  }
}

.site-nav__link--underline {
  position: relative
}

.site-nav__link--underline:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
  margin: 0 15px;
  border-bottom: 2px solid;
  border-bottom-color: #000000
  ;
  border-bottom-color: var(--colorNavText);
  transition: right 0.5s
}

[data-disable-animations=true] .site-nav__link--underline:after {
  transition: none
}

.is-light .site-nav__item:not(.site-nav--has-dropdown) .site-nav__link--underline:after {
  border-bottom-color: #fff
}

.site-nav--has-dropdown .site-nav__link--underline:after {
  border-bottom-color: #000000
  ;
  border-bottom-color: var(--colorTextBody)
}

.site-nav__item:hover .site-nav__link--underline:after {
  right: 0
}

.site-nav--has-dropdown {
  z-index: 6
}

.site-nav--has-dropdown.is-focused,
.site-nav--has-dropdown:hover {
  z-index: 7;
}

.site-nav--has-dropdown.is-focused > a,
.site-nav--has-dropdown:hover > a {
  color: #000000
   !important;
  color: var(--colorTextBody) !important;
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  opacity: 1;
  transition: none;
}

.site-nav__link--icon {
  padding-left: 12px;
  padding-right: 12px
}

@media only screen and (max-width:768px) {

  .site-nav__link--icon {
    padding-left: 7.5px;
    padding-right: 7.5px
  }

  .site-nav__link--icon + .site-nav__link--icon {
    margin-left: -4px;
  }
}

.site-nav__link--icon .icon {
  width: 30px;
  height: 30px;
}

.site-nav__dropdown {
  position: absolute;
  left: 0;
  margin: 0;
  z-index: 5;
  display: block;
  visibility: hidden;
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  min-width: 100%;
  padding: 10px 0 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
  transform: translate3d(0px, -12px, 0px)
}

.is-focused > .site-nav__dropdown,
.site-nav--has-dropdown:hover .site-nav__dropdown {
  display: block;
  visibility: visible;
  transform: translateZ(0px);
  transition: all 300ms cubic-bezier(0.2, 0.06, 0.05, 0.95)
}

.site-nav__dropdown li {
  margin: 0;
}

.site-nav__dropdown > li {
  position: relative
}

.site-nav__dropdown > li > a {
  position: relative;
  z-index: 6;
}

.site-nav__dropdown a {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
}

.site-nav__deep-dropdown {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 0;
  left: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 5;
  transform: translate3d(-12px, 0px, 0px)
}

.header-item--reverse .site-nav__item:last-child .site-nav__deep-dropdown {
  left: auto;
  right: 100%
}

.is-focused + .site-nav__deep-dropdown,
.site-nav__deep-dropdown-trigger:hover .site-nav__deep-dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateZ(0px);
  transition: all 300ms cubic-bezier(0.2, 0.06, 0.05, 0.95)
}

.site-nav__deep-dropdown:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.09), transparent);
  pointer-events: none
}

.header-item--reverse .site-nav__item:last-child .site-nav__deep-dropdown:before {
  left: auto;
  right: 0;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.09), transparent)
}

.site-nav__deep-dropdown-trigger:hover .site-nav__dropdown-link--has-children {
  background-color: #f2f2f2
  ;
  background-color: var(--colorBodyDim)
}

.site-nav__dropdown-link--has-children:focus,
.site-nav__dropdown-link--has-children:hover {
  background-color: #f2f2f2
  ;
  background-color: var(--colorBodyDim);
}

.site-nav__deep-dropdown-trigger .icon-chevron-down {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 10px;
  height: 10px;
  transform: rotate(-90deg) translateX(50%);
}

.mobile-nav {
  margin: -15px -15px 0
}

@media only screen and (min-width:769px) {

  .mobile-nav {
    margin-left: -30px;
    margin-right: -30px
  }
}

.mobile-nav li {
  margin-bottom: 0;
  list-style: none;
}

.mobile-nav__search {
  padding: 15px;
}

.mobile-nav__item {
  position: relative;
  display: block
}

.mobile-nav > .mobile-nav__item {
  background-color: #ffffff
  ;
  background-color: var(--colorDrawers)
}

.mobile-nav__item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorDrawerBorder);
}

.mobile-nav__faux-link,
.mobile-nav__link {
  display: block;
}

.mobile-nav__link--top-level {
  font-size: 1.4em
}

.mobile-nav--heading-style .mobile-nav__link--top-level {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight)
}

[data-type_header_capitalize=true] .mobile-nav--heading-style .mobile-nav__link--top-level {
  text-transform: uppercase
}

.mobile-nav__faux-link,
.mobile-nav__link,
.mobile-nav__toggle .faux-button,
.mobile-nav__toggle button {
  color: #000000
  ;
  color: var(--colorDrawerText);
  padding: 15px;
  text-decoration: none
}

.mobile-nav__faux-link:active,
.mobile-nav__link:active,
.mobile-nav__toggle .faux-button:active,
.mobile-nav__toggle button:active {
  color: #000000
  ;
  color: var(--colorDrawerTextDark);
}

.mobile-nav__faux-link:active,
.mobile-nav__link:active,
.mobile-nav__toggle .faux-button:active,
.mobile-nav__toggle button:active {
  background-color: #f2f2f2
  ;
  background-color: var(--colorDrawersDim);
}

.mobile-nav__child-item {
  display: flex
}

.mobile-nav__child-item .mobile-nav__link,
.mobile-nav__child-item a {
  flex: 1 1 auto;
}

.mobile-nav__child-item .collapsible-trigger:not(.mobile-nav__link--button) {
  flex: 0 0 43px;
}

.mobile-nav__child-item .collapsible-trigger__icon {
  padding: 0;
  margin-right: 15px;
}

.mobile-nav__item--secondary a {
  padding-top: 10px;
  padding-bottom: 5px;
}

.mobile-nav__item--secondary:after {
  display: none;
}

.mobile-nav__item:not(.mobile-nav__item--secondary) + .mobile-nav__item--secondary {
  margin-top: 10px;
}

.mobile-nav__has-sublist,
.mobile-nav__link--button {
  display: flex
}

.mobile-nav__has-sublist > *,
.mobile-nav__link--button > * {
  flex: 1 1 auto;
  word-break: break-word;
}

.mobile-nav__link--button {
  width: 100%;
  text-align: left;
  padding: 0;
}

.mobile-nav__toggle {
  flex: 0 1 auto
}

.mobile-nav__toggle .icon {
  width: 16px;
  height: 16px;
}

.mobile-nav__toggle .faux-button,
.mobile-nav__toggle button {
  height: 60%;
  padding: 0 30px;
  margin: 20% 0;
}

.mobile-nav__toggle button {
  border-left: 1px solid;
  border-left-color: #e8e8e1
  ;
  border-left-color: var(--colorDrawerBorder);
}

.mobile-nav__sublist {
  margin: 0
}

.mobile-nav__sublist .mobile-nav__item:after {
  top: 0;
  bottom: auto;
  border-bottom: none;
}

.mobile-nav__sublist .mobile-nav__item:last-child {
  padding-bottom: 15px;
}

.mobile-nav__sublist .mobile-nav__faux-link,
.mobile-nav__sublist .mobile-nav__link {
  font-weight: 400;
  padding: 7.5px 25px 7.5px 15px;
}

.mobile-nav__grandchildlist {
  margin: 0
}

.mobile-nav__grandchildlist:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  background: #000;
  left: 17px;
  top: 10px;
  bottom: 10px;
}

.mobile-nav__grandchildlist .mobile-nav__item:last-child {
  padding-bottom: 0;
}

.mobile-nav__grandchildlist .mobile-nav__link {
  padding-left: 35px;
}

.mobile-nav__social {
  list-style: none outside;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  margin: 15px 0 20px
}

@media only screen and (min-width:769px) {

  .mobile-nav__social {
    margin-left: -15px;
    margin-right: -15px
  }
}

.mobile-nav__social a {
  display: block;
  padding: 8px 30px
}

.mobile-nav__social a .icon {
  position: relative;
  top: -1px;
}

.mobile-nav__social-item {
  flex: 0 1 33.33%;
  text-align: center;
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorDrawerBorder);
  margin: 0 0 -1px
}

.mobile-nav__social-item:nth-child(3n-1) {
  margin-right: -1px;
  margin-left: -1px;
}

@media only screen and (min-width:769px) {
  .site-nav__link--icon .icon {
    width: 28px;
    height: 28px
  }

  .site-nav__link--icon .icon.icon-user {
    position: relative;
    top: 1px;
  }
}

.cart-link {
  position: relative;
  display: block;
}

.cart-link__bubble {
  display: none;
}

.cart-link__bubble--visible {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 15px;
  height: 15px;
  background-color: #e4320c
  ;
  background-color: var(--colorCartDot);
  border: 2px solid;
  border-color: #ffffff
  ;
  border-color: var(--colorBody);
  border-radius: 50%
}

[data-icon=cart] .cart-link__bubble--visible {
  top: 0;
  right: -4px
}

[data-type_headers_align_text=true] .breadcrumb {
  text-align: center
}

.breadcrumb {
  font-size: calc(var(--typeBaseSize) * 0.85);
  margin: -25px 0 10px
}

.template-product .breadcrumb {
  margin-top: 10px
}

@media only screen and (min-width:769px) {

  .template-product .breadcrumb {
    text-align: left
  }
}

@media only screen and (max-width:768px) {

  .breadcrumb {
    margin-bottom: 15px
  }
}

.breadcrumb__divider {
  color: currentColor;
}

.megamenu {
  padding: 39px 0;
  line-height: 1.8;
  transform: none;
  opacity: 0;
  transition: all 300ms cubic-bezier(0.2, 0.06, 0.05, 0.95);
  transition-delay: 0.3s
}

.is-focused > .megamenu,
.site-nav--has-dropdown:hover .megamenu {
  opacity: 1;
  transition-delay: 0s
}

.is-focused > .megamenu .appear-animation,
.site-nav--has-dropdown:hover .megamenu .appear-animation {
  opacity: 1;
  transform: none;
}

.site-nav--is-megamenu.site-nav__item {
  position: static;
}

.megamenu__colection-image {
  display: block;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 0;
  padding-bottom: 46%;
  margin-bottom: 20px;
}

.text-center .megamenu .grid {
  text-align: center
}

.text-center .megamenu .grid .grid__item {
  float: none;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.modal {
  display: none;
  bottom: 0;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 25;
  color: #fff;
  align-items: center;
  justify-content: center
}

.modal a,
.modal a:hover {
  color: inherit;
}

.modal .btn:not([disabled]):not(.btn--secondary),
.modal .btn:not([disabled]):not(.btn--secondary):hover {
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
}

.modal-open .modal .modal__inner {
  animation: modal-open 0.5s forwards;
}

.modal-open .modal:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e6e6e6
  ;
  background-color: var(--colorModalBg);
  animation: overlay-on 0.5s forwards;
  cursor: pointer;
}

.modal-closing .modal .modal__inner {
  animation: modal-closing 0.5s forwards;
}

.modal-closing .modal:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e6e6e6
  ;
  background-color: var(--colorModalBg);
  animation: overlay-off 0.5s forwards;
}

.modal-open--solid .modal:before {
  background-color: #e6e6e6
  ;
  background-color: var(--colorModalBg)
}

.modal-open .modal--solid:before {
  background-color: #000;
  animation: full-overlay-on 0.5s forwards;
}

.modal-closing .modal--solid:before {
  background-color: #000;
  animation: full-overlay-off 0.5s forwards;
}

.modal--is-closing {
  display: flex !important;
  overflow: hidden;
}

.modal--is-active {
  display: flex !important;
  overflow: hidden;
}

@media only screen and (min-width:769px) {
  .modal-open {
    overflow: hidden;
  }
}

.modal__inner {
  transform-style: preserve-3d;
  flex: 0 1 auto;
  margin: 15px;
  max-width: 100%;
  display: flex;
  align-items: center
}

@media only screen and (min-width:769px) {

  .modal__inner {
    margin: 40px
  }
}

.modal--square .modal__inner {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody)
}

.modal__inner img {
  display: block;
  max-height: 90vh;
}

.modal__inner .image-wrap img {
  max-height: none;
}

.modal__centered {
  position: relative;
  flex: 0 1 auto;
  min-width: 1px;
  max-width: 100%;
}

.modal--square .modal__centered-content {
  max-height: 80vh;
  padding: 22.5px;
  min-width: 200px;
  min-height: 200px;
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

@media only screen and (min-width:769px) {

  .modal--square .modal__centered-content {
    padding: 45px;
    max-height: 90vh;
    max-width: 1200px
  }
}

.modal__close {
  border: 0;
  padding: 15px;
  position: fixed;
  top: 0;
  right: 0;
  color: #fff
}

@media only screen and (min-width:769px) {

  .modal__close {
    padding: 30px
  }
}

.modal__close .icon {
  width: 28px;
  height: 28px;
}

.modal__close:focus,
.modal__close:hover {
  color: #fff;
}

.modal--square .modal__close {
  position: absolute;
  color: #000000
  ;
  color: var(--colorTextBody);
  padding: 10px
}

.modal--square .modal__close:focus,
.modal--square .modal__close:hover {
  color: #000000
  ;
  color: var(--colorTextBody);
}

.modal .page-content,
.modal .page-width {
  padding: 0;
}

.popup-cta {
  margin-bottom: 15px;
}

@media only screen and (max-width:768px) {
  .modal--mobile-friendly {
    top: auto;
    bottom: 0;
    overflow: auto
  }

  .modal--mobile-friendly.modal--square .modal__centered-content {
    padding: 20px 20px 0;
  }

  .modal--mobile-friendly.modal--is-active {
    overflow: auto;
  }
  .modal-open .modal--mobile-friendly:before {
    display: none;
  }

  .modal--mobile-friendly .modal__inner {
    margin: 0;
    border: 2px solid;
    border-color: #000000
    ;
    border-color: var(--colorTextBody);
  }

  .modal--mobile-friendly .h1 {
    padding-right: 25px;
  }

  .modal--mobile-friendly input {
    font-size: 16px !important;
  }

  .modal--mobile-friendly .text-close {
    display: none;
  }
}

.js-qty__wrapper {
  display: inline-block;
  position: relative;
  max-width: 100px;
  min-width: 75px;
  overflow: visible;
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
}

.js-qty__num {
  display: block;
  background: none;
  text-align: center;
  width: 100%;
  padding: 8px 25px;
  margin: 0;
  z-index: 1;
}

.js-qty__adjust {
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  border: 0 none;
  background: none;
  text-align: center;
  overflow: hidden;
  padding: 0 5px;
  line-height: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: background-color 0.1s ease-out;
  z-index: 2;
  fill: #000000
  ;
  fill: var(--colorTextBody)
}

.js-qty__adjust .icon {
  display: block;
  font-size: 8px;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  fill: inherit;
}

.js-qty__adjust:hover {
  background-color: #f2f2f2
  ;
  background-color: var(--colorBodyDim);
  color: #000000
  ;
  color: var(--colorTextBody);
}

.js-qty__num:active~.js-qty__adjust,
.js-qty__num:focus~.js-qty__adjust {
  border-color: #000000
  ;
  border-color: var(--colorTextBody)
}

.js-qty__adjust--plus {
  right: 0;
  border-left: 1px solid;
  border-left-color: #f2f2f2
  ;
  border-left-color: var(--colorBodyDim);
}

.js-qty__adjust--minus {
  left: 0;
  border-right: 1px solid;
  border-right-color: #f2f2f2
  ;
  border-right-color: var(--colorBodyDim);
}

.drawer .js-qty__wrapper {
  background-color: transparent;
  border-color: #e8e8e1
  ;
  border-color: var(--colorDrawerBorder);
}

.drawer .js-qty__num {
  color: #000000
  ;
  color: var(--colorDrawerText);
  border-color: #e8e8e1
  ;
  border-color: var(--colorDrawerBorder);
  padding-top: 2px;
  padding-bottom: 2px;
}

.drawer .js-qty__adjust {
  border-color: #e8e8e1
  ;
  border-color: var(--colorDrawerBorder);
  color: #000000
  ;
  color: var(--colorDrawerText);
  fill: #000000
  ;
  fill: var(--colorDrawerText)
}

.drawer .js-qty__adjust:hover {
  background-color: #e8e8e1
  ;
  background-color: var(--colorDrawerBorder);
  color: #ffffff
  ;
  color: var(--colorDrawers);
  fill: #ffffff
  ;
  fill: var(--colorDrawers);
}

.drawer .js-qty__num:active~.js-qty__adjust,
.drawer .js-qty__num:focus~.js-qty__adjust {
  border-color: #e8e8e1
  ;
  border-color: var(--colorDrawerBorder);
}

.currency-flag {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.3);
  margin: 0 5px
}

.currency-flag:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

.collapsibles-wrapper--border-bottom {
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorBorder);
}

.collapsible-trigger {
  color: inherit;
  position: relative;
}

.collapsible-trigger__icon {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  transform: translateY(-50%)
}

@media only screen and (min-width:769px) {

  .collapsible-trigger__icon {
    width: 12px;
    height: 12px
  }
}

.mobile-nav__has-sublist .collapsible-trigger__icon {
  right: 25px
}

.collapsible-trigger__icon .icon {
  display: block;
  width: 10px;
  height: 10px;
  transition: all 0.1s ease-in
}

@media only screen and (min-width:769px) {

  .collapsible-trigger__icon .icon {
    width: 12px;
    height: 12px
  }
}

.collapsible-trigger--inline {
  padding: 11px 0 11px 40px
}

.collapsible-trigger--inline .collapsible-trigger__icon {
  right: auto;
  left: 0;
}

.collapsible-trigger__icon--circle {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  width: 28px;
  height: 28px;
  border-radius: 28px;
  text-align: center
}

.collapsible-trigger__icon--circle .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.collapsible-trigger.is-open .collapsible-trigger__icon > .icon-chevron-down {
  transform: scaleY(-1);
}

.collapsible-trigger.is-open .collapsible-trigger__icon--circle > .icon-chevron-down {
  transform: translate(-50%, -50%) scaleY(-1);
}

.collapsible-content {
  transition: opacity 0.3s cubic-bezier(0.25,0.46,0.45,0.94), height 0.3s cubic-bezier(0.25,0.46,0.45,0.94)
}

.collapsible-content.is-open {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.25,0.46,0.45,0.94), height 0.35s cubic-bezier(0.25,0.46,0.45,0.94);
}

.collapsible-content--all {
  visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  height: 0
}

.collapsible-content--all .collapsible-content__inner {
  transform: translateY(40px);
}

.collapsible-content--all .collapsible-content__inner--no-translate {
  transform: translateY(0);
}

@media only screen and (max-width:768px) {
  .collapsible-content--small {
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    height: 0
  }

  .collapsible-content--small .collapsible-content__inner {
    transform: translateY(40px);
  }

  .collapsible-content--small .collapsible-content__inner--no-translate {
    transform: translateY(0);
  }
}

.collapsible-content__inner {
  transition: transform 0.3s cubic-bezier(0.25,0.46,0.45,0.94)
}

.is-open .collapsible-content__inner {
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.25,0.46,0.45,0.94)
}

.rte.collapsible-content__inner--faq {
  padding-bottom: 30px;
}

.collapsible-trigger[aria-expanded=true] .collapsible-label__closed {
  display: none
}

.collapsible-label__open {
  display: none
}

.collapsible-trigger[aria-expanded=true] .collapsible-label__open {
  display: inline-block
}

.collapsible-content--sidebar {
  visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  height: 0
}

@media only screen and (min-width:769px) {
  .collapsible-content--sidebar.is-open {
    overflow: visible;
    overflow: initial;
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

.pswp {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
}

.pswp img {
  max-width: none;
}

.pswp--animate_opacity {
  opacity: 0.001;
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: grabbing;
}

.pswp__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff
  ;
  background: var(--colorBody);
  opacity: 0;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

.pswp__scroll-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pswp__container,
.pswp__zoom-wrap {
  touch-action: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.pswp__container,
.pswp__img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  position: absolute;
  width: 100%;
  transform-origin: left top;
  transition: transform 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp__bg {
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp--animated-in .pswp__bg,
.pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}

.pswp__container,
.pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.pswp__img {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp--ie .pswp__img {
  width: 100% !important;
  height: auto !important;
  left: 0;
  top: 0;
}

.pswp__error-msg {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  line-height: 16px;
  margin-top: -8px;
  color: #ccc;
}

.pswp__error-msg a {
  color: #ccc;
  text-decoration: underline;
}

.pswp__button {
  position: relative
}

.pswp__button:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pswp__button svg {
  pointer-events: none;
}

.pswp__button--arrow--left .icon,
.pswp__button--arrow--right .icon {
  width: 13px;
  height: 13px;
  margin: 8px;
}

.pswp__button[disabled] {
  opacity: 0;
  pointer-events: none;
}

.pswp__ui {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  left: 0;
  right: 0;
  transform: translateY(0);
  transition: transform 0.25s 0.6s
}

.pswp__ui .btn {
  margin: 15px;
}

.pswp__ui--hidden {
  transform: translateY(150%);
  transition: transform 0.25s;
}

.disclosure {
  position: relative;
}

.disclosure__toggle {
  white-space: nowrap;
}

.disclosure-list {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
  bottom: 100%;
  padding: 10px 0;
  margin: 0;
  position: absolute;
  display: none;
  min-height: 92px;
  max-height: 60vh;
  overflow-y: auto;
  border-radius: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.09)
}

.disclosure-list a {
  color: currentColor;
}

.disclosure-list--down {
  bottom: auto;
  top: 100%;
  z-index: 30;
}

.disclosure-list--left {
  right: 0;
}

.disclosure-list--visible {
  display: block;
}

.disclosure-list__item {
  white-space: nowrap;
  padding: 5px 15px 4px;
  text-align: left
}

.disclosure-list__item .disclosure-list__label {
  border-bottom: 1px solid transparent;
}

.disclosure-list__option:focus .disclosure-list__label,
.disclosure-list__option:hover .disclosure-list__label {
  border-bottom: 1px solid currentColor;
}

.disclosure-list__item--current .disclosure-list__label {
  border-bottom: 1px solid currentColor;
}

.disclosure-list__label {
  display: inline-block;
  vertical-align: middle
}

.disclosure-list__label span {
  border-bottom: 2px solid transparent
}

.is-active .disclosure-list__label span {
  border-bottom: 2px solid currentColor
}

.multi-selectors {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.multi-selectors__item {
  margin: 0 10px
}

.toolbar .multi-selectors__item {
  margin-right: 0
}

.shopify-model-viewer-ui .shopify-model-viewer-ui__controls-area {
  opacity: 1;
  background: #ffffff
  ;
  background: var(--colorBody);
  border-color: rgba(0, 0, 0, 0.05)
  ;
  border-radius: 50px;
}

.shopify-model-viewer-ui .shopify-model-viewer-ui__button {
  color: #000000
  ;
  color: var(--colorTextBody);
}

.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:hover {
  color: #000000
  ;
  color: var(--colorTextBody);
}

.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control.focus-visible:focus,
.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:active {
  color: #000000
  ;
  color: var(--colorTextBody);
  background-color: rgba(0, 0, 0, 0.05)
  ;
}

.shopify-model-viewer-ui .shopify-model-viewer-ui__button--control:not(:last-child):after {
  border-color: rgba(0, 0, 0, 0.05)
  ;
}

.shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster {
  background-color: #000000
  ;
  background-color: var(--colorTextBody);
  color: #ffffff
  ;
  color: var(--colorBody);
  border-radius: 100%;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.05)
}

.shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster:focus,
.shopify-model-viewer-ui .shopify-model-viewer-ui__button--poster:hover {
  color: #ffffff
  ;
  color: var(--colorBody);
}

.product-single__view-in-space {
  display: block;
  color: #000000
  ;
  color: var(--colorTextBody);
  background-color: rgba(0, 0, 0, 0.08)
  ;
  width: 80%;
  width: calc(80% - 4px);
  margin: 0 10% 10px;
  padding: 5px 10px 10px
}

.slick-dotted.slick-slider + .product-single__view-in-space {
  margin-top: -10px
}

.product-single__view-in-space[data-shopify-xr-hidden] {
  display: none;
}

.product-single__view-in-space-text {
  font-size: calc(var(--typeBaseSize) * 0.85);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.shopify-model-viewer-ui,
.shopify-model-viewer-ui model-viewer {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shopify-model-viewer-ui__button[hidden] {
  display: none;
}

.product-single__close-media {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  direction: ltr
}

html[dir=rtl] .slick-slider {
  direction: rtl
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block
}

.slick-track:after,
.slick-track:before {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block
}

.slick-loading .slick-slide {
  visibility: hidden
}

.slick-vertical .slick-slide {
  display: block;
  height: auto
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: none;
  opacity: 1;
  z-index: 20
}

.slick-arrow.slick-disabled {
  opacity: 0.18;
}

.slick-arrow .icon {
  position: relative;
  top: 1px;
  display: block;
  width: 6px;
  height: 10px;
}

.slick-prev {
  left: 25px
}

[dir=rtl] .slick-prev {
  left: auto;
  right: 25px
}

@media only screen and (max-width:768px) {

  .slick-prev {
    left: 12px
  }

  [dir=rtl] .slick-prev {
    right: 12px
  }
}

.slick-next {
  right: 25px
}

[dir=rtl] .slick-next {
  left: 25px;
  right: auto
}

@media only screen and (max-width:768px) {

  .slick-next {
    right: 12px
  }

  [dir=rtl] .slick-next {
    left: 12px
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 10px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  opacity: 0.18;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 0.75;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

:-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

input,
select,
textarea {
  background-color: inherit;
  color: inherit
}

input.disabled,
input[disabled],
select.disabled,
select[disabled],
textarea.disabled,
textarea[disabled] {
  background-color: #f6f6f6;
  border-color: transparent;
}

input:active,
input:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  border: 1px solid;
  border-color: #000000
  ;
  border-color: var(--colorTextBody);
}

input[type=image] {
  background-color: transparent;
}

[data-center-text=true] .social-sharing {
  text-align: center
}

.social-sharing .icon {
  height: 18px;
  width: 18px;
}

.social-sharing__link {
  display: inline-block;
  color: #000000
  ;
  color: var(--colorTextBody);
  border-radius: 2px;
  margin: 0 18px 0 0;
  text-decoration: none;
  font-weight: 400
}

.social-sharing__link:last-child {
  margin-right: 0;
}

.social-sharing__title {
  font-size: calc(var(--typeBaseSize) * 0.85);
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
  padding-left: 3px;
}

.grid-search {
  margin-bottom: 30px;
}

.grid-search__product {
  position: relative;
  text-align: center;
}

.grid-search__page-link {
  display: block;
  background-color: #fafafa
  ;
  padding: 20px;
  color: #000000
  ;
  color: var(--colorTextBody);
  overflow: hidden;
  text-overflow: ellipsis
}

.grid-search__page-link:focus,
.grid-search__page-link:hover {
  background-color: #f5f5f5
  ;
}

.grid-search__page-content {
  display: block;
  height: 100%;
  overflow: hidden;
}

.grid-search__page-content img {
  display: block;
  margin-bottom: 10px;
}

.grid-search__image {
  display: block;
  padding: 20px;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%
}

@media only screen and (min-width:769px) {

  .grid-search__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}

.index-section {
  margin: 40px 0;
}

.index-section--small {
  margin: 18px 0;
}

.index-section + .index-section,
.index-section+.index-section--hidden,
.main-content + .index-section {
  margin-top: 0;
}

.index-section--flush {
  margin: 0;
}

.section--divider {
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorBorder);
  padding-top: 40px;
}

.index-section--faq {
  margin-bottom: 40px;
}

.newsletter-section {
  padding: 40px 0;
}

.newsletter-section--with-divider {
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorBorder);
}

.index-section--footer .index-section {
  margin-top: 0;
}

.template-challange .index-section--footer {
  display: none;
}

@media only screen and (min-width:769px) {
  .index-section {
    margin: 75px 0;
  }

  .index-section--small {
    margin: 30px 0;
  }

  .section--divider {
    padding-top: 75px;
  }

  .index-section--faq {
    margin: 75px 0 37.5px;
  }

  .newsletter-section {
    padding: 75px 0;
  }
}

.page-blocks--flush .page-width {
  padding: 0;
}

.page-blocks > div:first-child .index-section {
  margin-top: 0;
}

.feature-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column
}

@media only screen and (min-width:769px) {

  .feature-row {
    flex-direction: row;
    margin: 0 auto
  }
}

@media only screen and (min-width:1050px) {

  .feature-row {
    margin: 0 6%
  }
}

@media only screen and (max-width:959px) {
  .feature-row--small-none {
    display: block;
  }
}

.feature-row__item {
  flex: 1 1 100%;
  width: 100%;
  max-width: 100%
}

@media only screen and (min-width:769px) {

  .feature-row__item {
    order: 1;
    min-width: 50%;
    flex: 0 1 50%
  }
}

.feature-row__image {
  display: block;
  margin: 0 auto;
  order: 1
}

@media only screen and (min-width:769px) {

  .feature-row__image {
    order: 2
  }
}

.feature-row__text {
  font-size: 1.1em;
  padding-top: 30px;
  padding-bottom: 30px;
  order: 2;
  padding-top: 15px;
  padding-bottom: 0
}

@media only screen and (min-width:769px) {

  .feature-row__text {
    order: 1
  }
}

@media only screen and (max-width:768px) {

  .feature-row__text {
    text-align: center
  }
}

.feature-row__text .rte {
  margin: 0;
}

.feature-row__text .btn {
  margin: 15px 10px 0 0;
}

@media only screen and (min-width:769px) {
  .feature-row__text--left {
    padding-left: 60px;
  }

  .feature-row__text--right {
    padding-right: 60px;
  }
}

.product__photo-dots .slick-dots li,
.slick-slider .slick-dots li {
  vertical-align: middle;
}

.product__photo-dots .slick-dots li,
.product__photo-dots .slick-dots li button,
.slick-slider .slick-dots li,
.slick-slider .slick-dots li button {
  width: 6px;
  height: 6px;
}

.product__photo-dots .slick-dots li button:before,
.slick-slider .slick-dots li button:before {
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

.product__photo-dots .slick-dots li.slick-active,
.product__photo-dots .slick-dots li.slick-active button,
.product__photo-dots .slick-dots li.slick-active button:before,
.slick-slider .slick-dots li.slick-active,
.slick-slider .slick-dots li.slick-active button,
.slick-slider .slick-dots li.slick-active button:before {
  width: 8px;
  height: 8px;
  opacity: 1;
}

.product__photo-dots[data-bars] .slick-dots li,
.product__photo-dots[data-bars] .slick-dots li button,
.slick-slider[data-bars] .slick-dots li,
.slick-slider[data-bars] .slick-dots li button {
  padding: 0;
  width: 150px;
  height: 18px;
}

.product__photo-dots[data-bars] .slick-dots li button:after,
.product__photo-dots[data-bars] .slick-dots li button:before,
.slick-slider[data-bars] .slick-dots li button:after,
.slick-slider[data-bars] .slick-dots li button:before {
  content: "";
  width: 150px;
  height: 4px;
  border-radius: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-2px)
}

@media only screen and (min-width:769px) {

  .product__photo-dots[data-bars] .slick-dots li button:after,
  .product__photo-dots[data-bars] .slick-dots li button:before,
  .slick-slider[data-bars] .slick-dots li button:after,
  .slick-slider[data-bars] .slick-dots li button:before {
    height: 6px
  }
}

.product__photo-dots[data-bars] .slick-dots li.slick-active button:before,
.slick-slider[data-bars] .slick-dots li.slick-active button:before {
  opacity: 1;
}

.product__photo-dots[data-bars] .slick-dots li button:after,
.slick-slider[data-bars] .slick-dots li button:after {
  background-color: hsla(0, 0%, 100%, 0.25);
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.8);
}

.product__photo-dots[data-bars] .slick-dots li.slick-active,
.product__photo-dots[data-bars] .slick-dots li.slick-active button,
.slick-slider[data-bars] .slick-dots li.slick-active,
.slick-slider[data-bars] .slick-dots li.slick-active button {
  height: 18px;
}

.product__photo-dots[data-bars] .slick-dots li.slick-active button:after,
.product__photo-dots[data-bars] .slick-dots li.slick-active button:before,
.slick-slider[data-bars] .slick-dots li.slick-active button:after,
.slick-slider[data-bars] .slick-dots li.slick-active button:before {
  width: 150px;
  height: 4px
}

@media only screen and (min-width:769px) {

  .product__photo-dots[data-bars] .slick-dots li.slick-active button:after,
  .product__photo-dots[data-bars] .slick-dots li.slick-active button:before,
  .slick-slider[data-bars] .slick-dots li.slick-active button:after,
  .slick-slider[data-bars] .slick-dots li.slick-active button:before {
    height: 6px
  }
}

.product__photo-dots[data-bars] .slick-dots li.slick-active button:before,
.slick-slider[data-bars] .slick-dots li.slick-active button:before {
  background-color: #fff;
}

@media only screen and (max-width:768px) {
  .product__photo-dots[data-bars] .slick-dots li,
  .product__photo-dots[data-bars] .slick-dots li.slick-active,
  .product__photo-dots[data-bars] .slick-dots li.slick-active button,
  .product__photo-dots[data-bars] .slick-dots li.slick-active button:after,
  .product__photo-dots[data-bars] .slick-dots li.slick-active button:before,
  .product__photo-dots[data-bars] .slick-dots li button,
  .product__photo-dots[data-bars] .slick-dots li button:after,
  .product__photo-dots[data-bars] .slick-dots li button:before,
  .slick-slider[data-bars] .slick-dots li,
  .slick-slider[data-bars] .slick-dots li.slick-active,
  .slick-slider[data-bars] .slick-dots li.slick-active button,
  .slick-slider[data-bars] .slick-dots li.slick-active button:after,
  .slick-slider[data-bars] .slick-dots li.slick-active button:before,
  .slick-slider[data-bars] .slick-dots li button,
  .slick-slider[data-bars] .slick-dots li button:after,
  .slick-slider[data-bars] .slick-dots li button:before {
    width: 40px;
  }
}

.product__photo-dots[data-bars][data-autoplay=true] .slick-dots li button:before,
.slick-slider[data-bars][data-autoplay=true] .slick-dots li button:before {
  width: 0;
}

.product__photo-dots[data-bars][data-autoplay=true] .slick-dots li.slick-active button:before,
.slick-slider[data-bars][data-autoplay=true] .slick-dots li.slick-active button:before {
  animation: slideshowBars 0s linear forwards;
}

.hero .slick-dots {
  bottom: 6px
}

.hero .slick-dots li button:before {
  background-color: #fff;
}

.hero[data-bars] .slick-dots {
  bottom: -8px
}

@media only screen and (max-width:768px) {

  .hero[data-bars] .slick-dots {
    bottom: -9px
  }
}

.hero.slick-dotted {
  margin-bottom: 0;
}

.slick-track {
  cursor: pointer;
  cursor: -webkit-grab
}

[data-slide-count="1"] .slick-track {
  cursor: default
}

[data-slide-count="1"] .slick-track:active {
  cursor: default;
}

.slick-track:active {
  cursor: -webkit-grabbing;
}

.hero .slick-next,
.hero .slick-prev {
  top: 40%;
  opacity: 0.75
}

.hero .slick-next:hover,
.hero .slick-prev:hover {
  opacity: 1;
}

.hero .slick-next .icon,
.hero .slick-prev .icon {
  color: #ffffff
  ;
  color: var(--colorHeroText);
  width: 20px;
  height: 30px;
  filter:drop-shadow(0 0 20px rgba(0, 0, 0, 1))
}

@media only screen and (max-width:768px) {

  .hero .slick-next .icon,
  .hero .slick-prev .icon {
    height: 20px
  }
}

.hero .slick-list,
.hero .slick-track,
.hero .slideshow__slide {
  height: 100%;
}

.slideshow-wrapper {
  position: relative;
}

.slideshow__pause:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  background-color: #111111
  ;
  background-color: var(--colorBtnPrimary);
  padding: 10px;
  z-index: 10000;
  transition: none
}

.video-is-playing .slideshow__pause:focus {
  display: none
}

.slideshow__pause-stop {
  display: block
}

.is-paused .slideshow__pause-stop {
  display: none
}

.slideshow__pause-play {
  display: none
}

.is-paused .slideshow__pause-play {
  display: block
}

.slideshow__slide {
  display: none;
  position: relative;
  overflow: hidden
}

.slideshow__slide:first-child {
  display: block;
}

.hero {
  position: relative;
  overflow: hidden;
  background: #0f0f0f
  ;
  background: var(--colorLargeImageBg);
}

.hero__media-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.hero__image-wrapper,
.hero__media {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.hero__image-wrapper:before,
.hero__media:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: #000000
  ;
  background-color: var(--colorImageOverlay);
  opacity: 0.18
  ;
  opacity: var(--colorImageOverlayOpacity)
}

.video-interactable .hero__image-wrapper:before,
.video-interactable .hero__media:before {
  pointer-events: none
}

.hero__image {
  z-index: 1;
}

.hero__media {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: 1;
}

.hero__image-wrapper--no-overlay:before {
  content: none;
}

.hero__media iframe,
.hero__media video {
  width: 100%;
  height: 100%;
  pointer-events: none
}

.video-interactable .hero__media iframe,
.video-interactable .hero__media video {
  pointer-events: auto
}

.hero__media video {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.hero__media iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  left: -100%;
  max-width: none
}

@media screen and (min-width:1140px) {

  .hero__media iframe {
    width: 100%;
    height: 300%;
    left: auto;
    top: -100%
  }
}

.vimeo-mobile-trigger {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 2;
  margin-top: 90px
}

.hero__text-content .vimeo-mobile-trigger {
  bottom: 120%
}

.vimeo-mobile-trigger .icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
}

.hero__slide-link {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5
}

.hero__slide-link:hover~.hero__text-wrap .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse) {
  background: #2b2b2b
  ;
  background: var(--colorBtnPrimaryLight);
  transition-delay: 0.25s
}

.hero__slide-link:hover~.hero__text-wrap .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):after {
  animation: shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);
}

[data-button_style=angled] .hero__slide-link:hover~.hero__text-wrap .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):after {
  animation: none;
}

.hero__text-wrap {
  position: relative;
  height: 100%;
  color: #ffffff
  ;
  color: var(--colorHeroText)
}

.video-interactable .hero__text-wrap {
  pointer-events: none
}

.hero__text-wrap .page-width {
  display: table;
  width: 100%;
  height: 100%;
}

.hero__text-content {
  position: relative;
  padding: 15px 0;
  z-index: 4
}

@media only screen and (min-width:769px) {

  .hero__text-content {
    padding: 45px 0
  }
}

[data-arrows=true] .hero__text-content {
  padding-left: 20px;
  padding-right: 20px
}

.hero__text-shadow {
  position: relative;
  display: inline-block
}

.hero__text-shadow:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;
  background: radial-gradient(rgba(0,0,0,0.26) 0%, transparent 60%);
  background: radial-gradient(rgba(0,0,0,var(--colorImageOverlayTextShadow)) 0%, transparent 60%);
  margin: -100px -200px;
}

.hero__top-subtitle {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.hero__title {
  display: block;
  margin-bottom: 0;
}

.hero__subtext {
  margin-top: 20px;
}

.hero__subtitle {
  font-size: 1.3em;
  display: block;
  vertical-align: middle;
  margin-top: 5px;
  margin-bottom: 5px;
}

.hero__link {
  position: relative;
  display: block
}

.video-interactable .hero__link {
  pointer-events: auto
}

.hero__link .btn {
  margin: 4px 3px 2px 0
}

@media only screen and (min-width:769px) {

  .hero__link .btn {
    margin-right: 5px;
    margin-top: 0
  }
}

[data-button_style=angled] .hero__link .btn {
  margin-left: 12px
}

.hero__link .btn + .btn {
  margin-left: 2px
}

@media only screen and (min-width:769px) {

  .hero__link .btn + .btn {
    margin-left: 10px
  }
}

[data-button_style=angled] .hero__link .btn + .btn {
  margin-left: 12px
}

.hero__link .btn .icon-play {
  position: relative;
  top: -2px;
  margin-right: 5px;
}

[data-button_style=angled] .hero__link .animation-cropper {
  padding-right: 10px;
}

.hero__text-content {
  display: table-cell
}

.hero__text-content .hero__link {
  margin-top: 7.5px
}

@media only screen and (min-width:769px) {

  .hero__text-content .hero__link {
    margin-top: 15px
  }
}

.hero__text-content.horizontal-left {
  text-align: left;
}

.hero__text-content.horizontal-center {
  text-align: center;
}

.hero__text-content.horizontal-right {
  text-align: right;
}

.hero__text-content.vertical-center {
  vertical-align: middle;
}

.hero__text-content.vertical-bottom {
  vertical-align: bottom;
}

.slick-dotted .hero__text-content.vertical-bottom {
  padding-bottom: 28px
}

@media only screen and (min-width:769px) {

  .slick-dotted .hero__text-content.vertical-bottom {
    padding-bottom: 60px
  }
}

.overlaid-header .index-section--hero:first-child .hero__text-content.vertical-center {
  padding-top: 50px
}

@media only screen and (min-width:769px) {

  .overlaid-header .index-section--hero:first-child .hero__text-content.vertical-center {
    padding-top: 90px
  }
}

.hero--450px {
  height: 292.5px;
}

.hero--550px {
  height: 357.5px;
}

.hero--650px {
  height: 422.5px;
}

.hero--750px {
  height: 487.5px;
}

.hero--100vh {
  height: 100vh;
}

.hero--natural[data-natural] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width:769px) {
  .hero--450px {
    height: 450px;
  }
  .hero--550px {
    height: 550px;
  }
  .hero--650px {
    height: 650px;
  }
  .hero--750px {
    height: 750px;
  }
}

@media only screen and (max-width:768px) {
  .hero--mobile--250px:not([data-natural]) {
    height: 250px;
  }
  .hero--mobile--300px:not([data-natural]) {
    height: 300px;
  }
  .hero--mobile--400px:not([data-natural]) {
    height: 400px;
  }
  .hero--mobile--500px:not([data-natural]) {
    height: 500px;
  }
  .hero--mobile--100vh:not([data-natural]) {
    height: 90vh;
  }
}

.index-section--hero:first-child [data-align-top] .slideshow-wrapper {
  position: relative;
  z-index: 1;
}

.hero .slideshow__slide .hero__link .btn,
.hero .slideshow__slide .hero__subtitle .animation-contents,
.hero .slideshow__slide .hero__title .animation-contents,
.hero .slideshow__slide .hero__top-subtitle .animation-contents,
[data-aos=hero__animation] .hero__link .btn,
[data-aos=hero__animation] .hero__subtitle .animation-contents,
[data-aos=hero__animation] .hero__title .animation-contents,
[data-aos=hero__animation] .hero__top-subtitle .animation-contents {
  opacity: 0
}

.no-js .hero .slideshow__slide .hero__link .btn,
.no-js .hero .slideshow__slide .hero__subtitle .animation-contents,
.no-js .hero .slideshow__slide .hero__title .animation-contents,
.no-js .hero .slideshow__slide .hero__top-subtitle .animation-contents,
.no-js [data-aos=hero__animation] .hero__link .btn,
.no-js [data-aos=hero__animation] .hero__subtitle .animation-contents,
.no-js [data-aos=hero__animation] .hero__title .animation-contents,
.no-js [data-aos=hero__animation] .hero__top-subtitle .animation-contents {
  opacity: 1
}

.hero .slideshow__slide .hero__image,
.hero .slideshow__slide .hero__media,
[data-aos=hero__animation] .hero__image,
[data-aos=hero__animation] .hero__media {
  opacity: 0
}

.no-js .hero .slideshow__slide .hero__image,
.no-js .hero .slideshow__slide .hero__media,
.no-js [data-aos=hero__animation] .hero__image,
.no-js [data-aos=hero__animation] .hero__media {
  opacity: 1
}

.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__top-subtitle .animation-contents,
[data-aos=hero__animation].loaded.aos-animate .hero__top-subtitle .animation-contents {
  animation: rise-up 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0.7s forwards;
}

.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__title .animation-contents,
[data-aos=hero__animation].loaded.aos-animate .hero__title .animation-contents {
  animation: rise-up 0.8s cubic-bezier(0.26, 0.54, 0.32, 1) 0.3s forwards;
}

.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__subtitle .animation-contents,
[data-aos=hero__animation].loaded.aos-animate .hero__subtitle .animation-contents {
  animation: rise-up 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0.7s forwards;
}

.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__link .btn,
[data-aos=hero__animation].loaded.aos-animate .hero__link .btn {
  animation: fade-in 2s ease 1.3s forwards;
}

.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__link .btn:nth-child(2),
[data-aos=hero__animation].loaded.aos-animate .hero__link .btn:nth-child(2) {
  animation: fade-in 2s ease 1.6s forwards;
}

.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__image--svg,
.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__image.lazyloaded,
.hero.loaded.aos-animate .slideshow__slide.slick-active .hero__media,
[data-aos=hero__animation].loaded.aos-animate .hero__image--svg,
[data-aos=hero__animation].loaded.aos-animate .hero__image.lazyloaded,
[data-aos=hero__animation].loaded.aos-animate .hero__media {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none
}

[data-disable-animations=true] .hero.loaded.aos-animate .slideshow__slide.slick-active .hero__image--svg,
[data-disable-animations=true] .hero.loaded.aos-animate .slideshow__slide.slick-active .hero__image.lazyloaded,
[data-disable-animations=true] .hero.loaded.aos-animate .slideshow__slide.slick-active .hero__media,
[data-disable-animations=true] [data-aos=hero__animation].loaded.aos-animate .hero__image--svg,
[data-disable-animations=true] [data-aos=hero__animation].loaded.aos-animate .hero__image.lazyloaded,
[data-disable-animations=true] [data-aos=hero__animation].loaded.aos-animate .hero__media {
  opacity: 1;
  animation: none
}

.hero__image-wrapper {
  transform: translateX(200px);
  opacity: 0;
  transition: none;
}

.slideshow__slide .hero__subtitle .animation-cropper,
.slideshow__slide .hero__title .animation-cropper,
.slideshow__slide .hero__top-subtitle .animation-cropper {
  opacity: 0;
  transition: none;
}

.slideshow__slide .hero__link {
  opacity: 0;
  transition: none;
}

.slick-current .hero__image-wrapper {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.7s ease;
}

.slick-current .hero__top-subtitle .animation-cropper {
  opacity: 1;
  transition: all 0.5s ease-in 0.64s;
}

.slick-current .hero__title .animation-cropper {
  opacity: 1;
  transition: all 0.5s ease-in 0.25s;
}

.slick-current .hero__subtitle .animation-cropper {
  opacity: 1;
  transition: all 0.5s ease-in 0.64s;
}

.slick-current .hero__link {
  opacity: 1;
  transition: all 0.61s ease-in 1.18s;
}

.slideshow__slide.animate-out .hero__image-wrapper {
  opacity: 0;
  transform: translateX(-200px);
  transition: all 0.5s ease-in 0.05s;
}

.slideshow__slide.animate-out .hero__subtitle .animation-cropper,
.slideshow__slide.animate-out .hero__title .animation-cropper,
.slideshow__slide.animate-out .hero__top-subtitle .animation-cropper {
  opacity: 0;
  transition: none;
}

.slideshow__slide.animate-out .hero__link {
  opacity: 0;
  transition: none;
}

.callout-images {
  position: relative;
  height: 225px;
  width: 225px;
  max-width: 100%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center
}

@media only screen and (min-width:769px) {

  .callout-images {
    height: 450px;
    width: 450px
  }
}

.callout-image-centered {
  position: relative;
  width: 140px
}

@media only screen and (min-width:769px) {

  .callout-image-centered {
    width: 280px
  }
}

.callout-image-placeholder {
  display: block;
  max-height: 100%;
  margin: 0 auto 30px
}

@media only screen and (max-width:768px) {

  .callout-image-placeholder {
    max-width: 585px;
    margin: -75px 0 0 -145px
  }
}

.feature-row__callout-image + .feature-row__text .feature-row__content {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  position: relative;
  z-index: 3;
  margin: -10px;
  padding-left: 10px;
}

@media only screen and (min-width:769px) {
  .feature-row__callout-image {
    order: 2;
  }
  .feature-row__callout-text.feature-row__text--left {
    order: 1;
    padding: 0 60px 0 0;
  }
  .feature-row__callout-text.feature-row__text--right {
    order: 3;
    padding: 0 0 0 60px;
  }
}

.callout-image {
  position: absolute;
  transition: all 2.5s cubic-bezier(0.39, 0.68, 0.29, 1) 0.5s;
  z-index: 1;
  background-color: #ffffff
  ;
  background-color: var(--colorBody)
}

.callout-image:first-child {
  position: relative;
  width: 100%;
  z-index: 2;
}

.callout-image:nth-child(2) {
  bottom: 50%;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
  max-width: 90px
}

@media only screen and (min-width:769px) {

  .callout-image:nth-child(2) {
    max-width: 180px
  }
}

.callout-image:nth-child(3) {
  top: 50%;
  right: 50%;
  transform: translate3d(50%, -50%, 0);
  max-width: 80px
}

@media only screen and (min-width:769px) {

  .callout-image:nth-child(3) {
    max-width: 160px
  }
}

.callout-image:nth-child(4) {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 100px
}

@media only screen and (min-width:769px) {

  .callout-image:nth-child(4) {
    max-width: 200px
  }
}

.callout-image:nth-child(5) {
  right: 50%;
  bottom: 50%;
  transform: translate3d(50%, 50%, 0);
  max-width: 50px
}

@media only screen and (min-width:769px) {

  .callout-image:nth-child(5) {
    max-width: 100px
  }
}

[data-aos=collection-callout].aos-animate .callout-image:first-child {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}

[data-aos=collection-callout].aos-animate .callout-image:nth-child(2) {
  bottom: 46%;
  left: -64px;
  transform: translateZ(0);
}

[data-aos=collection-callout].aos-animate .callout-image:nth-child(3) {
  top: 52%;
  right: -60px;
  transform: translateZ(0);
}

[data-aos=collection-callout].aos-animate .callout-image:nth-child(4) {
  top: 48%;
  left: -50px;
  transform: translateZ(0);
}

[data-aos=collection-callout].aos-animate .callout-image:nth-child(5) {
  bottom: 54%;
  right: -40px;
  transform: translateZ(0);
}

@media only screen and (min-width:769px) {
  [data-aos=collection-callout].aos-animate .callout-image:first-child {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
  }
  [data-aos=collection-callout].aos-animate .callout-image:nth-child(2) {
    left: -128px;
  }
  [data-aos=collection-callout].aos-animate .callout-image:nth-child(3) {
    right: -120px;
  }
  [data-aos=collection-callout].aos-animate .callout-image:nth-child(4) {
    left: -100px;
  }
  [data-aos=collection-callout].aos-animate .callout-image:nth-child(5) {
    right: -80px;
  }
}

.collection-content {
  padding-top: 22.5px
}

@media only screen and (min-width:769px) {

  .collection-content {
    padding-top: 45px
  }
}

.collection-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end
}

@media only screen and (max-width:768px) {

  .collection-filter {
    flex-wrap: wrap;
    position: -webkit-sticky;
    position: sticky;
    top: 17px;
    z-index: 5
  }

  .sticky-header .collection-filter {
    top: 86px
  }
}

.collection-filter .btn,
.collection-filter select {
  height: 44px;
  font-size: 16px;
}

.collection-filter select {
  display: block;
  width: 100%;
}

.collection-filter .btn {
  padding: 0 20px;
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder)
}

@media only screen and (max-width:768px) {

  .collection-filter .btn {
    width: 100%;
    text-align: left
  }
}

.collection-filter .btn .icon {
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.collection-filter__item {
  flex: 1 1 auto;
  margin-bottom: 15px;
}

.collection-filter .btn:not(.btn--tertiary-active),
.collection-filter__sort-container {
  background: #ffffff
  ;
  background: var(--colorBody);
}

.collection-filter__item--drawer {
  flex: 0 1 50%;
  padding-right: 7.5px;
}

.collection-filter__item--count {
  flex: 1 1 170%;
  text-align: center;
}

.collection-filter__item--sort {
  flex: 0 1 50%;
  padding-left: 7.5px;
}

@media only screen and (max-width:768px) {
  .collection-filter__item--count {
    order: 3;
    flex: 1 1 100%;
  }
}

.collection-hero {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
  background: #0f0f0f
  ;
  background: var(--colorLargeImageBg)
}

@media only screen and (min-width:769px) {

  .collection-hero {
    height: 420px
  }
}

.collection-hero__content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 15px 0;
  z-index: 1
}

@media only screen and (min-width:769px) {

  .collection-hero__content {
    padding: 30px 0
  }
}

[data-type_headers_align_text=true] .collection-hero__content {
  align-items: center
}

.overlaid-header .collection-hero__content {
  padding-top: 70px
}

@media only screen and (min-width:769px) {

  .overlaid-header .collection-hero__content {
    padding-top: 100px
  }
}

.collection-hero__content .page-width {
  width: 100%;
}

.collection-hero__content:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;
  background-color: #000000
  ;
  background-color: var(--colorImageOverlay);
  opacity: 0.18
  ;
  opacity: var(--colorImageOverlayOpacity);
  display: block;
}

.collection-hero__content .section-header {
  opacity: 0;
  animation: fade-in 0.5s 1s forwards
}

[data-disable-animations=true] .collection-hero__content .section-header {
  opacity: 1;
  animation: none
}

.collection-hero__image {
  opacity: 0
}

.no-js .collection-hero__image {
  opacity: 1
}

.collection-hero__image.lazyloaded {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none
}

[data-disable-animations=true] .collection-hero__image.lazyloaded {
  animation: none;
  opacity: 1
}

.collection-grid__wrapper.unload {
  min-height: 180px;
  position: relative
}

.collection-grid__wrapper.unload:after,
.collection-grid__wrapper.unload:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 5px;
  background: #ffffff
  ;
  background: var(--colorBody);
  left: 50%;
  top: 350px;
  margin: -3px 0 0 -50px;
  z-index: 4;
  opacity: 0
}

@media only screen and (max-width:768px) {

  .collection-grid__wrapper.unload:after,
  .collection-grid__wrapper.unload:before {
    top: 150px
  }
}

.no-js .collection-grid__wrapper.unload:after,
.no-js .collection-grid__wrapper.unload:before {
  display: none
}

.collection-grid__wrapper.unload:before {
  background: #000000
  ;
  background: var(--colorTextBody);
  animation: fade-in 0.5s 0s forwards;
}

.collection-grid__wrapper.unload:after {
  animation: preloading 0.5s ease 0.3s infinite;
}

.collection-grid__wrapper.unload [data-section-type=collection-template] {
  opacity: 0;
  transition-duration: 0.3s;
  animation: grid-product__loading 1.5s ease 1.5s infinite;
}

.collection-sidebar {
  padding-right: 10px;
  margin-bottom: 20px;
}

@media only screen and (min-width:769px) {
  .grid__item--sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    max-height: 90vh;
    overflow-y: auto;
  }
}

.collection-sidebar__group {
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorDrawerBorder);
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: 5px
}

.collection-sidebar__group:first-child {
  margin-top: 0
}

.drawer .collection-sidebar__group:first-child {
  border-top: 0
}

@media only screen and (min-width:769px) {
  .collection-sidebar__group .collapsible-content__inner {
    padding-bottom: 5px;
  }
}

.collection-sidebar__group .tag-list {
  margin-bottom: 0;
}

.collection-sidebar div:first-of-type .collection-sidebar__group {
  border-top: 0;
  padding-top: 0;
}

.is-light .site-header__logo .logo--has-inverted {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  height: 0
}

.site-header__logo .logo--inverted {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  height: 0
}

.is-light .site-header__logo .logo--inverted {
  opacity: 1;
  visibility: visible;
  height: auto
}

@media only screen and (min-width:769px) {

  .site-header__logo {
    text-align: left
  }
}

.header-logo a,
.site-header__logo a {
  color: #000000
  ;
  color: var(--colorNavText)
}

.is-light .header-logo a,
.is-light .site-header__logo a {
  color: #fff
}

.is-light .header-logo a:hover,
.is-light .site-header__logo a:hover {
  color: #fff;
}

.is-light .site-header {
  box-shadow: none
}

.site-nav__dropdown-link {
  display: block;
  padding: 8px 15px;
  white-space: nowrap
}

.megamenu .site-nav__dropdown-link {
  padding: 4px 0;
  white-space: normal
}

.tag-list__header {
  text-align: left;
}

.tag-list .tag-list {
  margin-left: 15px;
}

.tag-list a {
  display: block;
  line-height: 2;
}

.tag--active > a {
  font-weight: 900;
}

.tag-list--active-tags:empty {
  display: none;
}

.tag-list--checkboxes {
  padding-bottom: 6px
}

.tag-list--checkboxes a {
  position: relative;
  padding-left: 25px;
  font-size: calc(var(--typeBaseSize) - 2px);
  font-size: max(calc(var(--typeBaseSize) - 2px), 14px)
}

.tag-list--checkboxes a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.tag-list--checkboxes a:before {
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  height: 16px;
  width: 16px;
}

.tag-list--checkboxes .tag--active a:before {
  background-color: #000000
  ;
  background-color: var(--colorTextBody);
  border-color: #000000
  ;
  border-color: var(--colorTextBody);
}

.tag--remove {
  position: relative
}

.tag--remove a {
  text-align: left;
}

.tag--remove .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
}

.tag-list--swatches {
  margin-top: 2px;
  margin-left: -4px
}

.drawer .tag-list--swatches {
  margin-left: -2px
}

.tag-list--swatches li {
  display: inline-block;
}

.variant-input-wrap {
  border: 0;
  padding: 0;
  margin: 0 0 20px;
  position: relative
}

.variant-input-wrap input {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.variant-input-wrap label {
  font-family: var(--typeBasePrimary), var(--typeBaseFallback);
  font-size: calc(var(--typeBaseSize) * 0.85);
  letter-spacing: var(--typeBaseSpacing);
  line-height: var(--typeBaseLineHeight)
}

@media only screen and (min-width:769px) {

  .variant-input-wrap label {
    font-size: var(--typeBaseSize)
  }
}

.variant-input-wrap label {
  position: relative;
  display: inline-block;
  font-weight: 400;
  padding: 7px 15px;
  margin: 0 8px 12px 0;
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  box-shadow: 0 0 0 1px#e8e8e1;
  box-shadow: 0 0 0 1px var(--colorBorder)
}

[data-center-text=true] .variant-input-wrap label {
  margin: 0 4px 12px
}

.variant-input-wrap label.disabled {
  color: #e8e8e1
  ;
  color: var(--colorBorder);
}

.variant-input-wrap label.disabled:after {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  bottom: 0;
  border-left: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  transform: rotate(45deg);
}

.variant-input-wrap input[type=radio]:focus + label {
  border-color: rgba(0, 0, 0, 0.05)
  ;
  box-shadow: 0 0 0 1px#000000;
  box-shadow: 0 0 0 1px var(--colorTextBody);
}

.variant-input-wrap input[type=radio]:checked + label {
  box-shadow: 0 0 0 2px#000000;
  box-shadow: 0 0 0 2px var(--colorTextBody);
}

.variant-input {
  display: inline-block
}

select .variant-input {
  display: block
}

.variant-wrapper {
  margin-bottom: -12px
}

.no-js .variant-wrapper {
  display: none
}

.variant-wrapper--dropdown {
  display: inline-block;
  max-width: 100%;
  margin-right: 15px;
  margin-bottom: 0;
}

.variant__label[for] {
  display: block;
  margin-bottom: 10px;
  cursor: default;
}

.variant__label-info {
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0;
}

.grid-product {
  margin-bottom: 15px
}

@media only screen and (min-width:769px) {

  .grid-product {
    margin-bottom: 30px
  }
}

@media only screen and (max-width:768px) {

  .grid-overflow-wrapper .grid-product {
    margin-bottom: 0
  }
}

.grid-product__content {
  position: relative;
  text-align: left
}

html[dir=rtl] .grid-product__content {
  text-align: right
}

.grid-product__title--heading {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight);
}

[data-type_header_capitalize=true] .grid-product__title--heading {
  text-transform: uppercase;
}

.grid-product__title--heading {
  font-size: calc(var(--typeBaseSize) + 2px);
  line-height: 1;
}

[data-type_product_capitalize=true] .grid-product__title {
  text-transform: uppercase;
  font-size: calc(var(--typeBaseSize) - 2px);
  letter-spacing: 0.2em
}

@media only screen and (max-width:768px) {

  [data-type_product_capitalize=true] .grid-product__title {
    font-size: calc(var(--typeBaseSize) - 3px)
  }
}

.grid-product__title {
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.grid-product__link {
  display: block;
}

.grid-product__link--inline {
  display: flex
}

.grid-product__link--inline .grid-product__image-mask {
  flex: 0 0 auto;
  margin-right: 10px;
}

.grid-product__link--inline .grid__image-ratio {
  width: 80px;
}

.grid-product__link--inline .grid-product__meta {
  text-align: left;
}

.grid-product__image-mask {
  position: relative;
  overflow: hidden;
}

.grid-product__image {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.grid-product__secondary-image {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  opacity: 0;
  background-color: #ffffff
  ;
  background-color: var(--colorBody)
}

.grid-product__image-mask:hover .grid-product__secondary-image {
  animation: fade-in 0.2s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards
}

@media only screen and (max-width:768px) {

  .supports-touch .grid-product__secondary-image {
    display: none
  }
}

.grid-product__color-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  opacity: 0;
  transition: opacity 0.4s ease;
  background-color: #ffffff
  ;
  background-color: var(--colorBody)
}

.grid-product__color-image.is-active {
  animation: fade-in 0.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
}

.product-form-holder {
  opacity: 0;
  transition: opacity 0.4s ease
}

.product-form-holder.is-active {
  opacity: 1;
}

[data-center-text=true] .grid-product__meta {
  text-align: center
}

.grid-product__meta {
  position: relative;
  padding: 10px 0 6px;
  line-height: 1.5
}

@media only screen and (max-width:768px) {

  .small--grid--flush .grid-product__meta {
    padding-left: 10px;
    padding-right: 10px
  }
}

.grid-product__vendor {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.75em;
  margin-top: 5px;
  opacity: 0.65;
}

.grid-product__price {
  font-size: calc(var(--typeBaseSize) * 0.85);
  color: #1c1d1d
  ;
  color: var(--colorPrice);
  margin-top: 5px;
}

.grid-product__price--original {
  text-decoration: line-through;
  margin-right: 5px;
}

.grid-product__price--savings {
  color: #e4320c
  ;
  color: var(--colorTextSavings);
  margin-left: 5px;
  white-space: nowrap;
}

.grid-product__tag {
  font-size: calc(var(--typeBaseSize) * 0.65);
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  padding: 6px 5px 6px 7px;
  background-color: #111111
  ;
  background-color: var(--colorBtnPrimary);
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  z-index: 2;
  transition: opacity 0.4s ease
}

.grid-product__tag.grid-product__tag--sold-out {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
}

@media only screen and (min-width:769px) {

  .grid-product__tag {
    font-size: calc(var(--typeBaseSize) * 0.85);
    padding: 7px 7px 7px 9px
  }
}

.grid-product__tag--sale {
  background-color: #1c1d1d
  ;
  background-color: var(--colorSaleTag);
  color: #ffffff
  ;
  color: var(--colorSaleTagText);
}

.quick-product__btn {
  font-size: calc(var(--typeBaseSize) * 0.85);
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  z-index: 2;
  background-color: #111111
  ;
  background-color: var(--colorBtnPrimary);
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  overflow: hidden;
  padding: 8px 5px;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 0.25s ease, transform 0.25s ease-out, background 0.4s ease
}

.no-js .quick-product__btn {
  display: none
}

.grid-product__content:hover .quick-product__btn {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.25s ease, transform 0.25s ease-out
}

@media only screen and (max-width:768px) {
  .grid-overflow-wrapper {
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch
  }

  .grid-overflow-wrapper .grid {
    white-space: nowrap;
    display: flex;
  }

  .grid-overflow-wrapper .grid__item {
    width: 39vw;
    flex: 0 0 39vw;
    display: inline-block;
    float: none;
    white-space: normal
  }

  .grid-overflow-wrapper .grid__item:first-child {
    margin-left: 17px;
  }

  .grid-overflow-wrapper .grid__item:last-child:after {
    content: "";
    display: inline-block;
    width: 100%;
    margin-right: 17px;
  }

  .grid-overflow-wrapper .grid__item--view-all {
    align-self: center;
  }

  .grid-overflow-wrapper .grid-product__price,
  .grid-overflow-wrapper .grid__item {
    font-size: 0.75rem;
  }

  [data-aos=overflow__animation] {
    transform: translateX(100vw);
    transition: all 0.8s cubic-bezier(0.25,0.46,0.45,0.94)
  }

  [data-aos=overflow__animation].aos-animate {
    transform: translateX(0);
  }

  [data-disable-animations=true] [data-aos=overflow__animation] {
    transform: none;
    transition: none
  }
}

.grid-product__see-all {
  display: inline-block;
  padding: 15px;
  text-align: center;
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  margin-top: -60px;
}

[data-center-text=true] .grid-product__colors {
  text-align: center
}

.grid-product__colors {
  margin-top: 3px;
  display: flex;
  flex-wrap: wrap;
  line-height: 15px
}

@media only screen and (min-width:769px) {

  .grid-product__colors {
    line-height: 19px
  }
}

[data-center-text=true] .grid-product__colors {
  align-items: center;
  justify-content: center
}

.grid-product__colors + .grid-product__colors {
  margin-top: 4px;
}

.color-swatch {
  position: relative;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0 4px 4px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.5em;
  box-shadow: 0 0 0 1px#e8e8e1;
  box-shadow: 0 0 0 1px var(--colorBorder);
  transition: box-shadow 0.2s ease
}

[data-swatch_style=round] .color-swatch {
  border-radius: 100%
}

.color-swatch:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.color-swatch:before {
  border: 3px solid;
  border-color: #ffffff
  ;
  border-color: var(--colorBody)
}

[data-swatch_style=round] .color-swatch:before {
  border-radius: 100%;
  border-width: 4px;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px
}

.tag:not(.tag--active) a:hover .color-swatch:hover,
a.color-swatch:hover {
  box-shadow: 0 0 0 1px#000000;
  box-shadow: 0 0 0 1px var(--colorTextBody);
}

.tag--active .color-swatch {
  box-shadow: 0 0 0 2px#000000;
  box-shadow: 0 0 0 2px var(--colorTextBody);
}

.color-swatch--small {
  width: 15px;
  height: 15px
}

@media only screen and (min-width:769px) {

  .color-swatch--small {
    width: 19px;
    height: 19px
  }
}

.color-swatch--small:before {
  border: 2px solid;
  border-color: #ffffff
  ;
  border-color: var(--colorBody)
}

[data-swatch_style=round] .color-swatch--small:before {
  border-width: 3px
}

.color-swatch--filter {
  width: 35px;
  height: 35px;
}

.tag--active .color-swatch--filter:hover:after {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  bottom: 0;
  border-left: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  transform: rotate(45deg);
}

.collection-item {
  position: relative;
  display: block;
  margin-bottom: 17px;
  overflow: hidden
}

@media only screen and (min-width:769px) {

  .collection-item {
    margin-bottom: 22px
  }
}

.grid--no-gutters .collection-item {
  margin-bottom: 0
}

.collection-item:hover .collection-image {
  transform: scale(1.03);
  transition: all 0.8s ease
}

[data-disable-animations=true] .collection-item:hover .collection-image {
  transform: none;
  transition: none
}

.collection-item:not(.collection-item--below):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;
  background-color: #000000
  ;
  background-color: var(--colorGridOverlay);
  opacity: 0.1;
  opacity: var(--colorGridOverlayOpacity);
  transition: opacity 0.8s ease;
}

.collection-item:not(.collection-item--below):hover:after {
  opacity: 0.25;
  opacity: calc(var(--colorGridOverlayOpacity) + 0.15);
}

.collection-image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
  opacity: 0
}

.collection-item--below .collection-image {
  background-size: contain
}

.collection-image--placeholder {
  opacity: 1
}

.collection-image--placeholder svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.collection-image--square {
  padding-bottom: 100%;
}

.collection-image--landscape {
  padding-top: 75%;
}

.collection-image--portrait {
  padding-top: 150%;
}

.collection-item__title {
  display: block;
  z-index: 2;
  font-size: calc(var(--typeCollectionTitle) * 0.8);
  line-height: 1em
}

@media only screen and (min-width:769px) {

  .collection-item__title {
    font-size: var(--typeCollectionTitle)
  }

  .medium-up--one-fifth .collection-item__title {
    font-size: 16px
  }
}

[data-type_product_capitalize=true] .collection-item__title span {
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.2em;
}

.collection-item--below .collection-item__title {
  margin-top: 5px
}

.collection-item__title--heading {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight);
}

[data-type_header_capitalize=true] .collection-item__title--heading {
  text-transform: uppercase;
}

.collection-item__title--overlaid,
.collection-item__title--overlaid-box {
  display: block;
  position: absolute;
  left: 10px;
  right: 10px;
}

.collection-item__title--overlaid {
  color: #fff;
  text-shadow: 0 0 50px #000;
}

.collection-item__title--overlaid-box > span {
  display: inline-block;
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 8px 15px;
  color: #000000
  ;
  color: var(--colorTextBody);
}

.collection-item__title--bottom-center,
.collection-item__title--center,
.collection-item__title--top-center {
  text-align: center;
}

.collection-item__title--bottom-right,
.collection-item__title--top-right .collection-item__title--right {
  text-align: right;
}

.collection-item__title--center[class*=collection-item__title--overlaid],
.collection-item__title--left[class*=collection-item__title--overlaid],
.collection-item__title--right[class*=collection-item__title--overlaid] {
  top: 50%;
  transform: translateY(-50%);
}

.collection-item__title--top-center[class*=collection-item__title--overlaid],
.collection-item__title--top-left[class*=collection-item__title--overlaid],
.collection-item__title--top-right[class*=collection-item__title--overlaid] {
  top: 10px
}

@media only screen and (min-width:769px) {

  .collection-item__title--top-center[class*=collection-item__title--overlaid],
  .collection-item__title--top-left[class*=collection-item__title--overlaid],
  .collection-item__title--top-right[class*=collection-item__title--overlaid] {
    top: 18px
  }
}

.collection-item__title--bottom-center[class*=collection-item__title--overlaid],
.collection-item__title--bottom-left[class*=collection-item__title--overlaid],
.collection-item__title--bottom-right[class*=collection-item__title--overlaid] {
  bottom: 10px
}

@media only screen and (min-width:769px) {

  .collection-item__title--bottom-center[class*=collection-item__title--overlaid],
  .collection-item__title--bottom-left[class*=collection-item__title--overlaid],
  .collection-item__title--bottom-right[class*=collection-item__title--overlaid] {
    bottom: 18px
  }
}

.custom-content {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: auto;
  margin-bottom: -22px;
  margin-left: -22px
}

@media only screen and (max-width:768px) {

  .custom-content {
    margin-bottom: -17px;
    margin-left: -17px
  }
}

.custom__item {
  flex: 0 0 auto;
  margin-bottom: 22px;
  padding-left: 22px;
  max-width: 100%
}

@media only screen and (max-width:768px) {

  .custom__item {
    flex: 0 0 auto;
    padding-left: 17px;
    margin-bottom: 17px
  }

  .custom__item.small--one-half {
    flex: 1 0 50%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.custom__item img {
  display: block;
}

.custom__item-inner {
  position: relative;
  display: inline-block;
  text-align: left;
  max-width: 100%;
  width: 100%;
}

.custom__item-inner--html,
.custom__item-inner--video {
  display: block;
}

.custom__item-inner--image {
  width: 100%;
}

.custom__item-inner--html img {
  display: block;
  margin: 0 auto;
}

.custom__item-inner--placeholder-image {
  width: 100%;
}

.align--top-middle {
  text-align: center;
}

.align--top-right {
  text-align: right;
}

.align--middle-left {
  align-self: center;
}

.align--center {
  align-self: center;
  text-align: center;
}

.align--middle-right {
  align-self: center;
  text-align: right;
}

.align--bottom-left {
  align-self: flex-end;
}

.align--bottom-middle {
  align-self: flex-end;
  text-align: center;
}

.align--bottom-right {
  align-self: flex-end;
  text-align: right;
}

.article__grid-image {
  display: block;
  text-align: center;
  margin-bottom: 17px
}

@media only screen and (min-width:769px) {

  .article__grid-image {
    margin-bottom: 20px
  }
}

.article__grid-image img {
  display: block;
}

.article__title {
  font-size: calc(var(--typeBaseSize) + 2px);
}

.article__date {
  font-size: max(calc(var(--typeBaseSize) * 0.7), 12px);
  margin-bottom: 3px
}

.section-header .article__date {
  margin-bottom: 15px
}

.article__author {
  margin-top: 2px;
  font-size: max(calc(var(--typeBaseSize) * 0.7), 12px);
}

.article__grid-meta {
  margin-bottom: 30px
}

@media only screen and (min-width:769px) {

  [data-center-text=true] .article__grid-meta {
    text-align: center
  }
}

.article__excerpt {
  margin-top: 10px;
}

.logo-bar {
  text-align: center;
  margin-bottom: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-bar__item {
  flex: 0 1 110px;
  vertical-align: middle;
  margin: 0 15px 20px
}

@media only screen and (min-width:769px) {

  .logo-bar__item {
    flex: 0 1 160px;
    margin: 0 20px 30px
  }
}

.logo-bar__image {
  display: block;
  margin: 0 auto;
}

.logo-bar__link {
  display: block;
}

[data-aos=logo__animation] .logo-bar__item {
  opacity: 0;
}

[data-aos=logo__animation].aos-animate .logo-bar__item {
  animation: fade-in 0.5s ease 0s forwards;
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(2) {
  animation-delay: 0.2s;
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(3) {
  animation-delay: 0.4s;
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(4) {
  animation-delay: 0.6s;
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(5) {
  animation-delay: 0.8s;
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(6) {
  animation-delay: 1.0s;
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(7) {
  animation-delay: 1.2s
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(8) {
  animation-delay: 1.4s;
}

[data-aos=logo__animation].aos-animate .logo-bar__item:nth-child(9) {
  animation-delay: 1.6s;
}

.background-media-text {
  position: absolute;
  width: 100%;
  overflow: hidden;
  background: #0f0f0f
  ;
  background: var(--colorLargeImageBg);
}

.background-media-text__video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 0;
}

@media only screen and (max-width:768px) {

  .background-media-text__video {
    width: 300%;
    left: -100%
  }
}

.background-media-text__video iframe,
.background-media-text__video video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none
}

@media only screen and (min-width:769px) {

  .background-media-text__video iframe,
  .background-media-text__video video {
    height: 120%;
    max-width: none;
    left: -100%;
    height: 150%;
    width: 300%
  }
}

@media screen and (min-width:1140px) {

  .background-media-text__video iframe,
  .background-media-text__video video {
    width: 100%;
    height: 300%;
    left: auto;
    top: -100%
  }
}

.video-interactable .background-media-text__video {
  iframe,
  video {
    pointer-events: auto;
  }
}

.background-media-text__inner {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.background-media-text__aligner {
  margin: 60px;
}

.background-media-text__text {
  text-align: left;
  font-size: 1.1em;
  background: #ffffff
  ;
  background: var(--colorBody);
  padding: 30px;
  width: 380px
}

html[dir=rtl] .background-media-text__text {
  text-align: right
}

@media only screen and (max-width:768px) {

  .background-media-text__text {
    text-align: center
  }
}

.background-media-text__text--framed {
  border: 7px solid;
  border-color: #ffffff
  ;
  border-color: var(--colorBody);
  box-shadow: inset 0 0 0 2px#000000;
  box-shadow: inset 0 0 0 2px var(--colorTextBody)
}

@media only screen and (min-width:769px) {

  .background-media-text__text--framed {
    border-width: 10px
  }
}

.background-media-text__text .btn {
  margin-top: 15px;
}

@media only screen and (min-width:769px) {
  .background-media-text--right .animation-cropper {
    float: right;
  }
}

.background-media-text__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width:768px) {
  .background-media-text {
    position: relative;
  }
  .background-media-text__inner {
    position: relative;
  }
  .background-media-text__container,
  .background-media-text__video {
    position: relative;
    height: 240px
  }
  .promo-video .background-media-text__container,
  .promo-video .background-media-text__video {
    height: 550px
  }
  .background-media-text__aligner {
    margin: -6px 6px 6px;
  }
  .background-media-text__text {
    padding: 22.5px;
    width: auto;
  }
  .background-media-text.loading:after,
  .background-media-text.loading:before {
    top: 117px;
  }
}

@media only screen and (min-width:769px) {
  .background-media-text--450,
  .background-media-text--450 .background-media-text__video,
  .background-media-text__spacer.background-media-text--450 {
    min-height: 450px;
  }
  .background-media-text--550,
  .background-media-text--550 .background-media-text__video,
  .background-media-text__spacer.background-media-text--550 {
    min-height: 550px;
  }
  .background-media-text--650,
  .background-media-text--650 .background-media-text__video,
  .background-media-text__spacer.background-media-text--650 {
    min-height: 650px;
  }
  .background-media-text--750,
  .background-media-text--750 .background-media-text__video,
  .background-media-text__spacer.background-media-text--750 {
    min-height: 750px;
  }
}

[data-aos=background-media-text__animation] .background-media-text__image,
[data-aos=background-media-text__animation] .background-media-text__image svg,
[data-aos=background-media-text__animation] .background-media-text__video {
  opacity: 0
}

.no-js [data-aos=background-media-text__animation] .background-media-text__image,
.no-js [data-aos=background-media-text__animation] .background-media-text__image svg,
.no-js [data-aos=background-media-text__animation] .background-media-text__video {
  animation: none;
  opacity: 1
}

[data-disable-animations=true] [data-aos=background-media-text__animation] .background-media-text__image,
[data-disable-animations=true] [data-aos=background-media-text__animation] .background-media-text__image svg,
[data-disable-animations=true] [data-aos=background-media-text__animation] .background-media-text__video {
  animation: none;
  opacity: 1
}

[data-aos=background-media-text__animation].aos-animate .background-media-text__image.lazyloaded,
[data-aos=background-media-text__animation].aos-animate .background-media-text__image svg,
[data-aos=background-media-text__animation].loaded.aos-animate .background-media-text__video {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none
}

[data-disable-animations=true] [data-aos=background-media-text__animation].aos-animate .background-media-text__image.lazyloaded,
[data-disable-animations=true] [data-aos=background-media-text__animation].aos-animate .background-media-text__image svg,
[data-disable-animations=true] [data-aos=background-media-text__animation].loaded.aos-animate .background-media-text__video {
  animation: none
}

@media only screen and (min-width:769px) {
  [data-aos=background-media-text__animation] .background-media-text__inner .animation-contents {
    opacity: 0
  }
  .no-js [data-aos=background-media-text__animation] .background-media-text__inner .animation-contents {
    animation: none;
    opacity: 1
  }

  [data-aos=background-media-text__animation].loaded.aos-animate .background-media-text__inner .animation-contents {
    animation: rise-up 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0.5s forwards
  }

  [data-disable-animations=true] [data-aos=background-media-text__animation].loaded.aos-animate .background-media-text__inner .animation-contents {
    animation: none;
    opacity: 1
  }
}

.quote-icon {
  display: block;
  margin: 0 auto 20px;
}

.testimonials-slider__text {
  padding: 0;
  margin-bottom: 45px
}

.slick-slider .testimonials-slider__text {
  margin-right: 30px;
  margin-bottom: 0
}

.text-center .slick-slider .testimonials-slider__text {
  margin-left: 15px;
  margin-right: 15px
}

.testimonials-slider__text cite {
  font-style: normal;
}

.testimonials-slider__text p {
  margin-bottom: 7.5px
}

.testimonials-slider__text p + cite {
  margin-top: 0;
}

.testimonials-slider__text--padded {
  padding: 0 30px
}

@media only screen and (min-width:769px) {

  .testimonials-slider__text--padded {
    padding: 0 50px
  }
}

.testimonail-image {
  max-width: 142px;
  background-color: #ffffff
  ;
  background-color: var(--colorBody)
}

.text-center .testimonail-image {
  margin-left: auto;
  margin-right: auto
}

.testimonail-image--round {
  width: 142px;
  height: 142px;
  max-width: none;
  border-radius: 142px
}

.testimonail-image--round img {
  overflow: hidden;
  border-radius: 142px;
}

.testimonials-slider.slick-initialized {
  cursor: grab;
}

@media only screen and (min-width:769px) {
  .testimonials-slider.slick-initialized[data-count="1"],
  .testimonials-slider.slick-initialized[data-count="2"],
  .testimonials-slider.slick-initialized[data-count="3"] {
    cursor: default
  }

  .testimonials-slider.slick-initialized[data-count="1"] .slick-track,
  .testimonials-slider.slick-initialized[data-count="2"] .slick-track,
  .testimonials-slider.slick-initialized[data-count="3"] .slick-track {
    cursor: default;
  }
}

.testimonials-wrapper .slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 15px
}

.testimonials-wrapper .slick-dots li button:before {
  background-color: #000000
  ;
  background-color: var(--colorTextBody);
}

.testimonials-wrapper .slick-slide[tabindex="0"] {
  outline: none;
}

.announcement-bar {
  font-size: calc(var(--typeBaseSize) * 0.75);
  position: relative;
  text-align: center;
  background-color: #3d474f
  ;
  background-color: var(--colorAnnouncement);
  color: #ffffff
  ;
  color: var(--colorAnnouncementText);
  padding: 10px 0;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorBorder)
}

@media only screen and (min-width:769px) {

  .announcement-bar {
    font-size: calc(var(--typeBaseSize) * 0.85)
  }
}

.announcement-slider__slide {
  display: none;
  position: relative;
  overflow: hidden;
  padding: 0 5px
}

.announcement-slider__slide:first-child {
  display: block;
}

.announcement-link {
  display: block;
  color: #ffffff
  ;
  color: var(--colorAnnouncementText)
}

.announcement-link:active,
.announcement-link:hover {
  color: #ffffff
  ;
  color: var(--colorAnnouncementText);
}

.announcement-text {
  display: block;
}

.announcement-link-text {
  display: block
}

.announcement-link .announcement-link-text {
  text-decoration: underline
}

.announcement-link-text a {
  color: inherit;
}

@media only screen and (min-width:769px) {
  .announcement-slider--compact .announcement-link-text,
  .announcement-slider--compact .announcement-text {
    display: inline;
  }

  .announcement-slider--compact .announcement-text + .announcement-link-text {
    padding-left: 5px;
  }
}

.shopify-challenge__container {
  padding: 30px 22px
}

@media only screen and (min-width:769px) {

  .shopify-challenge__container {
    padding: 120px 0
  }
}

.newsletter {
  margin: 0 auto;
  max-width: 520px;
}

.newsletter-section .errors {
  margin-left: auto;
  margin-right: auto;
  max-width: 520px;
}

.modal .newsletter .h1 {
  margin-bottom: 15px
}

@media only screen and (min-width:769px) {

  .modal .newsletter .h1 {
    margin-bottom: 30px
  }
}

.modal .newsletter .image-wrap {
  margin-bottom: 7.5px
}

@media only screen and (min-width:769px) {

  .modal .newsletter .image-wrap {
    margin-bottom: 15px
  }
}

.modal .newsletter .text-close {
  text-decoration: underline;
}

.newsletter__input-group {
  margin: 0 auto 20px;
  max-width: 400px
}

.newsletter__input-group:last-child {
  margin-bottom: 0;
}

.newsletter__input::-webkit-input-placeholder {
  color: #000000
  ;
  color: var(--colorTextBody);
  opacity: 1;
}

.newsletter__input:-moz-placeholder {
  color: #000000
  ;
  color: var(--colorTextBody);
  opacity: 1;
}

.newsletter__input::-moz-placeholder {
  color: #000000
  ;
  color: var(--colorTextBody);
  opacity: 1;
}

.newsletter__input:-ms-input-placeholder {
  color: #000000
  ;
  color: var(--colorTextBody);
}

.newsletter__input::-ms-input-placeholder {
  color: #000000
  ;
  color: var(--colorTextBody);
  opacity: 1;
}

@media only screen and (max-width:768px) {
  .form__submit--large {
    display: none;
  }

  .form__submit--small {
    display: block;
  }
}

@media only screen and (min-width:769px) {
  .form__submit--large {
    display: block;
  }

  .form__submit--small {
    display: none;
  }
}

.map-section {
  position: relative;
  height: 650px;
  width: 100%;
  overflow: hidden
}

@media only screen and (min-width:769px) {

  .map-section {
    height: 500px
  }
}

.map-section .page-width {
  height: 100%;
}

.map-section--load-error {
  height: auto;
}

.map-onboarding {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 0;
}

.map-section__overlay-wrapper {
  position: relative;
  height: 100%;
}

.map-section__overlay {
  position: relative;
  display: inline-block;
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  padding: 30px;
  margin: 30px;
  width: 100%;
  max-width: calc(100% - 60px);
  z-index: 3
}

@media only screen and (min-width:769px) {

  .map-section__overlay {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    width: 30%
  }
}

.map-section--load-error .map-section__overlay {
  position: static;
  transform: translateY(0)
}

.map-section__link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.map-section__container {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  margin-bottom: -50%
}

@media only screen and (min-width:769px) {

  .map-section__container {
    width: 130%;
    height: 100%;
    margin: 0 -30% 0 0
  }
}

[data-aos=map-section__animation] .map-section__container {
  animation: fade-out 0.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  will-change: opacity, transform;
  opacity: 0;
}

[data-aos=map-section__animation].aos-animate .map-section__container {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
}

.image-row:after {
  content: "";
  display: table;
  clear: both;
}

.image-row__placeholder {
  float: left;
  width: 33.33%
}

.image-row--gutters .image-row__placeholder {
  width: 32%;
  margin: 0 1% 2%
}

.image-row--gutters .image-row__placeholder:first-child {
  margin-left: 0;
}

.image-row--gutters .image-row__placeholder:last-child {
  margin-right: 0;
}

.image-row__image {
  position: relative;
  min-height: 1px;
  float: left
}

.image-row__image:after {
  content: "";
  display: block;
  height: 0;
  width: 100%;
}

.image-row__image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.image-row__image .js-photoswipe__zoom {
  cursor: zoom-in;
}

.image-row__image a .js-photoswipe__zoom {
  cursor: pointer;
}

.promo-grid--space-top {
  padding-top: 40px
}

@media only screen and (min-width:769px) {

  .promo-grid--space-top {
    padding-top: 75px
  }
}

.promo-grid--space-bottom {
  padding-bottom: 40px
}

@media only screen and (min-width:769px) {

  .promo-grid--space-bottom {
    padding-bottom: 75px
  }
}

.promo-grid__container {
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat
}

.promo-grid__container .btn {
  margin-right: 10px
}

@media only screen and (max-width:768px) {

  .promo-grid__container .btn {
    margin-right: 7px
  }
}

.promo-grid__container.horizontal-center {
  justify-content: center;
  text-align: center
}

.promo-grid__container.horizontal-center .btn {
  margin: 2px 5px;
}

.promo-grid__container.horizontal-right {
  justify-content: flex-end;
  text-align: right;
}

@media only screen and (max-width:768px) {
  .promo-grid__container--boxed .promo-grid__bg {
    height: 60%;
  }

  .promo-grid__container--boxed .promo-grid__content {
    width: 100%;
    margin-top: 55%;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  }
}

.promo-grid__container--framed:not(.promo-grid__container--boxed):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;
  border: 7px solid transparent;
  box-shadow: inset 0 0 0 2px#ffffff;
  box-shadow: inset 0 0 0 2px var(--colorHeroText);
  z-index: 2
}

@media only screen and (min-width:769px) {

  .promo-grid__container--framed:not(.promo-grid__container--boxed):after {
    border-width: 10px
  }
}

.type-banner .promo-grid__container--framed:not(.promo-grid__container--boxed):after,
.type-product .promo-grid__container--framed:not(.promo-grid__container--boxed):after,
.type-sale_collection .promo-grid__container--framed:not(.promo-grid__container--boxed):after {
  box-shadow: inset 0 0 0 2px#000000;
  box-shadow: inset 0 0 0 2px var(--colorTextBody)
}

.video-interactable.promo-grid__container--framed:not(.promo-grid__container--boxed):after {
  pointer-events: none;
}

.promo-grid__container--tint:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;
  opacity: 1;
  z-index: 1;
  pointer-events: none;
}

.promo-grid__slide-link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4
}

.promo-grid__slide-link:hover~.promo-grid__content .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse) {
  background: #2b2b2b
  ;
  background: var(--colorBtnPrimaryLight);
  transition-delay: 0.25s
}

[data-button_style=square] .promo-grid__slide-link:hover~.promo-grid__content .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):after,
[data-button_style^=round] .promo-grid__slide-link:hover~.promo-grid__content .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):after {
  animation: shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);
}

.promo-grid__content {
  flex: 0 1 auto;
  padding: 2em 2.5em;
  position: relative;
  min-width: 200px;
  z-index: 3
}

.promo-grid__container--framed:not(.promo-grid__container--boxed) .promo-grid__content {
  margin: 1.5em
}

.promo-grid__content p:last-child {
  margin-bottom: 0;
}

.vertical-top .promo-grid__content {
  align-self: flex-start
}

.vertical-center .promo-grid__content {
  align-self: center
}

.vertical-bottom .promo-grid__content {
  align-self: flex-end
}

.video-interactable .promo-grid__content {
  pointer-events: none;
}

.promo-grid__content--boxed {
  background: #ffffff
  ;
  background: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
  margin: 15px
}

@media only screen and (max-width:768px) {

  .promo-grid__content--boxed {
    margin: 10px
  }
}

.promo-grid__content--framed.promo-grid__content--boxed {
  border: 7px solid;
  border-color: #ffffff
  ;
  border-color: var(--colorBody);
  box-shadow: inset 0 0 0 2px#000000;
  box-shadow: inset 0 0 0 2px var(--colorTextBody)
}

@media only screen and (min-width:769px) {

  .promo-grid__content--framed.promo-grid__content--boxed {
    border-width: 10px
  }
}

.type-advanced .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale),
.type-simple .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) {
  color: #ffffff
  ;
  color: var(--colorHeroText)
}

.type-advanced .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) a,
.type-simple .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) a {
  color: #ffffff
  ;
  color: var(--colorHeroText);
  border-bottom: 2px solid;
  border-bottom-color: #ffffff
  ;
  border-bottom-color: var(--colorHeroText)
}

[data-button_style=square] .type-advanced .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) a:not(.btn--inverse),
[data-button_style=square] .type-simple .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) a:not(.btn--inverse),
[data-button_style^=round] .type-advanced .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) a:not(.btn--inverse),
[data-button_style^=round] .type-simple .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) a:not(.btn--inverse) {
  border-bottom: 0;
}

.type-advanced .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) .btn--inverse,
.type-simple .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) .btn--inverse {
  border-color: #ffffff
  ;
  border-color: var(--colorHeroText);
}

.type-advanced .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale):after,
.type-simple .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;
  background: radial-gradient(rgba(0,0,0,0.26) 0%, transparent 60%);
  background: radial-gradient(rgba(0,0,0,var(--colorImageOverlayTextShadow)) 0%, transparent 60%);
  margin: -100px -200px;
  z-index: 1;
}

.type-advanced .rte--strong,
.type-product .rte--strong,
.type-sale_collection .rte--strong {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight);
}

[data-type_header_capitalize=true] .type-advanced .rte--strong,
[data-type_header_capitalize=true] .type-product .rte--strong,
[data-type_header_capitalize=true] .type-sale_collection .rte--strong {
  text-transform: uppercase;
}

.type-advanced .rte--strong,
.type-product .rte--strong,
.type-sale_collection .rte--strong {
  line-height: 1.1;
}

.type-advanced .rte--em,
.type-product .rte--em,
.type-sale_collection .rte--em {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.9375em;
  line-height: 1.2;
}

.type-advanced .rte--strong,
.type-product .rte--strong {
  font-size: 1.6em;
  line-height: 1.1;
}

@media only screen and (min-width:769px) {
  .type-advanced .rte--strong,
  .type-product .rte--strong {
    font-size: 2.125em;
  }
  .type-product.flex-grid__item--33 .rte--strong,
  .type-product.flex-grid__item--50 .rte--strong {
    font-size: 1.6em;
  }
}

.promo-grid__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  text-align: left
}

.promo-grid__container:not(.promo-grid__container--boxed) .promo-grid__bg:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #000000
  ;
  background-color: var(--colorImageOverlay);
  opacity: 0.18
  ;
  opacity: var(--colorImageOverlayOpacity)
}

.promo-grid__bg .placeholder-svg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: none;
  width: auto;
  padding: 0;
}

.video-interactable .promo-grid__bg:before {
  pointer-events: none;
}

.promo-grid__bg-image {
  z-index: 1;
  opacity: 0
}

.no-js .promo-grid__bg-image {
  opacity: 1
}

.promo-grid__bg-image.lazyloaded {
  animation: fade-in 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none;
}

.promo-grid__text {
  position: relative;
  z-index: 2
}

.promo-grid__text .btn {
  margin-top: 2px;
  margin-bottom: 2px;
}

.type-advanced .promo-grid__content {
  padding: 2em
}

@media only screen and (max-width:768px) {

  .type-advanced .promo-grid__content {
    padding: 1.5em
  }
}

.type-advanced .btn {
  margin-bottom: 10px
}

@media only screen and (max-width:768px) {

  .type-advanced .btn {
    margin-bottom: 7px
  }
}

.type-sale_collection {
  flex-grow: 1;
  max-height: 600px
}

.type-sale_collection .promo-grid__container {
  background: #ffffff
  ;
  background: var(--colorSmallImageBg);
  align-items: center;
}

.type-sale_collection .promo-grid__content {
  padding: 2em;
  flex: 0 1 auto;
  min-width: 0
}

@media only screen and (max-width:768px) {

  .type-sale_collection .promo-grid__content {
    padding: 1em;
    font-size: 0.9em
  }
}

@media only screen and (min-width:769px) {
  .type-sale_collection .promo-grid__content:not(.promo-grid__content--small-text) {
    font-size: 1.5em;
  }
}

.type-sale_collection .rte--block {
  margin-bottom: 7.5px
}

.type-sale_collection .rte--block:last-child {
  margin-bottom: 0;
}

.type-sale_collection .rte--strong {
  position: relative;
  display: block;
  font-size: 3.375em;
  line-height: 1;
  white-space: nowrap;
}

.type-sale_collection small {
  display: inline;
  font-size: 0.25em;
  margin-left: -2.9em;
  letter-spacing: 0;
}

.type-sale-images {
  flex: 1 1 50%
}

.type-sale-images svg {
  display: block;
  width: 50%;
  float: left;
}

.type-sale-images__crop {
  overflow: hidden;
  width: 100%;
}

.type-sale-images__image {
  width: 50%;
  float: left;
}

.type-simple .promo-grid__content {
  padding: 30px
}

@media only screen and (max-width:768px) {

  .type-simple .promo-grid__content {
    padding: 15px
  }
}

.promo-grid__title:last-child {
  margin-bottom: 0;
}

.type-image .promo-grid__container {
  background: none;
}

.type-image .image-wrap,
.type-image a,
.type-image img {
  width: 100%;
}

.type-banner {
  flex: 1 0 100%
}

.type-banner .promo-grid__container {
  background: none;
}

.type-banner .promo-grid__container--framed:not(.promo-grid__container--boxed) {
  padding: 1em;
}

.type-banner p {
  margin: 5px 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

.type-banner__link {
  display: block;
  flex: 1 1 100%;
}

.type-banner__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.type-banner__text {
  position: relative;
  flex: 0 1 auto;
  z-index: 2;
  padding: 10px 20px;
}

.type-banner__image {
  flex: 0 0 45%
}

@media only screen and (min-width:769px) {

  .type-banner__image {
    flex: 0 0 200px
  }
}

.type-product__wrapper {
  flex: 1 1 100%;
  position: relative;
  padding: 2em 0;
  z-index: 3
}

.type-product__wrapper.promo-grid__container--tint {
  padding: 2em;
}

.promo-grid__container--framed .type-product__wrapper {
  padding: 2em
}

@media only screen and (max-width:768px) {

  .type-product__content {
    margin-top: 15px
  }
}

.type-product__images {
  position: relative;
  width: 100%;
  margin: 10px 0 10px 10px;
}

.type-product__image {
  position: relative
}

.type-product__image:first-child {
  width: 100%;
  max-width: 75%;
}

.type-product__image:nth-child(2) {
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 100%;
  max-width: 30%;
}

.type-product__labels {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
  text-align: right
}

@media only screen and (min-width:769px) {
  .type-product__labels .flex-grid__item--33,
  .type-product__labels .flex-grid__item--50 {
    font-size: 0.9em;
  }
}

.type-product__label {
  padding: 4px 12px;
  background-color: #111111
  ;
  background-color: var(--colorBtnPrimary);
  color: #ffffff
  ;
  color: var(--colorBtnPrimaryText);
  float: right;
  clear: both;
}

.type-product__label--secondary {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
}

.store-availability {
  display: flex;
  justify-content: space-around
}

.store-availability .icon {
  margin: 6px 0 0;
  width: 12px;
  height: 12px;
}

.store-availability .icon-in-stock {
  fill: #56ad6a;
}

.store-availability .icon-out-of-stock {
  fill: #d02e2e;
}

.store-availability + .store-availability {
  margin-top: 20px;
}

.store-availability__info {
  flex: 0 1 90%;
  text-align: left;
  margin-left: 10px
}

.store-availability__info > div {
  margin-bottom: 5px
}

.store-availability__info > div:last-child {
  margin-bottom: 0;
}

.store-availability__info a {
  text-decoration: underline;
}

.store-availability__small {
  font-size: 0.8em
}

.store-availability__small a {
  display: block;
  margin-top: 10px;
}

.cart__row {
  position: relative;
  margin-bottom: 30px
}

.cart__row:first-child {
  border-bottom: 1px solid;
  border-bottom-color: #e8e8e1
  ;
  border-bottom-color: var(--colorBorder);
  padding-bottom: 15px;
}

.cart__row:last-child {
  border-top: 1px solid;
  border-top-color: #e8e8e1
  ;
  border-top-color: var(--colorBorder);
  padding-top: 15px;
}

.cart__row .js-qty__wrapper {
  margin: 0 auto;
}

.cart__checkout-wrapper {
  margin-top: 20px
}

.cart__checkout-wrapper .additional-checkout-buttons {
  margin-top: 12px;
}

.drawer .additional-checkout-buttons {
  margin: 10px 0
}

.drawer .additional-checkout-buttons [data-shopify-buttoncontainer] {
  justify-content: center
}

.drawer .additional-checkout-buttons [data-shopify-buttoncontainer] > * {
  height: auto !important;
}

@media only screen and (min-width:769px) {
  .cart__update + .cart__checkout {
    margin-left: 15px;
  }
}

.cart__row--table {
  display: table;
  table-layout: fixed;
  width: 100%
}

.cart__row--table .grid__item {
  display: table-cell;
  vertical-align: middle;
  float: none;
}

@media only screen and (min-width:769px) {
  .cart__row--table-large {
    display: table;
    table-layout: fixed;
    width: 100%
  }

  .cart__row--table-large .grid__item {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  .cart__row--table-large .cart__image {
    width: 150px;
  }
}

.cart__image {
  display: block
}

@media only screen and (max-width:959px) {

  .cart__image {
    margin-bottom: 15px
  }
}

.cart__image img {
  width: 100%;
  display: block;
}

.cart__product-name {
  margin-bottom: 0;
}

.cart__product-qty {
  text-align: center;
  margin: 0 auto;
  max-width: 80px;
}

.cart__price {
  display: block;
}

.cart__price--strikethrough {
  text-decoration: line-through;
}

.cart__discount {
  color: #e4320c
  ;
  color: var(--colorTextSavings);
}

.cart__product-meta {
  margin-bottom: 0
}

.cart__product-meta + .cart__product-meta {
  margin-top: 20px;
}

iframe.zoid-component-frame {
  z-index: 1 !important;
}

@media only screen and (min-width:769px) {
  .product-single__sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 20px
  }
  .sticky-header .product-single__sticky {
    top: 140px
  }
}

.page-content--product {
  padding-top: 55px;
}

@media only screen and (max-width:768px) {
  .page-content--product {
    padding-top: 0;
  }

  .grid--product-images-right {
    display: flex;
    flex-wrap: wrap
  }

  .grid--product-images-right .grid__item:first-child {
    order: 2;
  }
}

.modal .page-content--product {
  width: 1500px;
  max-width: 100%;
}

.product-single__meta {
  padding-left: 45px
}

@media only screen and (max-width:768px) {

  .product-single__meta {
    text-align: center;
    padding-left: 0;
    margin-top: 15px
  }
}

.grid--product-images-right .product-single__meta {
  padding-left: 0;
  padding-right: 45px
}

@media only screen and (max-width:768px) {

  .grid--product-images-right .product-single__meta {
    padding-right: 0
  }
}

.product-single__meta .social-sharing {
  margin-top: 30px;
}

.product-single__meta .rte {
  text-align: left
}

html[dir=rtl] .product-single__meta .rte {
  text-align: right
}

.product-single__vendor {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 0.75em;
  margin-bottom: 7.5px;
}

.product-single__title {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.product-single__review-link {
  display: block
}

.product-single__review-link .spr-badge[data-rating="0.0"] {
  display: none;
}

.product-single__review-link .spr-badge {
  margin-bottom: 7px;
}

.product-single__review-link .spr-badge-starrating {
  margin-right: 8px;
}

.product-single__review-link .spr-badge-caption {
  font-size: calc(var(--typeBaseSize) - 1px);
}

.product-single__sku {
  margin-bottom: 5px;
}

.product-single__description {
  margin-bottom: 30px;
}

.product-single__description-full {
  margin: 30px 0;
}

.product-single__form {
  margin-bottom: 30px;
}

.product-single__variants {
  display: none
}

.no-js .product-single__variants {
  display: block;
  margin-bottom: 30px
}

.product-image-main {
  position: relative;
}

.trust-image {
  margin: 0 auto 30px;
}

.product__video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 100%;
  height: auto;
  background-color: #ffffff
  ;
  background-color: var(--colorSmallImageBg)
}

.product__video-wrapper iframe {
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in;
}

.product__video-wrapper[data-video-style=muted].loaded:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.product__video-wrapper.video-interactable:before {
  display: none;
}

.product__video-wrapper.loaded:after {
  display: none;
}

.product__video-wrapper.loading iframe {
  opacity: 0.01;
}

.product__video-wrapper.loaded iframe {
  opacity: 1;
}

.product__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.product-video-trigger {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product__photos--beside {
  display: flex;
  width: 100%;
}

.product__photos {
  direction: ltr
}

.product__photos a {
  display: block;
  max-width: 100%;
}

.product__photos img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

.product__main-photos {
  position: relative;
  overflow: hidden;
  flex: 1 1 auto
}

.product__main-photos img {
  display: none;
}

.product__main-photos .slick-initialized img,
.product__main-photos .starting-slide img {
  display: block;
}

.product__main-photos .secondary-slide:not(.slick-slide) {
  display: none;
}

@media only screen and (min-width:769px) {
  .product__main-photos .slick-slide:not(.slick-current) {
    opacity: 0 !important;
    visibility: hidden;
  }
}

@media only screen and (max-width:768px) {

  .product__main-photos {
    margin-left: -17px;
    margin-right: -17px
  }

  .product__main-photos .slick-list {
    padding-left: 10%;
    padding-right: 10%;
  }
  .product__main-photos .slick-slide:not(.slick-current) {
    position: relative
  }

  .product__main-photos .slick-slide:not(.slick-current):before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .product__main-photos .slick-slide .product-image-main {
    margin-right: 4px;
  }
  .product__main-photos .slick-cloned img {
    opacity: 1;
  }
}

.product__photo-dots .slick-dots {
  position: static;
}

.product__photo-dots .slick-dots li button:before {
  background-color: #000000
  ;
  background-color: var(--colorLink);
}

.product__thumbs {
  position: relative;
}

.product__thumbs--below {
  margin-top: 8.5px
}

@media only screen and (min-width:769px) {

  .product__thumbs--below {
    margin-top: 15px
  }
}

.product__thumbs--beside {
  flex: 0 0 60px;
  max-width: 60px;
  margin-left: 8.5px
}

@media only screen and (min-width:769px) {

  .product__thumbs--beside {
    flex: 0 0 80px;
    max-width: 80px;
    margin-left: 15px
  }

  .product__thumbs--beside.product__thumbs-placement--left {
    order: -1;
    margin-left: 0;
    margin-right: 15px;
  }
}

.product__thumbs--beside .slick-list {
  min-height: 100%;
}

.product__thumbs .slick-arrow {
  background: #ffffff
  ;
  background: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
  transform: none;
  z-index: 2
}

.product__thumbs .slick-arrow.slick-disabled {
  display: none !important;
}

.product__thumbs .slick-arrow .icon {
  display: inline-block;
}

.product__thumbs[data-position=below] .slick-arrow {
  top: 0;
  height: 100%;
  width: 25px
}

.product__thumbs[data-position=below] .slick-arrow.slick-next {
  right: 0;
  text-align: right;
}

.product__thumbs[data-position=below] .slick-arrow.slick-prev {
  left: 0;
  text-align: left;
}

.product__thumbs[data-position=beside] .slick-arrow {
  width: 100%
}

.product__thumbs[data-position=beside] .slick-arrow .icon {
  margin: 0 auto;
  transform: rotate(90deg);
}

.product__thumbs[data-position=beside] .slick-arrow.slick-prev {
  top: 0;
  left: auto;
  padding-bottom: 10px;
}

.product__thumbs[data-position=beside] .slick-arrow.slick-next {
  top: auto;
  bottom: 0;
  right: auto;
  padding-top: 10px;
}

.product__thumb-item {
  border: 2px solid transparent
}

.product__thumb-item a:focus {
  outline: none
}

.product__thumb-item a:focus:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 2px#000000;
  box-shadow: inset 0 0 0 2px var(--colorTextBody);
  z-index: 1;
}

.product__thumb-item a:active:before {
  content: none;
}

.product__thumb-item.slick-current {
  border-color: #000000
  ;
  border-color: var(--colorTextBody)
}

.product__thumb-item.slick-current a:focus:before {
  content: none;
}

.product__thumbs--beside .product__thumb-item {
  margin-bottom: 8.5px
}

@media only screen and (min-width:769px) {

  .product__thumbs--beside .product__thumb-item {
    margin-bottom: 15px
  }
}

.product__thumbs--below .product__thumb-item {
  margin-right: 8.5px;
  max-width: 80px !important
}

@media only screen and (min-width:769px) {

  .product__thumbs--below .product__thumb-item {
    margin-right: 15px
  }
}

.product__thumbs--below .product__thumb-item:last-child {
  margin-right: 0;
}

.product__thumbs--below:not(.slick-initialized) .product__thumb-item {
  max-width: 100px;
  float: left;
}

.product__thumb {
  position: relative;
  display: block;
  cursor: pointer;
}

.product__thumb-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #000000
  ;
  background-color: var(--colorTextBody);
  border-radius: 100px;
  padding: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease;
  font-size: 0
}

.aos-animate .product__thumb-icon {
  opacity: 1
}

.product__thumb-icon .icon {
  fill: #ffffff
  ;
  fill: var(--colorBody);
  width: 10px;
  height: 10px
}

@media only screen and (min-width:769px) {

  .product__thumb-icon .icon {
    width: 13px;
    height: 13px
  }
}

.product__price {
  color: #1c1d1d
  ;
  color: var(--colorPrice);
  margin-right: 5px;
  font-size: calc(var(--typeBaseSize) + 2px);
}

.product__unit-price {
  font-size: 0.8em;
  opacity: 0.8;
}

.product__unit-price--spacing {
  margin-top: 10px;
}

.product__price--compare {
  padding-right: 5px;
  display: inline-block;
  text-decoration: line-through;
}

.product__price-savings {
  color: #e4320c
  ;
  color: var(--colorTextSavings);
  white-space: nowrap;
}

.product__inventory {
  font-size: calc(var(--typeBaseSize) * 0.85);
  text-align: center;
  font-style: italic;
  margin: 5px 0
}

@media only screen and (min-width:769px) {

  .product__inventory {
    margin: 7.5px 0
  }
}

.product__quantity {
  margin-bottom: 15px
}

@media only screen and (min-width:769px) {

  .product__quantity {
    margin-bottom: 30px
  }
}

.product__quantity label {
  display: block;
  margin-bottom: 10px;
}

.product__quantity--dropdown {
  display: inline-block;
}

.add-to-cart[disabled] + .shopify-payment-button {
  display: none;
}

.product__photo-zoom {
  position: absolute !important;
  bottom: 0;
  right: 0;
  cursor: zoom-in
}

@media only screen and (max-width:768px) {

  .product__photo-zoom {
    padding: 6px
  }

  .product__main-photos .product__photo-zoom {
    margin-bottom: 10px;
    margin-right: 10px
  }

  .slick-slider .product__photo-zoom {
    opacity: 0;
    transition: opacity 0.5s ease-out
  }

  .slick-slider .slick-active .product__photo-zoom {
    opacity: 1
  }
}

@media only screen and (min-width:769px) {

  .product__photo-zoom {
    opacity: 0;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0
  }

  .product__photo-zoom span,
  .product__photo-zoom svg {
    display: none;
  }
}

.template-blog .article {
  margin-bottom: 45px
}

@media only screen and (min-width:769px) {

  .template-blog .article {
    margin-bottom: 90px
  }
}

.template-blog .article:last-child {
  margin-bottom: 0
}

@media only screen and (min-width:769px) {

  .template-blog .article:last-child {
    margin-bottom: 45px
  }
}

.article__body {
  margin-bottom: 15px
}

@media only screen and (min-width:769px) {

  .article__body {
    margin-bottom: 30px
  }
}

.comment.last {
  margin-bottom: -15px;
}

.template-password {
  height: 100vh;
  text-align: center;
}

.password-page__image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 1;
  animation: zoom-fade-password 20s ease 1s forwards
}

.password-page__image:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(50, 50, 50, 0.3);
}

.password-page__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 3;
  min-height: 500px
}

.password-page__wrapper a {
  color: inherit;
}

.password-page__wrapper hr {
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
}

.password-page__wrapper .social-sharing.clean a {
  color: inherit;
  background: transparent;
}

.password-header-section {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.password-page__header__inner {
  padding: 15px;
}

.password-page__logo {
  margin-top: 20px
}

@media only screen and (min-width:769px) {

  .password-page__logo {
    margin-top: 90px
  }
}

.password-page__logo .logo {
  max-width: 100%;
}

.password-page__main {
  flex: 1 1 100%;
}

.password-page {
  display: flex;
  justify-content: center;
}

.password-page__content {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
  padding: 30px;
  border: 7px solid;
  border-color: #ffffff
  ;
  border-color: var(--colorBody);
  box-shadow: inset 0 0 0 2px#000000;
  box-shadow: inset 0 0 0 2px var(--colorTextBody)
}

@media only screen and (min-width:769px) {

  .password-page__content {
    border-width: 10px
  }
}

.password-page__hero {
  text-transform: none;
}

.password-page__message {
  margin-bottom: 30px !important
}

.password-page__message img {
  max-width: 100%;
}

.password-form {
  margin-bottom: 1em;
}

@media only screen and (min-width:769px) {

  .password-page__signup-form {
    padding: 0 30px
  }
}

.password-page__signup-form .input-group-field {
  background-color: #fff;
  color: #000
}

.password-page__signup-form .input-group-field::-webkit-input-placeholder {
  color: #000;
  opacity: 0.7;
}

.password-page__signup-form .input-group-field:-moz-placeholder {
  color: #000;
  opacity: 0.7;
}

.password-page__signup-form .input-group-field::-moz-placeholder {
  color: #000;
  opacity: 0.7;
}

.password-page__signup-form .input-group-field:-ms-input-placeholder {
  color: #000;
  opacity: 0.7;
}

.password-page__signup-form .input-group-field::-ms-input-placeholder {
  color: #000;
  opacity: 0.7;
}

.password-page__signup-form .errors ul {
  list-style-type: none;
  margin-left: 0;
}

.password-page__social-sharing {
  margin-top: 30px;
}

.password-login {
  display: block;
  margin: 0 auto;
  padding: 7.5px 15px
}

@media only screen and (min-width:769px) {

  .password-login {
    margin-top: 20px;
    margin-right: 20px
  }
}

.password__lock .icon {
  position: relative;
  top: -2px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
}

.password-page__footer {
  flex: 0 1 auto;
}

.password-page__footer_inner {
  padding: 45px 0 15px;
  line-height: 2;
}

.icon-shopify-logo {
  width: 60px;
  height: 20px;
}

@media only screen and (max-width:768px) {

  #LoginModal .modal__close {
    padding: 20px
  }
}

#LoginModal .modal__inner {
  background: #ffffff
  ;
  background: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
  padding: 30px
}

@media only screen and (max-width:768px) {

  #LoginModal .modal__inner {
    margin-bottom: 40vh
  }
}

.password-admin-link {
  margin: 0
}

.password-admin-link a {
  border-bottom: 2px solid !important;
  border-bottom-color: #e8e8e1
   !important;
  border-bottom-color: var(--colorBorder) !important;
}

.template-giftcard,
.template-giftcard body {
  background: #ffffff
  ;
  background: var(--colorBody)
}

.template-giftcard a,
.template-giftcard body a {
  text-decoration: none;
}

.template-giftcard .page-width {
  max-width: 588px;
}

.giftcard-header {
  padding: 60px 0;
  font-size: 1em;
  text-align: center
}

.giftcard-header a {
  display: block;
  margin: 0 auto;
}

.template-giftcard .shop-url {
  display: none;
}

.giftcard__border {
  padding: 1.5em;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.giftcard__content:after {
  content: "";
  display: table;
  clear: both;
}

.giftcard__content {
  background-color: #ffffff
  ;
  background-color: var(--colorBody);
  color: #000000
  ;
  color: var(--colorTextBody);
}

.giftcard__header:after {
  content: "";
  display: table;
  clear: both;
}

.giftcard__header {
  padding: 15px;
}

.giftcard__title {
  float: left;
  margin-bottom: 0;
}

.giftcard__tag {
  display: block;
  float: right;
  background-color: #000000
  ;
  background-color: var(--colorTextBody);
  border: 1px solid transparent;
  color: #ffffff
  ;
  color: var(--colorBody);
  padding: 10px;
  border-radius: 4px;
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1;
}

.giftcard__tag--active {
  background: transparent;
  color: #000000
  ;
  color: var(--colorTextBody);
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
}

.giftcard__wrap {
  position: relative;
  margin: 15px 15px 30px
}

.giftcard__wrap img {
  position: relative;
  display: block;
  border-radius: 10px;
  z-index: 2;
}

.giftcard__code {
  position: absolute;
  bottom: 30px;
  text-align: center;
  width: 100%;
  z-index: 50;
}

.giftcard__code--medium {
  font-size: 0.875em;
}

.giftcard__code--small {
  font-size: 0.75em;
}

.giftcard__code__inner {
  display: inline-block;
  vertical-align: baseline;
  background-color: #fff;
  padding: 0.5em;
  border-radius: 4px;
  max-width: 450px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1)
}

.giftcard__code--small .giftcard__code__inner {
  overflow: auto
}

.giftcard__code__text {
  font-weight: 400;
  font-size: 1.875em;
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px dashed;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  padding: 0.4em 0.5em;
  display: inline-block;
  vertical-align: baseline;
  color: #000000
  ;
  color: var(--colorTextBody);
  line-height: 1
}

.disabled .giftcard__code__text {
  color: #999;
  text-decoration: line-through
}

.giftcard__amount {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 2.75em;
  line-height: 1.2;
  padding: 15px;
  z-index: 50
}

.giftcard__amount strong {
  display: block;
  text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.giftcard__amount--medium {
  font-size: 2em;
}

.tooltip {
  display: block;
  position: absolute;
  top: -50%;
  right: 50%;
  margin-top: 16px;
  z-index: 3;
  color: #fff;
  text-align: center;
  white-space: nowrap
}

.tooltip:before {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 0;
  height: 0;
  margin-left: -5px;
  margin-bottom: -5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 5px solid #333;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
}

.tooltip__label {
  display: block;
  position: relative;
  right: -50%;
  border: none;
  border-radius: 4px;
  background: #333;
  background: rgba(51, 51, 51, 0.9);
  min-height: 14px;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  line-height: 16px;
  text-shadow: none;
  padding: 0.5em 0.75em;
  margin-left: 0.25em
}

.tooltip__label small {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #b3b3b3;
  font-size: 0.875em;
}

.giftcard__instructions {
  text-align: center;
  margin: 0 15px 30px;
}

.giftcard__actions {
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-bottom: 1em;
}

.template-giftcard .action-link {
  position: absolute;
  left: 15px;
  top: 50%;
  font-size: 0.875em;
  font-weight: 700;
  display: block;
  padding-top: 4px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-top: -10px
}

.template-giftcard .action-link:focus,
.template-giftcard .action-link:hover {
  color: #000000
  ;
  color: var(--colorTextBody);
}

.template-giftcard .action-link__print {
  display: inline-block;
  vertical-align: baseline;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 10px;
  opacity: 1;
  background-image: url('//cdn.shopify.com/s/assets/gift-card/icon-print-164daa1ae32d10d1f9b83ac21b6f2c70.png');
  background-repeat: no-repeat;
  background-position: 0 0;
}

.giftcard__footer {
  text-align: center;
  padding: 60px 0;
}

#QrCode img {
  padding: 30px;
  border: 1px solid;
  border-color: #e8e8e1
  ;
  border-color: var(--colorBorder);
  border-radius: 4px;
  margin: 0 auto 30px;
}

@media only screen and (max-width:768px) {
  .giftcard {
    font-size: 12px;
  }

  .giftcard-header {
    padding: 30px 0;
  }

  .header-logo {
    font-size: 2em;
  }

  .giftcard__border {
    padding: 15px;
  }

  .giftcard__actions {
    padding: 15px;
  }

  .giftcard__actions .btn {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .template-giftcard .action-link {
    display: none;
  }
}

@media screen and (max-width:400px) {
  .giftcard__amount strong {
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .giftcard__wrap:after,
  .giftcard__wrap:before {
    display: none;
  }

  .giftcard__code {
    font-size: 0.75em;
  }

  .giftcard__code--medium {
    font-size: 0.65em;
  }

  .giftcard__code--small {
    font-size: 0.55em;
  }
}

@media screen and (max-height:800px) {
  .header-logo img {
    max-height: 90px;
  }
}

@media print {
  @page {
    margin: 0.5cm;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  body,
  html {
    background-color: #fff;
  }

  .giftcard-header {
    padding: 10px 0;
  }

  .giftcard__border,
  .giftcard__content {
    border: 0 none;
  }

  .add-to-apple-wallet,
  .giftcard__actions,
  .giftcard__wrap:after,
  .giftcard__wrap:before,
  .site-header__logo-link img:nth-child(2),
  .tooltip {
    display: none;
  }

  .giftcard__title {
    float: none;
    text-align: center;
  }

  .giftcard__code__text {
    color: #555;
  }

  .template-giftcard .shop-url {
    display: block;
  }

  .template-giftcard .logo {
    color: #58686f;
  }
}


.stamped-badge-powered {
  display: none;
}


.stamped-container {
  border: none !important;
  padding: 2em 0 !important;
}

.stamped-summary-actions-newquestion,
.stamped-summary-actions-newreview {
  background: grey !important;
  color: white !important;
  border: none !important;
  padding: 5px 15px !important;
}



span.stamped-badge-caption {
  color: #3085c7;
}

.fa-star-half-o:before,
.fa-star-o:before,
.fa-star:before {
  font-size: 18px !important;
  margin-right: 2px;
}

.template-product #stamped-reviews-widget[data-widget-type="full-page"] .stamped-widget-title {
  display: none;
}

.template-product .stamped-ratings-wrapper.stamped-review-card div:first-child {
  display: none !important;
}

.template-product #stamped-reviews-widget[data-widget-type="full-page"] .stamped-ratings-wrapper > div {
  padding-left: 0 !important;
}
.template-product #stamped-reviews-widget[data-widget-type="full-page"] .stamped-reviews-title br {
  display: none;
}

.template-product #stamped-reviews-widget[data-widget-type="full-page"] .stamped-reviews-title .stamped-review-product {
  display: none;
}

.template-product #stamped-reviews-widget[data-widget-type="full-page"] .stamped-reviews-title a.stamped-review-title {
  display: block !important;
  cursor: default;
  pointer-events: none;
  color: black !important;
  font-weight: bold;
}

.grid-product__tag--volume {
  font-size: 11px;
  margin-bottom: 5px;
}
.grid-product__tag--gold {
  background: #ffd200;
  color: #333;
}